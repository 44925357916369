import { ToastId, UseToastOptions } from "@chakra-ui/react";

export type ToastType = (options?: UseToastOptions) => ToastId;

export enum DATE_KEYS {
  MINUTES = "minutes",
  HOUR = "hour",
  DAY = "day",
  MONTH = "month",
  YEAR = "year"
}

export type DateItemType = {
  placeholder: string;
  key: DATE_KEYS;
  width?: string;
};

export enum DATE_SORTERS {
  DATE_ASC = "date_asc",
  DATE_DESC = "date_desc"
}
