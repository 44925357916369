import React from "react";
import { ProfileViewSectionContainer } from "../ProfileViewSectionContainer/ProfileViewSectionContainer";
import { Button, Flex, Text, useToast, VStack } from "@chakra-ui/react";
import { PhotoDisplay } from "@lubbezposrednio-frontend/commons";
import { useImageDropzone } from "../../../../hooks/useImageDropzone";
import { FilePreview } from "@lubbezposrednio-frontend/core";

interface PhotoUploadSectionProps {
  profilePhoto: FilePreview | null;
  previewSrc: string | null;
  setProfilePhoto: (photo: FilePreview) => void;
  title: string;
}

export const PhotoUploadSection: React.FC<PhotoUploadSectionProps> = ({
  profilePhoto,
  setProfilePhoto,
  title,
  previewSrc
}) => {
  const toast = useToast();

  const { getInputProps, open } = useImageDropzone(setProfilePhoto, toast);

  return (
    <ProfileViewSectionContainer>
      <input {...getInputProps()} />
      <Flex direction={{ base: "column", md: "row" }} align={{ base: "center", md: "flex-end" }} gap="20px">
        <VStack>
          <Text textStyle="text" color="primary" fontWeight="bold">
            {title}
          </Text>
          <PhotoDisplay profilePhoto={profilePhoto} previewSrc={previewSrc} />
        </VStack>
        <Button
          bg="primary"
          color="white"
          variant="custom"
          borderRadius="standard"
          onClick={open}
          flexGrow="1"
          py="24px"
          w={{ base: "100%", md: "fit-content" }}
        >
          <Text textStyle="text">Wgraj zdjęcie</Text>
        </Button>
      </Flex>
    </ProfileViewSectionContainer>
  );
};
