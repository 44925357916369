import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { LAYOUT_CONTENT_WIDTHS } from "@lubbezposrednio-frontend/core";

interface HomeViewHeaderProps {}

export const HomeViewHeader: React.FC<HomeViewHeaderProps> = ({}) => {
  return (
    <Flex
      bg="primary"
      w="100%"
      p="32px"
      pt="50px"
      borderBottomRadius="15px"
      boxShadow="standard"
      minH={{ base: "100vh", md: "fit-content" }}
      justify="center"
    >
      <HStack maxW={LAYOUT_CONTENT_WIDTHS} px={{ base: 0, md: "32px", xl: "64px" }}>
        <Image src="/HOME_HEADER_IMAGE.png" alt="image" my="20px" w="50%" display={{ base: "none", md: "block" }} />
        <VStack
          w={{ base: "100%", md: "50%" }}
          align={{ base: "center", md: "flex-start" }}
          h="100%"
          justify={{ base: "center", md: "flex-start" }}
        >
          <Link to="/">
            <Image
              src="/LBP_Logo_White.svg"
              alt="logo"
              px={{ base: "20px", md: 0 }}
              mb={{ base: 0, md: "20px", xl: "120px" }}
            />
          </Link>
          <Image
            src="/HOME_HEADER_IMAGE.png"
            alt="image"
            mr="-40px"
            my="20px"
            display={{ base: "block", md: "none" }}
          />
          <Box textStyle="header" color="background" mb={{ base: 0, md: "24px" }}>
            <Text display="inline-block">Tu zaczyna się prawdziwa </Text>
            <Text color="accent" display="inline-block">
              demokracja
            </Text>
            <Text display="inline-block">!</Text>
          </Box>
          <Text textStyle={{ base: "text", md: "largeText" }} color="background">
            Platforma decyduj.lubbezposrednio.pl to darmowe i bezpieczne narzędzie do przeprowadzania wiarygodnych
            głosowań na najniższym szczeblu. Wykorzystaj nasze możliwości w swojej organizacji lub otoczeniu, by
            demokratycznie podjąć decyzję np. w stowarzyszeniu, spółdzielni mieszkaniowej, samorządzie uczniowskim,
            związku zawodowym albo lokalnej wspólnocie. Korzystamy z kryptografii i technologii blockchain, by budować
            pełne zaufanie do demokracji możliwie najbliżej Obywatela!
          </Text>
        </VStack>
      </HStack>
    </Flex>
  );
};
