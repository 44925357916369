import { Button, ButtonProps, Text } from "@chakra-ui/react";
import React from "react";

interface ProfileButtonProps extends ButtonProps {
  label: string;
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({
  label,
  ...props
}) => {
  return (
    <Button
      w="300px"
      bg="background"
      color="primary"
      variant="custom"
      borderRadius="standard"
      borderWidth="1px"
      borderColor="primary"
      py="24px"
      {...props}
    >
      <Text textStyle="largeText">{label}</Text>
    </Button>
  );
};
