import { HStack, Text } from "@chakra-ui/react";
import React from "react";

interface OrganizerDataLineProps {
  fieldName: string;
  value: string;
}

export const OrganizerDataLine: React.FC<OrganizerDataLineProps> = ({ fieldName, value }) => {
  return (
    <HStack>
      <Text textStyle="largeText" color="primary" fontWeight="bold">
        {fieldName} :
      </Text>
      <Text textStyle="largeText" color="primary">
        {value}
      </Text>
    </HStack>
  );
};
