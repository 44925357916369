import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { CustomCollapse } from "../CustomCollapse/CustomCollapse";
import { Link } from "react-router-dom";

interface CollapsesSectionProps {}

export const CollapsesSection: React.FC<CollapsesSectionProps> = ({}) => {
  return (
    <Flex
      p="30px"
      w="100%"
      gap={{ base: "50px", md: "20px" }}
      direction={{ base: "column", md: "row" }}
      justify="center"
    >
      <CustomCollapse
        title="DLA ORGANIZATORA"
        redirectButtonText="Zorganizuj głosowanie"
        redirectPath="/logowanie"
        data-cy="organizer-collapse"
        content={
          <Box>
            <Text display="inline">
              Reprezentujesz organizację lub społeczność, w której chcecie podjąć demokratyczną decyzję przy udziale
              wszystkich jej członków? Możesz to zrobić za darmo, bezpiecznie i wiarygodnie.{" "}
            </Text>
            <Link to={"/rejestracja"} style={{ display: "inline-block" }}>
              <Text fontWeight="bold">Zarejestruj się</Text>
            </Link>
            <Text display="inline">&nbsp;lub&nbsp;</Text>
            <Link to={"/logowanie"} style={{ display: "inline-block" }}>
              <Text fontWeight="bold">zaloguj</Text>
            </Link>
            <Text display="inline">, by stworzyć głosowanie i zaprosić do niego wszystkich uprawnionych!</Text>
          </Box>
        }
      />
      <CustomCollapse
        title="DLA GŁOSUJĄCYCH"
        redirectButtonText="Aktywne głosowania"
        redirectPath="/aktywne-glosowania"
        data-cy="voter-collapse"
        content={
          <Box>
            <Text display="inline">
              Szukasz głosowania swojej organizacji lub społeczności albo chcesz podejrzeć, w jakich sprawach
              demokratyczne decyzje podejmują inni? Kliknij w{" "}
            </Text>
            <Link to={"/aktywne-glosowania"} style={{ display: "inline-block" }}>
              <Text fontWeight="bold">„Aktywne głosowania!”</Text>
            </Link>
            <Text display="inline">
              . Możesz też zaproponować zorganizowanie takiego głosowania za darmo i bezpiecznie w swojej organizacji
              lub społeczności.
            </Text>
          </Box>
        }
      />
    </Flex>
  );
};
