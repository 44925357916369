import React, { useEffect } from "react";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { Text, VStack } from "@chakra-ui/layout";

export const AccessibilityDeclarationView: React.FC = () => {
  useEffect(() => {
    document.title = "Deklaracja dostępności";
  }, []);

  return (
    <Layout>
      <VStack flex={1}>
        <Text textStyle="header" color="primary">
          Deklaracja dostępności
        </Text>
      </VStack>
    </Layout>
  );
};
