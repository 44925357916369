import React from "react";
import { CustomTile } from "@lubbezposrednio-frontend/commons";
import { BoxProps } from "@chakra-ui/react";
import { MAX_CREATE_VOTING_WIDTH } from "@lubbezposrednio-frontend/core";

type CreateVotingTileProps = BoxProps & {
  title: string;
  children: React.ReactNode;
  absoluteChildren?: React.ReactNode;
  containerProps?: BoxProps;
};

export const CreateVotingTile: React.FC<CreateVotingTileProps> = ({ children, ...props }) => {
  return (
    <CustomTile
      tileWidth={813}
      pr="31px"
      titleStyle={{ variant: "createVotingTileTitle" }}
      maxWidth={MAX_CREATE_VOTING_WIDTH}
      {...props}
    >
      {children}
    </CustomTile>
  );
};
