export const dividerStyles = {
  baseStyle: {
    h: "1px",
    bg: "primary",
    opacity: 1
  },
  variants: {
    summary: {
      h: "2px",
      my: { base: "19px", sm: "28px" }
    }
  }
};
