import { Box, Flex, HStack, Image, StackProps, SystemProps, Text, TextProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { VotingCreatorType } from "@lubbezposrednio-frontend/core";

const IMAGE_BOX_SIZE = 51;
const IMAGE_BOX_BORDER = 2;
const IMAGE_SIZE = IMAGE_BOX_SIZE - 2 * IMAGE_BOX_BORDER;

type VotingCreatorDataProps = StackProps & {
  creatorData: VotingCreatorType;
  textDirection?: SystemProps["flexDirection"];
  textProps?: TextProps;
  votingId: string;
};

export const VotingCreatorData: React.FC<VotingCreatorDataProps> = ({
  creatorData,
  textDirection,
  textProps,
  votingId,
  maxW,
  ...props
}) => {
  return (
    <Box maxW={maxW}>
      <Link to={`/glosowanie/${votingId}/organizator`}>
        <HStack {...props}>
          <Box
            h={`${IMAGE_BOX_SIZE}px`}
            w={`${IMAGE_BOX_SIZE}px`}
            borderRadius="50%"
            borderColor="primary"
            borderWidth={`${IMAGE_BOX_BORDER}px`}
            display="flex"
            alignItems="center"
            overflow="hidden"
          >
            <Image flex={1} src={creatorData.pictureUrl} maxH={`${IMAGE_SIZE}px`} maxW={`${IMAGE_SIZE}px`} />
          </Box>
          <Flex direction={textDirection ?? { base: "column", sm: "row" }} flex={1}>
            <Text color="primary" textStyle={"text"} fontWeight="bold" {...textProps}>
              Organizator:&nbsp;
            </Text>
            <Text color="primary" textStyle={"text"} fontWeight="bold" wordBreak="break-word" {...textProps}>
              {creatorData.name}
            </Text>
          </Flex>
        </HStack>
      </Link>
    </Box>
  );
};
