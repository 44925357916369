import { Icon, IconProps } from "@chakra-ui/react";

export const VerifiedCheckIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 28 28" {...props}>
      <g id="Group_35" data-name="Group 35" transform="translate(-121 -26.579)">
        <g
          id="Ellipse_7"
          data-name="Ellipse 7"
          transform="translate(121 26.579)"
          fill="#fffaeb"
          stroke="#e51f44"
          strokeWidth="3"
        >
          <circle cx="14" cy="14" r="14" stroke="none" />
          <circle cx="14" cy="14" r="12.5" fill="none" />
        </g>
        <g id="Group_33" data-name="Group 33" transform="translate(128.179 37.795) rotate(-6)">
          <line
            id="Line_23"
            data-name="Line 23"
            x2="4"
            y2="6"
            transform="translate(0.388 2.476)"
            fill="none"
            stroke="#e51f44"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_24"
            data-name="Line 24"
            x1="8"
            y2="8"
            transform="translate(4.388 0.476)"
            fill="none"
            stroke="#e51f44"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </Icon>
  );
};
