import { action, computed, makeObservable, observable } from "mobx";
import {
  DATE_KEYS,
  LP_STATUSES,
  Model,
  checkDateCorrectness,
  getDateModelType,
  getDateObject,
  LandingPageDataType,
  LandingPageDraftDataType,
  correctDate
} from "@lubbezposrednio-frontend/core";

const INITIAL_DATE = {
  [DATE_KEYS.MINUTES]: "",
  [DATE_KEYS.HOUR]: "",
  [DATE_KEYS.DAY]: "",
  [DATE_KEYS.MONTH]: "",
  [DATE_KEYS.YEAR]: ""
};

export class LandingPageDatesModel extends Model {
  startOfPublicationDate: Record<DATE_KEYS, string> = this.getCurrentDate();
  startOfPublicationDateError = "";
  endOfPublicationDate: Record<DATE_KEYS, string> = INITIAL_DATE;
  endOfPublicationDateError = "";

  constructor() {
    super();

    makeObservable(this, {
      startOfPublicationDate: observable.ref,
      endOfPublicationDate: observable.ref,
      changeStartOfPublicationDate: action.bound,
      changeEndOfPublicationDate: action.bound,
      startOfPublicationDateError: observable,
      endOfPublicationDateError: observable,
      validateDates: action.bound,
      checkIsDateCorrect: action.bound,

      setInitialData: action.bound,

      clearData: action.bound,
      clearErrors: action.bound,
      isError: computed,
      status: computed,
      startDateObject: computed,
      endDateObject: computed
    });
  }

  private getCurrentDate(): Record<DATE_KEYS, string> {
    const currDateObject = new Date();

    const date = {
      [DATE_KEYS.MINUTES]: currDateObject.getMinutes() + 1,
      [DATE_KEYS.HOUR]: currDateObject.getHours(),
      [DATE_KEYS.DAY]: currDateObject.getDate(),
      [DATE_KEYS.MONTH]: currDateObject.getMonth() + 1,
      [DATE_KEYS.YEAR]: currDateObject.getFullYear()
    };

    return correctDate(date);
  }

  public async setInitialData(data: (LandingPageDataType | LandingPageDraftDataType)["landing_page"]) {
    this.startOfPublicationDate = data.start_date ? getDateModelType(new Date(data.start_date)) : this.getCurrentDate();
    this.endOfPublicationDate = data.end_date ? getDateModelType(new Date(data.end_date)) : INITIAL_DATE;
  }

  public changeEndOfPublicationDate(key: DATE_KEYS, value: string, isActive: boolean) {
    this.endOfPublicationDate = { ...this.endOfPublicationDate, [key]: value };
    this.endOfPublicationDateError && this.validateDates(isActive);
  }
  public changeStartOfPublicationDate(key: DATE_KEYS, value: string, isActive: boolean) {
    this.startOfPublicationDate = { ...this.startOfPublicationDate, [key]: value };
    this.startOfPublicationDateError && this.validateDates(isActive);
  }

  checkIsDateCorrect(isStart: boolean, isActive: boolean) {
    if (this.startOfPublicationDateError || this.endOfPublicationDateError) {
      return this.validateDates(isActive);
    }
    const date = isStart ? this.startOfPublicationDate : this.endOfPublicationDate;
    const { isCorrect, message } = checkDateCorrectness(date);

    if (isStart) {
      this.startOfPublicationDateError = isCorrect ? "" : message;
    } else {
      this.endOfPublicationDateError = isCorrect ? "" : message;
    }
    return isCorrect;
  }

  private validateDate(date: Record<DATE_KEYS, string>, isDraft?: boolean): string {
    const { isCorrect, message } = checkDateCorrectness(date);
    if (!isCorrect) {
      return message;
    }

    const dateValues = Object.values(date);
    const isDateComplete = dateValues.every(value => !!value) || (isDraft && dateValues.every(value => value === ""));
    if (!isDateComplete) {
      return "Wymagane jest podanie pełnej daty";
    }

    if (!isDraft) {
      const dateObject = getDateObject(date);
      const isDateInFuture = dateObject.getTime() - Date.now() > 0;
      if (!isDateInFuture) {
        return "Data nie moze być wcześniejsza niż aktualna";
      }
    }

    return "";
  }

  validateDates(isActive: boolean, isDraft?: boolean) {
    const startErrorMessage = !isActive ? this.validateDate(this.startOfPublicationDate, isDraft) : "";
    const endErrorMessage = this.validateDate(this.endOfPublicationDate, isDraft);

    if (!startErrorMessage && !endErrorMessage && !isDraft) {
      const isEndAfterStart = this.startDateObject.getTime() < this.endDateObject.getTime();

      this.startOfPublicationDateError = startErrorMessage;
      return (this.endOfPublicationDateError = isEndAfterStart ? "" : "Data zakończenia musi być po dacie rozpoczęcia");
    }
    this.startOfPublicationDateError = startErrorMessage;
    this.endOfPublicationDateError = endErrorMessage;
  }

  clearErrors() {
    this.startOfPublicationDateError = "";
    this.endOfPublicationDateError = "";
  }

  clearData() {
    this.startOfPublicationDate = this.getCurrentDate();
    this.endOfPublicationDate = INITIAL_DATE;
    this.clearErrors();
  }

  get isError(): boolean {
    return !!(this.startOfPublicationDateError || this.endOfPublicationDateError);
  }

  get startDateObject(): Date {
    return getDateObject(this.startOfPublicationDate);
  }

  get endDateObject(): Date {
    return getDateObject(this.endOfPublicationDate);
  }

  get status(): LP_STATUSES {
    if (this.startDateObject.getTime() > Date.now()) {
      return LP_STATUSES.PLANNED;
    }
    if (this.endDateObject.getTime() < Date.now()) {
      return LP_STATUSES.FINISHED;
    }
    return LP_STATUSES.ONGOING;
  }

  override async onInit() {}
}
