import { forwardRef, Input, InputGroup, InputProps } from "@chakra-ui/react";

export type CustomInputProps = Omit<InputProps, "value" | "onChange"> & {
  value: string;
  onChange: (value: string) => void;
  onInputClick?: () => void;
  children?: React.ReactNode;
};

export const CustomInput = forwardRef<CustomInputProps, "input">(
  ({ onChange, onInputClick, value, children, ...props }: CustomInputProps, ref) => {
    const handleChange = (event: { target: { value: string } }) => onChange(event.target.value);
    return (
      <InputGroup width={props.width} maxW="100%">
        <Input
          ref={ref}
          onChange={handleChange}
          onClick={onInputClick}
          value={value}
          _placeholder={{ color: "lightGray", fontSize: "14px" }}
          {...props}
          borderRadius="standard"
          borderColor="primary"
          borderWidth="2px"
          bg="background"
          _hover={{}}
        />
        {children}
      </InputGroup>
    );
  }
);
