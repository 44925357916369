import { Text, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { CustomCheckbox, InternalLink } from "@lubbezposrednio-frontend/commons";
import { AuthViewWrapper } from "../../hocs/AuthViewWrapper";
import { bindProperty, authorization } from "@lubbezposrednio-frontend/core";
import { BottomButtonsSection } from "./components/BottomButtonsSection";
import { LoginForm } from "./components/LoginForm";

interface LoginViewProps {}

export const LoginView: React.FC<LoginViewProps> = observer(({}) => {
  useEffect(() => {
    document.title = "Logowanie";
    authorization.clearInputData();
  }, []);

  return (
    <AuthViewWrapper
      authorization={authorization}
      buttonText="Zaloguj się"
      buttonColor="primary"
      bottomElement={<BottomButtonsSection />}
      submitAction={authorization.logIn}
      customTop={
        <VStack maxW={{ base: "90%", sm: "50%" }} pt="40px">
          <Text textStyle="text" color="primary" textAlign="center">
            By tworzyć bezpieczne, wiarygodne i darmowe głosowania na decyduj.lubbezposrednio.pl musisz utworzyć konto i
            być zalogowany. Jeżeli już to zrobiłeś – podaj poniżej swój adres e-mail i hasło. Jeżeli nie zrobiłeś tego
            wcześniej albo jesteś tu po raz pierwszy –{" "}
            <InternalLink to="/rejestracja" text="zarejestruj się" isInline />.
          </Text>
          <Text textStyle="text" color="primary" textAlign="center">
            Uwaga! Nie potrzebujesz rejestracji i logowania, by wziąć udział w głosowaniu, do którego zostałeś
            uprawniony przez organizatora głosowania. W takim przypadku przejdź do sekcji{" "}
            <InternalLink to="/aktywne-glosowania" text="„Dla głosujących”" isInline /> i znajdź interesujące Cię
            głosowanie albo kliknij bezpośrednio w link, którzy otrzymałeś od organizatora głosowania.
          </Text>
        </VStack>
      }
    >
      <LoginForm authorization={authorization} />
      <VStack align="flex-start" alignSelf="flex-start" ml="12px" mt="12px" flexGrow={1}>
        <CustomCheckbox
          required
          label="Zapoznałem się z Regulaminem i Polityką Prywatności"
          {...bindProperty(authorization, "applicationServiceTerms")}
        />
      </VStack>
    </AuthViewWrapper>
  );
});
