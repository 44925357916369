import { DATE_KEYS } from "../types/general";

export const LAYOUT_CONTENT_WIDTHS = {
  md: "900px",
  lg: "1000px",
  xl: "1440px"
};

export const BREAKPOINTS = {
  MOBILE: 768,
  MD: 960,
  LG: 1200,
  XL: 1600,
  FULL_HD: 1920
};

export const LOCAL_STOREGE_KEYS = {
  ACCESS_TOKEN: "accessToken",
  TOKEN_EXPITY_DATE: "tokenExpiryDate",
  REFRESH_TOKEN_EXPITY_DATE: "refteshTokenExpiryDate"
};

export const PASSWORD_VALIDATORS = [/[a-z]/g, /[A-Z]/g, /[0-9]/g, /[^a-zA-Z0-9 ]+/];

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 128;
export const NUMBER_OF_PASSWORD_CONDITIONS_REQUIRED = 2;

export const ACCOUNT_TYPES = {
  PERSON: "person",
  ORGANIZATION: "organization"
};
export const FIRST_LETTER_CHAR_CODE = 65;
export const LAST_LETTER_CHAR_CODE = 90;

export const MIN_INPUT_LENGTH = 3;

export const ANCHOR_TAG_REGEX = /<a[^>]*href=['"](https?:\/\/[^'"]+)['"][^>]*>(.*?)<\/a>/gi;
export const LINK_REGEX = /\b(?:https?:\/\/|www.)\S+/g;
export const GENERAL_MAIL_REGEX =
  /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~."(),:;<>@\[\\\]żźćńółęąśŻŹĆĄŚĘŁÓŃ]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}>?$/;
export const MAILER_CLIENT_ADDRESS_REGEX =
  /<[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~."(),:;<>@\[\\\]żźćńółęąśŻŹĆĄŚĘŁÓŃ]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}>/g;

export const TILE_X_PADDING = "22px";
export const MAX_CREATE_VOTING_WIDTH = "78vw";

export const VOTINGS_LIST_PAGE_SIZE = 4;

export const PASSWORD_TOOLTIP = `Hasło powinno zawierać minimum ${MIN_PASSWORD_LENGTH} znaków, oraz co najmniej ${NUMBER_OF_PASSWORD_CONDITIONS_REQUIRED} z 4 wymienionych:  mała litera, duża litera, cyfra, znak specjalny`;
export const MAX_MAIL_SIGNS = 255;

export const NEW_TAB_WARNING = "Link zostanie otwarty w nowej karcie";

export const VOTING_FILTER_HEIGHT = "48px";

export const SIZE_UNIT = 1024;

export const MAX_PDF_SIZE = 2 * 1024 * 1024; // B

export const MAX_VALUES: { [key in DATE_KEYS]?: number } = {
  [DATE_KEYS.MINUTES]: 59,
  [DATE_KEYS.HOUR]: 23,
  [DATE_KEYS.MONTH]: 12
};

export const FORM_ERROR_MESSAGE = "Formularz zawiera niepoprawne lub brakujące dane";

export const TILE_TITLE_STYLES = {
  textStyle: "largeText",
  bg: "primary",
  color: "background",
  py: "18px",
  fontWeight: "bold",
  borderRadius: "standard"
};

export const LIST_BOTTOM_PADDING = 56;

export const DRAFTS_LIMIT = 10;
