import { Button, ButtonProps, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";

interface CustomButtonProps extends ButtonProps {
  text: string;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string | number;
  onClick?: () => void;
}

export const CustomButton: React.FC<CustomButtonProps> = observer(
  ({
    text,
    onClick,
    backgroundColor = "accent",
    textColor = "background",
    fontSize = { base: "18px", md: "18px" },
    fontWeight = "bold",
    ...props
  }) => {
    return (
      <Button
        variant="menu"
        h="37px"
        bg={backgroundColor}
        py="20px"
        px={{ base: "20px", md: "40px" }}
        borderRadius="standard"
        borderColor={backgroundColor}
        onClick={onClick}
        textTransform="none"
        _hover={{}}
        {...props}
      >
        <Text fontSize={fontSize} lineHeight={{ base: "20px", md: "20px" }} color={textColor} fontWeight={fontWeight}>
          {text + " >>"}
        </Text>
      </Button>
    );
  }
);
