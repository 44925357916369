import { useToast, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { RefObject, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CustomButton, Layout } from "@lubbezposrednio-frontend/commons";
import { Command, bindToCommand, ToastType, AuthorizationModel } from "@lubbezposrednio-frontend/core";

interface AuthViewWrapperProps {
  authorization: AuthorizationModel;
  children: React.ReactNode;
  bottomElement?: React.ReactNode;
  buttonText: string;
  buttonColor?: string;
  customTop?: React.ReactNode;
  submitAction: (
    reCaptchaRef: RefObject<ReCAPTCHA>,
    toast: ToastType,
    navigate: NavigateFunction
  ) => Command<void, any>;
}

export const AuthViewWrapper: React.FC<AuthViewWrapperProps> = observer(
  ({ authorization, children, buttonText, bottomElement, buttonColor, submitAction, customTop }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const reCaptchaRef = useRef<ReCAPTCHA>(null);

    return (
      <Layout>
        {customTop}
        <VStack maxW="300px" my={{ base: "40px", md: "80px" }} gap="16px">
          {children}
          <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string} size="invisible" ref={reCaptchaRef} />
          <CustomButton
            text={buttonText}
            {...bindToCommand(submitAction(reCaptchaRef, toast, navigate))}
            backgroundColor={buttonColor}
          />
          {bottomElement}
        </VStack>
      </Layout>
    );
  }
);
