import React, { useMemo } from "react";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { VotingItemDate } from "./VotingItemDate";
import { VotingCreatorData } from "./VotingCreatorData";
import { VOTING_STATUSES, VotingListItem } from "@lubbezposrednio-frontend/core";
import { observer } from "mobx-react-lite";
import { VotingDetails } from "./VotingDetails";
import { CustomTile } from "@lubbezposrednio-frontend/commons";
import { VotingTileButtonProps } from "./VotingTileButton";
import { CreatedVotingsListViewModel } from "../../CreatedVotingsListViewModel";
import { VotingsListModel } from "../../../../models/VotingsListModel";

export const TILE_WIDTH = 536;
export const MAX_TILE_WIDTH = "70vw";

const getChildren = (voting: VotingListItem, customDates?: JSX.Element) => [
  <React.Fragment key={1}>
    {customDates ?? (
      <Flex justify="space-between">
        <Box>
          <VotingItemDate label="Data rozpoczęcia" date={voting.startDate} />
          <VotingItemDate label="Data zakończenia" date={voting.endDate} />
        </Box>
        {voting.status === VOTING_STATUSES.DRAFT && (
          <Text textStyle="text" fontWeight="bold" color="primary" textAlign="end">
            WERSJA ROBOCZA
          </Text>
        )}
      </Flex>
    )}
  </React.Fragment>,
  <Divider mt={{ base: "8px", sm: "14.5px" }} mb="13.5px" key={2} />,
  <VotingCreatorData
    creatorData={voting.votingCreator}
    key={3}
    pb={customDates ? "2px" : { base: "51px", sm: "26px" }}
    votingId={voting.id}
  />
];

type CreatedVotingTileProps = {
  voting: VotingListItem;
  model: VotingsListModel & Partial<CreatedVotingsListViewModel>;
  Button: React.FC<VotingTileButtonProps>;
  isCustomTile?: boolean;
  CustomDates?: React.FC<{ start?: string; id: string }>;
  customTitle?: React.ReactNode;
};

export const CreatedVotingTile: React.FC<CreatedVotingTileProps> = observer(
  ({ voting, model, Button, isCustomTile, CustomDates, customTitle }) => {
    const isOpen = voting.id === model.expandedVotingId;

    const children = useMemo(() => {
      const childrenArr = getChildren(
        voting,
        isCustomTile && CustomDates ? <CustomDates start={voting.startDate} id={voting.id} /> : undefined
      );
      return isCustomTile ? childrenArr.reverse() : childrenArr;
    }, [JSON.stringify(voting), isCustomTile, !!CustomDates]);

    return (
      <Box borderColor={isOpen ? "primary" : "transparent"}>
        <CustomTile
          customTitle={customTitle}
          tileWidth={TILE_WIDTH}
          title={voting.name}
          pos="relative"
          zIndex={isOpen ? 2 : undefined}
          borderColor={isOpen ? "primary" : "transparent"}
          absoluteChildren={
            <>
              {isOpen && <VotingDetails model={model} />}
              <Button model={model} voting={voting} isOpen={isOpen} />
            </>
          }
          titleStyle={{ variant: "createdVotingsListTileTitle" }}
          maxWidth={MAX_TILE_WIDTH}
          pt={isCustomTile ? "10px" : { base: "15px", sm: "17px" }}
          containerProps={{ minW: "260px" }}
        >
          {children}
        </CustomTile>
      </Box>
    );
  }
);
