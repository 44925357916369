import { action, computed, makeObservable, observable, runInAction } from "mobx";
import {
  Command,
  FORM_ERROR_MESSAGE,
  LP_STATUSES,
  Model,
  ToastType,
  createLandingPage,
  getLandingPage,
  editLandingPage,
  CreateLandingPageParams,
  getLandingPages,
  DRAFTS_LIMIT,
  getApiParamsDate
} from "@lubbezposrednio-frontend/core";
import { NavigateFunction } from "react-router-dom";
import { LandingPageTextsModel } from "./components/LandingPageTexts/LandingPageTextsModel";
import { LandingPageDocumentsModel } from "./components/LandingPageDocuments/LandingPageDocumentsModel";
import { LandingPageDatesModel } from "./components/LandingPageDates/LandingPageDatesModel";

export class CreateLandingPageViewModel extends Model {
  textsModel: LandingPageTextsModel = new LandingPageTextsModel();
  documentsModel: LandingPageDocumentsModel = new LandingPageDocumentsModel();
  datesModel: LandingPageDatesModel = new LandingPageDatesModel();

  isEdit = false;
  id?: string;
  isActive = false;

  constructor() {
    super();

    makeObservable(this, {
      isEdit: observable,
      isActive: observable,
      id: observable,
      getInitialData: action.bound,

      clearData: action.bound,
      clearErrors: action.bound,
      isError: computed
    });
  }

  public async getInitialData(isEdit: boolean, id: string) {
    this.isEdit = isEdit;
    this.id = id;

    if (id) {
      try {
        const { status, data } = await getLandingPage(id);

        if (status === 200 && data) {
          const { landing_page } = data;
          this.textsModel.setInitialData(landing_page);
          this.documentsModel.setInitialData(landing_page);
          this.datesModel.setInitialData(landing_page);

          runInAction(() => {
            this.isActive = landing_page.status === LP_STATUSES.ONGOING;
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  private getLPParams(isDraft?: boolean): Partial<CreateLandingPageParams> {
    const start = getApiParamsDate(this.datesModel.startOfPublicationDate, isDraft);
    const end = getApiParamsDate(this.datesModel.endOfPublicationDate, isDraft);

    const params: Partial<CreateLandingPageParams> = {
      end_date: end
    };

    if (!(this.isActive && this.isEdit)) {
      params.page_title = this.textsModel.pageTitle.trim();
      params.page_description = this.textsModel.pageDescriptionWithLinks;
      params.additional_descriptions = this.textsModel.additionalDescriptions.map(
        desctiption => desctiption.contentWithLinks
      );
      params.terms_and_conditions = this.documentsModel.customRegulations;
      params.terms_and_conditions_url = this.documentsModel.customRegulationsUrl;
      params.privacy_policy = this.documentsModel.customPrivacyPolicy;
      params.privacy_policy_url = this.documentsModel.customPrivacyPolicyUrl;
      params.start_date = start;
      params.status = isDraft ? LP_STATUSES.DRAFT : this.datesModel.status;
      params.published = String(!isDraft);
    }

    return params;
  }

  private validateForm() {
    this.datesModel.validateDates(this.isActive);
    this.textsModel.validateForm();
  }

  public handleSubmit(toast: ToastType, navigate: NavigateFunction) {
    return new Command(async () => {
      this.validateForm();

      if (!this.isError) {
        this.textsModel.removeEmptyAdditionalDescriptions();
        const params = this.getLPParams();

        const response = await (this.isEdit && this.id
          ? editLandingPage(this.id, params)
          : createLandingPage(params as CreateLandingPageParams));
        if (response?.status === 201 || response?.status === 200) {
          navigate("/organizator/modyfikuj-strone-zapisu-na-glosowanie/potwierdzenie", {
            state: {
              url: `${window.origin}/zapis-na-glosowanie/${response.data.landing_page.id}`,
              startDate: this.datesModel.startDateObject,
              endDate: this.datesModel.endDateObject
            }
          });
          this.clearData();
        } else {
          toast({
            title: "Nie udało się utworzyć strony zapisu na głosowanie. Spróbuj ponownie później.",
            status: "error",
            isClosable: true
          });
        }
      } else {
        this.showGenericErrorToast(toast, FORM_ERROR_MESSAGE);
      }
    });
  }

  clearErrors() {
    this.textsModel.clearErrors();
    this.documentsModel.clearErrors();
    this.datesModel.clearErrors();
  }

  clearData() {
    this.textsModel.clearData();
    this.documentsModel.clearData();
    this.datesModel.clearData();

    this.isEdit = false;
    this.id = undefined;
    this.isActive = false;

    this.clearErrors();
  }

  get isError(): boolean {
    return !!(this.textsModel.isError || this.documentsModel.isError || this.datesModel.isError);
  }

  private async checkDraftsQuantity(toast: ToastType): Promise<boolean> {
    try {
      const { data } = await getLandingPages({ status: [LP_STATUSES.DRAFT], per_page: 1 });
      const quantity = data.meta.total_pages;

      if (quantity >= DRAFTS_LIMIT) {
        this.showGenericErrorToast(
          toast,
          `Maksymalna ilość wersji roboczych to ${DRAFTS_LIMIT}. Aby dodać kolejną wersję roboczą, usuń jedną z uprzednio utworzonych wersji roboczych.`
        );
        return false;
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private async validateDraft(toast: ToastType): Promise<boolean> {
    const isLimitReached = !(await this.checkDraftsQuantity(toast));
    if (isLimitReached) {
      return false;
    }

    this.clearErrors();
    this.documentsModel.validatePrivacyPolicy();
    this.documentsModel.validateRegulations();
    this.datesModel.validateDates(false, true);

    this.isError && this.showGenericErrorToast(toast, FORM_ERROR_MESSAGE);

    return !this.isError;
  }

  public async handleCreateDraft(navigate: NavigateFunction, toast: ToastType) {
    const isValid = await this.validateDraft(toast);

    if (isValid) {
      const errMessage = "Nie udało się utworzyć wersji roboczej. Spróbuj ponownie później.";
      try {
        const response = await createLandingPage(this.getLPParams(true));
        if (response?.status === 201) {
          toast({ status: "success", isClosable: true, title: "Wersja robocza została utworzona" });
          navigate("/organizator/lista-zapisow-na-glosowanie");
        } else {
          this.showGenericErrorToast(toast, errMessage);
        }
      } catch (err) {
        this.showGenericErrorToast(toast, errMessage);
        console.error(err);
      }
    }
  }

  override async onInit() {}
}
