import { useEffect } from "react";
import { Button, Divider, Flex, Text, VStack, useToast } from "@chakra-ui/react";
import { createVotingModel } from "../../CreateVotingView/CreateVotingViewModel";
import { VotingDates } from "./VotingDates";
import { VotingQuestionsSummary } from "./VotingQuestionsSummary";
import { VotersListSummary } from "./VotersListSummary";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

export const Summary: React.FC = observer(() => {
  useEffect(() => {
    document.title = "Podsumowanie tworzonego głosowania";
  }, []);

  const navigate = useNavigate();
  const toast = useToast();
  return (
    <>
      <Text textStyle="header" color="primary" py={{ base: "41px", sm: "34px" }}>
        Utwórz głosowanie
      </Text>
      <VStack spacing={0}>
        <VStack
          bg="background"
          flex={1}
          borderRadius="standard"
          borderWidth="2px"
          borderColor="primary"
          pl={{ base: "15px", sm: "21px" }}
          pr={{ base: "15px", sm: "31.5px" }}
          pt="33px"
          pb="43px"
          w="813px"
          align="start"
          spacing={0}
          maxW="77vw"
          alignItems={{ base: "center", sm: "start" }}
        >
          <Flex flexWrap="wrap" justify={{ base: "center", sm: "start" }}>
            <Text variant="votingSummaryLabel">Typ głosowania:&nbsp;</Text>
            <Text variant="votingSummaryValue">{createVotingModel.isPublic ? "jawne" : "niepubliczne"}</Text>
          </Flex>

          <Divider variant="summary" mt={{ base: "16px", sm: "27px" }} w={{ base: "57vw", sm: "100%" }} />
          <Flex flexWrap="wrap" justify={{ base: "center", sm: "start" }}>
            <Text variant="votingSummaryLabel">Nazwa głosowania:&nbsp;</Text>
            <Text variant="votingSummaryValue" textAlign={{ base: "center", sm: "left" }}>
              {createVotingModel.votingDataModel.name}
            </Text>
          </Flex>

          <Divider variant="summary" mt={{ base: "18px", sm: "23px" }} w={{ base: "57vw", sm: "100%" }} />
          <VotingDates model={createVotingModel} />

          <Divider variant="summary" w={{ base: "57vw", sm: "100%" }} />
          <VotingQuestionsSummary model={createVotingModel} />

          <Divider variant="summary" w={{ base: "57vw", sm: "100%" }} />
          <VotersListSummary model={createVotingModel} />
        </VStack>
        <Flex gap="20px" mt={{ base: "59px", sm: "44px" }} mb={{ base: "75px", sm: "87px" }}>
          <Button
            variant="custom"
            bg="background"
            color="primary"
            h="56px"
            borderRadius="standard"
            borderColor="primary"
            borderWidth="2px"
            px="21px"
            py="17px"
            fontSize="18px"
            lineHeight="20px"
            fontWeight="bold"
            onClick={() => navigate(-1)}
          >
            Wróć
          </Button>
          <Button
            variant="custom"
            bg="accent"
            color="background"
            h="56px"
            borderRadius="standard"
            px="21px"
            py="17px"
            fontSize="18px"
            lineHeight="20px"
            fontWeight="bold"
            onClick={() => createVotingModel.handleCreateVoting(toast)}
          >
            Zatwierdź głosowanie {">>"}
          </Button>
        </Flex>
      </VStack>
    </>
  );
});
