import { QuestionIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  FormHelperTextProps
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { CustomTooltip } from "../../CustomTooltip/CustomTooltip";
export interface CustomFormControlProps extends FormControlProps {
  labelTitle?: string;
  errorMessage?: string;
  helperTextProps?: FormHelperTextProps;
  helperText?: string;
  bottomHelperText?: string;
  tooltipMessage?: string;
  children?: ReactNode;
  labelProps?: FormLabelProps;
}

export const CustomFormControl = (props: CustomFormControlProps) => {
  const {
    labelTitle,
    children,
    errorMessage,
    helperText,
    helperTextProps,
    isInvalid,
    isRequired,
    tooltipMessage,
    onBlur,
    labelProps,
    bottomHelperText,
    ...rest
  } = props;
  const [isDirty, setIsDirty] = useState(false);
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={isDirty ? isInvalid : undefined}
      {...rest}
      onBlur={onBlur ?? (() => setIsDirty(true))}
    >
      {labelTitle && (
        <FormLabel
          textStyle="text"
          fontSize="14px"
          fontWeight="bold"
          color={isDirty && isInvalid ? "error" : "primary"}
          pl="12px"
          {...labelProps}
        >
          {labelTitle}
          {tooltipMessage && (
            <CustomTooltip message={tooltipMessage} placement="top">
              <QuestionIcon ml="1" mb={1} />
            </CustomTooltip>
          )}
        </FormLabel>
      )}
      {helperText && (
        <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
      )}
      {children}
      {bottomHelperText && (
        <FormHelperText color="grayText.400" fontSize="12px">
          {bottomHelperText}
        </FormHelperText>
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
