import { action, makeObservable, observable } from "mobx";
import { AxiosResponse } from "axios";
import {
  getFormattedDate,
  ApiVotingListType,
  VOTING_STATUSES,
  VotingCreatorType,
  VotingListItem,
  ListModel
} from "@lubbezposrednio-frontend/core";

type CachedFiltersType<FilterType> = FilterType & {
  status?: VOTING_STATUSES;
};

export abstract class VotingsListModel<FilterType = {}> extends ListModel<
  CachedFiltersType<FilterType>,
  VotingListItem
> {
  statusFilter?: VOTING_STATUSES;

  constructor() {
    super();

    makeObservable(this, {
      statusFilter: observable,
      _changeStatusFilter: action.bound
    });
  }

  abstract changeStatusFilter(status?: VOTING_STATUSES): void;

  _changeStatusFilter(status?: VOTING_STATUSES) {
    this.statusFilter = status;
  }

  override cacheCommonFilters() {
    this.cachedFilters.status = this.cachedFilters.status ?? this.statusFilter;
    this.cachedFilters.page = this.cachedFilters.page ?? this.currPage;
  }

  override restoreCommonFilters() {
    this.statusFilter = this.cachedFilters.status ?? this.statusFilter;
    this.currPage = this.cachedFilters.page ?? this.currPage;
  }

  abstract getVotings(page: number): Promise<AxiosResponse<ApiVotingListType> | undefined>;

  private getLocalDateString(isoDate?: string /*ISO string*/): string {
    return isoDate ? getFormattedDate(new Date(isoDate)) : "";
  }

  async getListItems(page: number): Promise<{ list?: VotingListItem[]; pages?: number; status?: number }> {
    try {
      const response = await this.getVotings(page);

      const list = response?.data?.votings.map(voting => {
        const { user } = voting;
        const userName =
          (user.first_name || user.last_name) && `${voting.user.first_name ?? ""} ${voting.user.last_name ?? ""}`;
        const organizerName = user.is_organization ? user.organization_name : userName;
        const votingCreator: VotingCreatorType = {
          name: organizerName ?? user.email,
          pictureUrl: user.avatar_url ? `${process.env.REACT_APP_BACKEND_URL}${user.avatar_url}` : undefined
        };

        return {
          id: voting.id,
          name: voting.title,
          startDate: this.getLocalDateString(voting.start_date),
          endDate: this.getLocalDateString(voting.end_date),
          votingCreator,
          status: voting.status
        };
      });

      return { list, pages: response?.data?.meta.total_pages, status: response?.status };
    } catch (err) {
      throw err;
    }
  }
}
