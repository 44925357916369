import { Text } from "@chakra-ui/react";
import {
  CustomIconWIthTooltip,
  CustomInputWithCounter,
  CustomInputWithCounterProps
} from "@lubbezposrednio-frontend/commons";

type LandingPageInputProps = CustomInputWithCounterProps & {
  value: string;
  onChange: (value: string) => void;
  label: string;
  tooltip?: string;
  maxLength: number;
  valueForCounter?: string;
};

export const LandingPageInput: React.FC<LandingPageInputProps> = ({
  value,
  onChange,
  label,
  tooltip,
  maxLength,
  valueForCounter,
  ...props
}) => {
  return (
    <>
      <CustomIconWIthTooltip tooltipMsg={tooltip}>
        <Text variant="smallTitle" pb="10px">
          {label}
        </Text>
      </CustomIconWIthTooltip>

      <CustomInputWithCounter
        as="textarea"
        value={value}
        onChange={onChange}
        color="primary"
        maxLength={maxLength}
        valueForCounter={valueForCounter}
        {...props}
      />
    </>
  );
};
