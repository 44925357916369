import { useMemo } from "react";
import { Flex, IconButton, Text, VStack, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { CreatedLandingPagesListViewModel } from "../CreatedLandingPagesListViewModel";
import { CustomList, CustomTile, CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { LIST_BOTTOM_PADDING, LP_STATUSES, NEW_TAB_WARNING, TILE_TITLE_STYLES } from "@lubbezposrednio-frontend/core";
import { LandingPageData } from "./LandingPageData";
import { CopyIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

export const LandingPagesList: React.FC<{ model: CreatedLandingPagesListViewModel }> = observer(({ model }) => {
  return (
    <CustomList model={model} pb={`${LIST_BOTTOM_PADDING}px`} noItemsMessage="Brak utworzonych zapisów na głosowanie">
      <VStack gap="33px" pb={`${LIST_BOTTOM_PADDING}px`}>
        {model.currentPageItems.map(landingPage => (
          <CustomTile
            key={landingPage.id}
            tileWidth={1088}
            maxWidth="80vw"
            customTitle={
              <CustomTileTitle
                id={landingPage.id}
                isActive={landingPage.isActive}
                title={landingPage.pageTitle}
                isDraft={landingPage.status === LP_STATUSES.DRAFT}
              />
            }
            pr="26px"
            pb="23px"
          >
            <LandingPageData landingPage={landingPage} model={model} />
          </CustomTile>
        ))}
      </VStack>
    </CustomList>
  );
});

type CustomTileTitleProps = { isActive: boolean; title: string; id: string; isDraft: boolean };

const CustomTileTitle: React.FC<CustomTileTitleProps> = ({ isActive, title, id, isDraft }) => {
  const LPUrl = `${window.origin}/zapis-na-glosowanie/${id}`;
  const toast = useToast({
    status: "success",
    title: "Skopiowano adres strony zapisu na głosowanie",
    isClosable: true
  });

  const statusLabel = useMemo(
    () => (isDraft ? "wersja robocza" : isActive ? "aktywny" : "nieaktywny"),
    [isActive, isDraft]
  );

  return (
    <Flex {...TILE_TITLE_STYLES} pl="22px" pr="27px" justify="space-between" wrap={"wrap"}>
      <Flex>
        {!isDraft && (
          <CustomTooltip message="Skopiuj adres strony zapisu na głosowanie">
            <IconButton
              icon={<CopyIcon />}
              aria-label="copy page url"
              variant="iconButton"
              h="100%"
              onClick={() => {
                navigator.clipboard.writeText(LPUrl);
                toast();
              }}
            />
          </CustomTooltip>
        )}
        {isDraft ? (
          <Text>{title}</Text>
        ) : (
          <CustomTooltip message={NEW_TAB_WARNING}>
            <Link to={LPUrl} target="_blank">
              <Text>{title}</Text>
            </Link>
          </CustomTooltip>
        )}
      </Flex>
      <Text pl="8px" color={isActive ? "success" : "accent"}>
        {statusLabel}
      </Text>
    </Flex>
  );
};
