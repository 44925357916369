import { Box, Text, VStack } from "@chakra-ui/react";
import { CreateVotingTile } from "../CreateVotingTile";
import { observer } from "mobx-react-lite";
import { CustomInputWithCounter, CustomDatePicker, CustomIconWIthTooltip } from "@lubbezposrednio-frontend/commons";
import { VotingDataModel } from "./VotingDataModel";
import { VOTING_DATA_ERR_KEYS } from "@lubbezposrednio-frontend/core";

export const VotingData: React.FC<{
  model: VotingDataModel;
}> = observer(({ model }) => {
  const {
    [VOTING_DATA_ERR_KEYS.NAME]: nameErr,
    [VOTING_DATA_ERR_KEYS.BLUR_START]: blurStartErr,
    [VOTING_DATA_ERR_KEYS.SUBMIT_START]: submitStartErr,
    [VOTING_DATA_ERR_KEYS.BLUR_END]: blurEndErr,
    [VOTING_DATA_ERR_KEYS.SUBMIT_END]: submitEndErr
  } = model.errors;

  const startDateErr = blurStartErr || submitStartErr;
  const endDateErr = blurEndErr || submitEndErr;

  return (
    <CreateVotingTile title="Dane głosowania" pt="16px" pb={{ sm: "40px" }} pr="auto">
      <VStack alignItems="left" spacing={0}>
        <Text variant="smallTitle" pb="10px">
          Nazwa głosowania
        </Text>
        <Box pb="21px">
          <CustomInputWithCounter
            value={model.name}
            onChange={model.setName.bind(model)}
            color="primary"
            h="38px"
            maxLength={300}
          />
          {nameErr && (
            <Text variant="error" pb={0}>
              {nameErr}
            </Text>
          )}
        </Box>
        <CustomIconWIthTooltip tooltipMsg="Uprawnione przez Ciebie osoby będą mogły głosować tylko w wyznaczonym czasie. Głosy przed i po terminie nie będą możliwe. Nie będzie możliwa zmiana czasu głosowania">
          <Text variant="smallTitle" pb={{ base: "16px", sm: "10px" }}>
            Czas trwania głosowania
          </Text>
        </CustomIconWIthTooltip>
        <CustomDatePicker
          label="od"
          onChange={model.changeStartDate.bind(model)}
          value={model.startVotingDate}
          onBlur={() => model.saveDateError(model.startVotingDate, VOTING_DATA_ERR_KEYS.BLUR_START)}
        />
        {startDateErr && <Text variant="error">{startDateErr}</Text>}
        <CustomDatePicker
          label="do"
          onChange={model.changeEndDate.bind(model)}
          value={model.endVotingDate}
          onBlur={() => model.saveDateError(model.endVotingDate, VOTING_DATA_ERR_KEYS.BLUR_END)}
        />
        {endDateErr && <Text variant="error">{endDateErr}</Text>}
      </VStack>
    </CreateVotingTile>
  );
});
