import axios, { AxiosResponse } from "axios";
import { EditProfileType, ProfileApiData } from "../types/profile";
import { axiosClient } from "./axiosClient";

export const getProfile = async () => axiosClient.get<ProfileApiData>("/user");

export const editProfile = async (user: EditProfileType) => {
  const formData = new FormData();

  Object.entries(user).forEach(([key, value]) => {
    const passedValue = typeof value === "number" || typeof value === "boolean" ? String(value) : value;
    passedValue !== null && formData.append(`user[${key}]`, passedValue);
  });

  return axiosClient.patch("/user", formData, { headers: { "Content-Type": "multipart/form-data" } });
};

export const getAffirmationTemplate = async (): Promise<AxiosResponse<Blob> | undefined> => {
  try {
    return axiosClient.get<Blob>("/documents/affirmation", {
      responseType: "blob"
    });
  } catch (err) {
    console.error(err);
  }
};

export const downloadAffirmation = async (affirmationUrl: string): Promise<AxiosResponse<Blob> | undefined> => {
  try {
    return await axios.get<Blob>(`${process.env.REACT_APP_BACKEND_URL}/${affirmationUrl}`, {
      responseType: "blob"
    });
  } catch (err) {
    console.error(err);
  }
};
