import { VStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

interface LayoutProps {
  children: ReactNode;
  isMenu?: boolean;
  redirectBaseUrl?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, isMenu, redirectBaseUrl }) => (
  <VStack h="100%" minH="100vh" backgroundImage="url('/pattern_background.svg')" justify={"stretch"}>
    <Header isMenu={isMenu} logoRedirectUrl={redirectBaseUrl} />
    <VStack gap={0} h="100%" flexGrow={1} justifyContent="center">
      {children}
    </VStack>
    <Footer redirectBaseUrl={redirectBaseUrl} />
  </VStack>
);
