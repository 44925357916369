import { Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CustomButton, Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";

export const VoteConfirmFailedScreen: React.FC = () => {
  useEffect(() => {
    document.title = "Błąd potwierdzenia głosowania";
  }, []);

  const { id } = useParams();

  return (
    <Layout>
      <VStack spacing={0} flex={1} py={{ base: 0, sm: "135px" }} maxW="100vw">
        <MessageWithIcon
          image="/ICON_voting.svg"
          title="Nieprawidłowy link"
          message={
            <Text>
              Link do potwierdzenia oddania głosu jest nieprawidłowy, przeterminowany lub był wcześniej użyty. W razie
              problemów{" "}
              <Link to={`/glosowanie/${id}/organizator`} style={{ textDecoration: "underline" }}>
                skontaktuj się z organizatorem
              </Link>
              .
            </Text>
          }
          bottomElement={
            <Link to="/" tabIndex={-1}>
              <CustomButton
                text="Strona główna"
                backgroundColor="accent"
                mb="-20px"
                display={{ base: "flex", md: "none" }}
                w="200px"
              />
            </Link>
          }
          isError
        />
        <Link to="/" tabIndex={-1}>
          <CustomButton
            text="Strona główna"
            backgroundColor="accent"
            my="50px"
            display={{ base: "none", md: "flex" }}
          />
        </Link>
      </VStack>
    </Layout>
  );
};
