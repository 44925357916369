import { FC } from "react";
import { Model } from "../models/Model";
import { ConstructorOf } from "../mvvm/core";

export function withModel<T extends Model, P extends Object = {}>(
  Component: FC<{ model: T } & P>,
  Model: ConstructorOf<T>
) {
  const model = new Model();
  model.onInit();

  return (props: P) => {
    return <Component model={model} {...props} />;
  };
}
