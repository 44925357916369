import { createBrowserRouter } from "react-router-dom";
import ActiveVotingsView from "../views/ActiveVotingsView/ActiveVotingsView";
import HomeView from "../views/HomeView/HomeView";
import { LoginView } from "../views/LoginView/LoginView";
import ProfileView from "../views/ProfileView/ProfileView";
import ConsentsViewView from "../views/ConsentsView/ConsentsView";
import { CreateVotingView } from "../views/CreateVotingView/CreateVotingView";
import { RegisterView } from "../views/RegisterView/RegisterView";
import CreatedVotingsListView from "../views/CreatedVotingsListView/CreatedVotingsListView";
import { RegistrationConfirmEmailSentScreen } from "../views/RegistrationConfirmEmailSentScreen/RegistrationConfirmEmailSentScreen";
import {
  addLeadEmailConfirmRouteLoader,
  landingPageLoader,
  loginRouteLoader,
  organizerPathsLoader,
  registrationConfirmRouteLoader,
  removeAccountRouteLoader,
  voteConfirmRouteLoader
} from "@lubbezposrednio-frontend/core";
import { CreateVotingSummaryView } from "../views/CreateVotingSummaryView/CreateVotingSummaryView";
import { RegistrationConfirmView } from "../views/RegistrationConfirmView/RegistrationConfirmView";
import VotingView from "../views/VotingView/VotingView";
import { RegisterFailedView } from "../views/RegisterFailedView/RegisterFailedView";
import ForgotPasswordView from "../views/ForgotPasswordView/ForgotPasswordView";
import ResetPasswordView from "../views/ResetPasswordView/ResetPasswordView";
import { PasswordResetSuccessView } from "../views/PasswordResetSuccessView/PasswordResetSuccessView";
import { VotingConfirmEmailSentScreen } from "../views/VotingConfirmEmailSentScreen/VotingConfirmEmailSentScreen";
import { VoteConfirmScreen } from "../views/VoteConfirmScreen/VoteConfirmScreen";
import VotingOrganizerView from "../views/VotingOrganizerView/VotingOrganizerView";
import { VoterNotAllowedScreen } from "../views/VoterNotAllowedScreen/VoterNotAllowedScreen";
import { VoteConfirmFailedScreen } from "../views/VoteConfirmFailedScreen/VoteConfirmFailedScreen";
import { VotingFinishedScreen } from "../views/VotingFinishedScreen/VotingFinishedScreen";
import { VotingNotStartedScreen } from "../views/VotingNotStartedScreen/VotingNotStartedScreen";
import { AccessibilityDeclarationView } from "../views/AccessibilityDeclaration/AccessibilityDeclarationView";
import { AboutView } from "../views/AboutView/AboutView";
import { HowToVoteView } from "../views/HowToVoteView/HowToVoteView";
import { HowToCreateVotingView } from "../views/HowToCreateVotingView/HowToCreateVotingView";
import { FAQView } from "../views/FAQView/FAQView";
import { SessionExpiredView } from "../views/SessionExpiredView/SessionExpiredView";
import { RemoveAccountConfirmScreen } from "../views/RemoveAccountConfirmScreen/RemoveAccountConfirmScreen";
import { RemoveAccountConfirmEmailSentScreen } from "../views/RemoveAccountConfirmEmailSentScreen/RemoveAccountConfirmEmailSentScreen";
import { RemoveAccountConfirmFailedScreen } from "../views/RemoveAccountConfirmFailedScreen/RemoveAccountConfirmFailedScreen";
import CreateLandingPageView from "../views/CreateLandingPageView/CreateLandingPageView";
import { CreateLandingConfirmationScreen } from "../views/CreateLandingConfirmationScreen/CreateLandingConfirmationScreen";
import CreatedLandingPagesListView from "../views/CreatedLandingPagesListView/CreatedLandingPagesListView";
import { Screen404, ServerErrorScreen } from "@lubbezposrednio-frontend/commons";
import { AddEmailConfirmScreen } from "../views/AddEmailConfirmScreen/AddEmailConfirmScreen";
import { AddEmailFailedScreen } from "../views/AddEmailFailedScreen/AddEmailFailedScreen";
import LandingPageView from "../views/LandingPageView/LandingPageView";
import { HowToUseExplorerView } from "../views/HowToUseExplorerView/HowToUseExplorerView";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeView />,
    errorElement: <Screen404 />
  },
  {
    path: "/nie-znaleziono-strony",
    element: <Screen404 />
  },
  {
    path: "/logowanie",
    element: <LoginView />,
    loader: loginRouteLoader
  },
  {
    path: "/rejestracja",
    element: <RegisterView />
  },
  {
    path: "/wyslanie-maila-z-potwierdzeniem-rejestracji",
    element: <RegistrationConfirmEmailSentScreen />
  },
  {
    path: "/potwierdzenie-rejestracji",
    element: <RegistrationConfirmView />,
    loader: registrationConfirmRouteLoader
  },
  {
    path: "/aktywne-glosowania",
    element: <ActiveVotingsView />
  },
  {
    path: "/glosowanie/:id",
    element: <VotingView />
  },
  {
    path: "/glosowanie/:id/organizator",
    element: <VotingOrganizerView />
  },
  {
    path: "/blad-potwierdzenia-rejestracji",
    element: <RegisterFailedView />
  },
  {
    path: "/blad-serwera",
    element: <ServerErrorScreen />
  },
  {
    path: "/zapomnialem-hasla",
    element: <ForgotPasswordView />
  },
  {
    path: "/ustaw-haslo",
    element: <ResetPasswordView />
  },
  {
    path: "/sukces-resetu-hasla",
    element: <PasswordResetSuccessView />
  },
  {
    path: "/wyslanie-maila-z-potwierdzeniem-glosowania",
    element: <VotingConfirmEmailSentScreen />
  },
  {
    path: "/glosowanie/:id/nieuprawiony-email",
    element: <VoterNotAllowedScreen />
  },
  {
    path: "/glosowanie/:id/zakonczone",
    element: <VotingFinishedScreen />
  },
  {
    path: "/glosowanie/:id/planowane",
    element: <VotingNotStartedScreen />
  },
  {
    path: "/potwierdzenie-glosowania",
    element: <VoteConfirmScreen />,
    loader: voteConfirmRouteLoader
  },
  {
    path: "/wyslanie-maila-z-potwierdzeniem-usuniecia-konta",
    element: <RemoveAccountConfirmEmailSentScreen />
  },
  {
    path: "/potwierdzenie-usuniecia-konta",
    element: <RemoveAccountConfirmScreen />,
    loader: removeAccountRouteLoader
  },
  {
    path: "/blad-potwierdzenia-usuniecia-konta",
    element: <RemoveAccountConfirmFailedScreen />
  },
  {
    path: "/glosowanie/:id/blad-potwierdzenia-glosowania",
    element: <VoteConfirmFailedScreen />
  },
  {
    path: "/deklaracja-dostepnosci",
    element: <AccessibilityDeclarationView />
  },
  {
    path: "/o-nas",
    element: <AboutView />
  },
  {
    path: "/jak-stworzyc-glosowanie",
    element: <HowToCreateVotingView />
  },
  {
    path: "/jak-zaglosowac",
    element: <HowToVoteView />
  },
  {
    path: "/faq",
    element: <FAQView />
  },
  {
    path: "/sesja-wygasla",
    element: <SessionExpiredView />
  },
  {
    path: "/potwierdzenie-zapisu-na-liste",
    element: <AddEmailConfirmScreen />,
    loader: addLeadEmailConfirmRouteLoader
  },
  {
    path: "/blad-potwierdzenia-zapisu-na-liste",
    element: <AddEmailFailedScreen />
  },
  {
    path: "/zapis-na-glosowanie/:id",
    element: <LandingPageView />,
    loader: landingPageLoader
  },
  {
    path: "/eksplorer-jak-korzystac",
    element: <HowToUseExplorerView />
  },

  {
    path: "/organizator",
    loader: organizerPathsLoader,
    children: [
      {
        path: "/organizator/utworz-glosowanie/podsumowanie",
        element: <CreateVotingSummaryView />
      },
      {
        path: "/organizator/utworz-glosowanie",
        element: <CreateVotingView />
      },
      {
        path: "/organizator/twoje-glosowania",
        element: <CreatedVotingsListView />
      },
      {
        path: "/organizator/zgody",
        element: <ConsentsViewView />
      },
      {
        path: "/organizator/konto",
        element: <ProfileView />
      },
      {
        path: "/organizator/modyfikuj-strone-zapisu-na-glosowanie",
        element: <CreateLandingPageView />
      },
      {
        path: "/organizator/modyfikuj-strone-zapisu-na-glosowanie/potwierdzenie",
        element: <CreateLandingConfirmationScreen />
      },
      {
        path: "/organizator/lista-zapisow-na-glosowanie",
        element: <CreatedLandingPagesListView />
      }
    ]
  }
]);
