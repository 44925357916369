import {
  AddEmailParams,
  ApiLandingPageListType,
  CreateLandingPageParams,
  GetLandingPagesParams,
  LandingPageDataType,
  LandingPageDraftDataType
} from "../types";
import { axiosClient } from "./axiosClient";

const appendAdditionalDescriptions = (descriptions: string[] = [], formData: FormData) => {
  descriptions.forEach(description => {
    formData.append("landing_page[additional_descriptions][]", description);
  });
};

const getFormData = (data: Partial<CreateLandingPageParams>): FormData => {
  const formData = new FormData();

  const { additional_descriptions, ...restParams } = data;
  Object.entries(restParams).forEach(([key, value]) => {
    value && formData.append(`landing_page[${key}]`, value);
  });
  additional_descriptions && appendAdditionalDescriptions(additional_descriptions, formData);

  return formData;
};

export const createLandingPage = async (params: Partial<CreateLandingPageParams>) => {
  try {
    return await axiosClient.post("landing_pages", getFormData(params), {
      headers: { "Content-Type": "multipart/form-data" }
    });
  } catch (err) {
    console.error(err);
  }
};

export const editLandingPage = async (id: string, params: Partial<CreateLandingPageParams>) => {
  try {
    return await axiosClient.put(`landing_pages/${id}`, getFormData(params), {
      headers: { "Content-Type": "multipart/form-data" }
    });
  } catch (err) {
    console.error(err);
  }
};

export const getLandingPages = async (params: GetLandingPagesParams) =>
  axiosClient.get<ApiLandingPageListType>("landing_pages", { params });

export const getLandingPage = async (id: string) =>
  axiosClient.get<LandingPageDataType | LandingPageDraftDataType>(`landing_pages/${id}`);

export const deleteLandingPage = async (id: string) =>
  axiosClient.delete<LandingPageDataType | LandingPageDraftDataType>(`landing_pages/${id}`);

export const addEmail = async (id: string, params: AddEmailParams) =>
  axiosClient.post(`landing_pages/${id}/lead`, { lead: params });

export const confirmAddEmail = async (token: string) =>
  axiosClient.post("lead/confirm", {
    confirmation_token: token
  });
