import { Flex, FlexProps } from "@chakra-ui/react";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import { InfoOutlineIcon } from "@chakra-ui/icons";

type CustomIconWIthTooltipProps = FlexProps & {
  tooltipMsg?: string;
  children: React.ReactNode;
};

export const CustomIconWIthTooltip: React.FC<CustomIconWIthTooltipProps> = ({ tooltipMsg, children, ...props }) => {
  return (
    <Flex gap="10px" {...props}>
      {tooltipMsg && (
        <CustomTooltip message={tooltipMsg}>
          <InfoOutlineIcon color="primary" tabIndex={0} />
        </CustomTooltip>
      )}
      {children}
    </Flex>
  );
};
