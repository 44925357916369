import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Box, FlexProps, SimpleGrid } from "@chakra-ui/react";
import { CustomList } from "@lubbezposrednio-frontend/commons";
import { TILE_WIDTH, CreatedVotingTile } from "./CreatedVotingTile";
import { CreatedVotingsListViewModel } from "../../CreatedVotingsListViewModel";
import { VotingsListModel } from "../../../../models/VotingsListModel";
import { VotingTileButtonProps } from "./VotingTileButton";
import { LIST_BOTTOM_PADDING, VOTING_STATUSES, useIsMobile } from "@lubbezposrednio-frontend/core";

export const VOTING_LIST_ID = "voting-list";

type VotingsListProps = FlexProps & {
  model: VotingsListModel & Partial<CreatedVotingsListViewModel>;
  Button: React.FC<VotingTileButtonProps>;
  getIsCustomTile?: (status?: VOTING_STATUSES) => boolean;
  CustomDates?: React.FC<{ start?: string; id: string }>;
  getCustomTitle?: (id: string, title?: string, status?: VOTING_STATUSES) => React.ReactNode;
};

export const VotingsList: React.FC<VotingsListProps> = observer(
  ({ model, Button, getIsCustomTile, CustomDates, getCustomTitle, ...props }) => {
    const isMobile = useIsMobile((TILE_WIDTH * 2) / 0.7);

    const stretchListContainerHeight = useMemo(
      () =>
        model.openItemPositionData?.isOutside && model.openItemPositionData?.height
          ? `${model.openItemPositionData.offset + LIST_BOTTOM_PADDING}px`
          : 0,
      [JSON.stringify(model.openItemPositionData)]
    );

    return (
      <CustomList model={model} {...props}>
        <Box id={VOTING_LIST_ID} display="flex" justifyContent="center" flexDirection="column">
          <SimpleGrid
            columns={isMobile ? 1 : 2}
            spacingX="16px"
            spacingY={{ base: "65px", sm: "75px" }}
            pb={`${LIST_BOTTOM_PADDING}px`}
            maxW="70vw"
          >
            {model.currentPageItems.map(voting => (
              <CreatedVotingTile
                key={voting.id}
                voting={voting}
                model={model}
                Button={Button}
                isCustomTile={getIsCustomTile?.(voting.status)}
                CustomDates={CustomDates}
                customTitle={getCustomTitle?.(voting.id, voting.name, voting.status)}
              />
            ))}
          </SimpleGrid>
          <Box h={stretchListContainerHeight} />
        </Box>
      </CustomList>
    );
  }
);
