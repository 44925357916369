import { Flex, Text, VStack, Box } from "@chakra-ui/layout";
import { Button, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import QRCode from "qrcode.react";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { LandingPageViewModel } from "../LandingPageViewModel";
import { observer } from "mobx-react-lite";

export const QR_ID = "QRCodeCanvas";

export const LandingPageQR: React.FC<{ model: LandingPageViewModel }> = observer(({ model }) => {
  useEffect(() => {
    model.getQrCodeBLob();
  }, []);

  return (
    <Flex
      pr={{ md: "10px" }}
      wrap="wrap"
      gap={{ base: "12.5px", sm: "16px" }}
      direction={{ base: "column", sm: "row" }}
      w={{ base: "100%", sm: "auto" }}
      align="center"
    >
      <Box
        w={{ base: "95px", sm: "140px" }}
        h={{ base: "95px", sm: "140px" }}
        bg="white"
        p={{ base: "10px", sm: "15px" }}
        borderRadius="standard"
        borderColor="primary"
        borderWidth="2px"
      >
        <QRCode
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          fgColor="#430098"
          value={window.location.href}
          id={QR_ID}
        />
      </Box>
      <VStack flex={1} justify="center" gap={{ base: "6px", sm: "11px" }} align={{ sm: "start" }}>
        <CustomTooltip message="Jeżeli zapisy mają charakter otwarty – możesz pobrać i wydrukować kod QR, by przekazać informację o zapisach na głosowanie osobom w swojej społeczności. W razie wątpliwości – skontaktuj się z organizatorem!">
          <Text
            fontSize={{ base: "22px", sm: "29px" }}
            lineHeight={{ base: "27px", sm: "35px" }}
            color="primary"
            fontWeight="bold"
            textAlign={{ base: "center", sm: "start" }}
          >
            Udostępnij link do zapisów innym!
          </Text>
        </CustomTooltip>
        <CustomTooltip message={`${model.qrCodeSize}, PNG`}>
          <Button
            bg="accent"
            gap={{ base: "11px", sm: "15px" }}
            color="background"
            px={{ base: "15px", sm: "20px" }}
            h={{ base: "36px", sm: "46px" }}
            borderRadius="standard"
            fontSize={{ base: "12px", sm: "16px" }}
            lineHeight="16px"
            _hover={{}}
            onClick={model.downloadQrCode.bind(model)}
            whiteSpace="normal"
          >
            Pobierz QR i udostępnij stronę zapisu na głosowanie <Image src="/Icon ionic-md-download.svg" maxH="16px" />
          </Button>
        </CustomTooltip>
      </VStack>
    </Flex>
  );
});
