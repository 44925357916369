import { HStack } from "@chakra-ui/react";
import React from "react";
import { SocialMediaLink } from "./SocialMediaLink";

interface SocialMediaSectionProps {}

export const SocialMediaSection: React.FC<SocialMediaSectionProps> = ({}) => {
  return (
    <HStack gap="26px">
      <SocialMediaLink icon="/FB.svg" url="https://www.facebook.com/LubBezposrednioPL/" />
      <SocialMediaLink icon="/IG.svg" url="https://www.instagram.com/LubBezposrednioPL/" />
      <SocialMediaLink icon="/Tiktok_Black _Icon.svg" url="https://www.tiktok.com/@lubbezposrednio.pl" />
      <SocialMediaLink icon="/TWIT.svg" url="https://twitter.com/LubBezposrednio" />
      <SocialMediaLink icon="/YouTube_full-color_icon_(2017).svg" url="https://www.youtube.com/@LubBezposrednioPL" />
    </HStack>
  );
};
