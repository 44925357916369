import { Checkbox, CheckboxProps, FormControl, FormControlProps, Text, TextProps, Box } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";

interface CustomCheckboxProps extends Omit<CheckboxProps, "onChange" | "value"> {
  wrapperProps?: FormControlProps;
  labelProps?: TextProps;
  label: React.ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
  required?: boolean;
}

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  value,
  onChange,
  required,
  wrapperProps,
  labelProps,
  ...checkboxProps
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked);
  return (
    <FormControl isRequired={required} {...wrapperProps}>
      <Checkbox isChecked={value} onChange={handleChange} {...checkboxProps}>
        <Box textStyle="text" fontWeight="bold" color="primary" {...labelProps}>
          {label}
          {required && (
            <Text as="span" color="error" ml="6px">
              {"*"}
            </Text>
          )}
        </Box>
      </Checkbox>
    </FormControl>
  );
};
