import { useState, useRef } from "react";
import { Box, IconButton, VStack } from "@chakra-ui/react";
import { onIconWithListClick } from "@lubbezposrednio-frontend/core";

type CustomFilterButtonProps<T extends string> = {
  getIcon: (fill: string) => React.ReactElement;
  label: string;
  value?: T;
  children?: React.ReactNode;
};

export const CustomFilterButton = <T extends string>({
  getIcon,
  label,
  value,
  children
}: CustomFilterButtonProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const listRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);

  return (
    <VStack pos={{ sm: "relative" }}>
      <IconButton
        aria-label={label}
        icon={getIcon(value ? "#FFFAEB" : "#430098")}
        variant="filter"
        onClick={() => onIconWithListClick(setIsOpen, listRef, iconRef)}
        ref={iconRef}
        bg={value ? "primary" : "background"}
      />
      {isOpen && (
        <Box
          mt={"11px"}
          bg={"background"}
          w="260px"
          pos="absolute"
          top="100%"
          left={0}
          zIndex={1}
          borderRadius="standard"
          borderWidth="2px"
          borderColor="primary"
          ref={listRef}
          py="20px"
          px="5px"
        >
          {children}
        </Box>
      )}
    </VStack>
  );
};
