import { Box, Divider, Text, VStack } from "@chakra-ui/layout";
import { VotingQuestionOption } from "./VotingQuestionOptions";
import { convertHtmlToText, NEW_TAB_WARNING, VotingsListQuestion } from "@lubbezposrednio-frontend/core";
import { useMemo } from "react";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";

export const VotingQuestion: React.FC<{
  question: VotingsListQuestion;
  isDivider: boolean;
}> = ({ question, isDivider }) => {
  const isLink = useMemo(() => question.question.includes("<a"), [question.question]);

  return (
    <VStack align="left" textStyle="text" color="primary" gap="20px">
      {isDivider && <Divider ml="22.5px" mt="35px" mb="11px" w={{ base: "80%", sm: "491px" }} />}
      <CustomTooltip message={isLink ? NEW_TAB_WARNING : ""}>
        <Text pl="28px" fontWeight="bold">
          {convertHtmlToText(question.question)}
        </Text>
      </CustomTooltip>
      <Box>
        {question.options.map((option, index) => (
          <VotingQuestionOption key={option} option={option} index={index} />
        ))}
      </Box>
    </VStack>
  );
};
