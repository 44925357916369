import { CONSENT_NAMES } from "./consents";
import { DATE_SORTERS } from "./general";
import { VotingsListQuestion } from "./votingQuestions";
import { ApiVotingQuestion } from "./votingView";

export enum VOTING_STATUSES {
  ONGOING = "ongoing",
  PLANNED = "planned",
  FINISHED = "finished",
  DRAFT = "draft"
}

export enum PUBLICITY_TYPES {
  PUBLIC = "public",
  NON_PUBLIC = "nonPublic"
}

export type GetOrganizerVotingsParams = {
  per_page: number;
  page_number?: number;
  status?: VOTING_STATUSES;
  public?: boolean;
  sort?: DATE_SORTERS;
};

export type VotingCreatorType = {
  pictureUrl?: string;
  name: string;
};

export type VotingListItem = {
  id: string;
  name?: string;
  startDate?: string;
  endDate?: string;
  votingCreator: VotingCreatorType;
  status?: VOTING_STATUSES;
};

export type VotingRequestFeedback = { isSuccess: boolean; message?: string };

export type GetPublicVotingsParams = {
  per_page: number;
  page_number?: number;
  status?: VOTING_STATUSES[];
  sort?: DATE_SORTERS;
};

export type ApiVotingListItem = {
  id: string;
  title: string;
  status: VOTING_STATUSES;
  public: boolean;
  start_date: string; //ISO string
  end_date: string; //ISO string

  user: {
    id?: string;
    email: string;
    is_organization?: boolean;
    [CONSENT_NAMES.APPLICATIONS_SERVICE]?: boolean;
    [CONSENT_NAMES.APPLICATIONS_SERVICE]?: boolean;
    avatar_url?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    organization_name?: string | null;
  };
};

export type ApiVotingListType = {
  votings: (ApiVotingListItem | VotingDraftApiType)[];
  meta: {
    total_pages: number;
  };
};

export type VotingDraftApiType = Partial<Omit<ApiVotingListItem, "user" | "id">> &
  Pick<ApiVotingListItem, "id" | "user">;

export type ApiVotingDraftDetails = {
  voting: VotingDraftApiType & {
    questions?: ApiVotingQuestion[];
    privacy_policy_url?: string | null;
    terms_and_conditions_url?: string | null;
    emails?: { id: string; value: string }[] | null;
    voter_list_url?: string | null;
  };
};

export type VotingDraftDetails = Partial<Omit<VotingListItem, "id" | "votingCreator" | "status" | "type">> & {
  id: string;
  questions?: VotingsListQuestion[];
  privacyPolicyUrl?: string;
  termsAndConditionsUrl?: string;
  emails?: { id: string; value: string }[];
  votersListUrl?: string;
  isPublic?: boolean;
};
