import { Box, BoxProps } from "@chakra-ui/react";
import React, { useState } from "react";
import { CollapsableSection } from "./components/CollapsableSection";
import { CollapseHeader } from "./components/CollapseHeader";

interface CustomCollapseProps extends Omit<BoxProps, "content"> {
  title: string;
  redirectPath: string;
  redirectButtonText: string;
  content: React.ReactNode;
}

export const CustomCollapse: React.FC<CustomCollapseProps> = ({
  title,
  redirectPath,
  redirectButtonText,
  content,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(isOpen => !isOpen);
  return (
    <Box
      borderWidth="2px"
      borderColor={isOpen ? "primary" : "transparent"}
      borderRadius={isOpen ? "15px" : "20px"}
      borderTopRadius="20px"
      w="100%"
      maxW="500px"
      {...rest}
    >
      <CollapseHeader toggleIsOpen={toggleIsOpen} title={title} />
      <CollapsableSection
        redirectButtonText={redirectButtonText}
        redirectPath={redirectPath}
        isOpen={isOpen}
        content={content}
      />
    </Box>
  );
};
