import { VOTING_FILTER_HEIGHT } from "@lubbezposrednio-frontend/core";

export const buttonStyles = {
  variants: {
    menu: {
      color: "primary",
      bg: "transparent",
      w: "260px",
      h: "auto",
      py: "7px",
      borderWidth: "2px",
      borderRadius: "standard",
      borderColor: "primary",
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "bold"
    },
    filter: {
      bg: "background",
      w: "59px",
      h: VOTING_FILTER_HEIGHT,
      borderWidth: "2px",
      borderColor: "primary",
      borderRadius: "standard"
    },
    regular: {
      background: "primary",
      color: "background",
      borderRadius: "standard"
    },
    statusFilter: {
      w: "260px",
      h: VOTING_FILTER_HEIGHT,
      borderWidth: "2px",
      borderRadius: "standard",
      borderColor: "primary",
      fontSize: "18px",
      lineHeight: "20px",
      fontWeight: "bold"
    },
    iconButton: {
      _active: {
        bg: "background",
        color: "primary"
      },
      _hover: {},
      color: "background"
    }
  }
};
