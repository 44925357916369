import { redirect } from "react-router-dom";
import {
  LOCAL_STOREGE_KEYS,
  activateAccount,
  clearLocalStorageToken,
  confirmVote,
  refreshAuthToken,
  ConfirmVotingErrorDataType,
  confirmAddEmail,
  confirmDeactivateAccount,
  getLandingPage
} from "@lubbezposrednio-frontend/core";
import { AxiosError, AxiosResponse } from "axios";

export const organizerPathsLoader = async ({ request }: { request: any }) => {
  const accessToken = localStorage.getItem(LOCAL_STOREGE_KEYS.ACCESS_TOKEN);
  const tokenExpiryDate = Number(localStorage.getItem(LOCAL_STOREGE_KEYS.TOKEN_EXPITY_DATE));
  const refreshTokenExpiryDate = Number(localStorage.getItem(LOCAL_STOREGE_KEYS.REFRESH_TOKEN_EXPITY_DATE));

  if (!accessToken || refreshTokenExpiryDate < Date.now()) {
    clearLocalStorageToken();
    return redirect("/");
  }

  if (request.url.endsWith("/organizator")) {
    return redirect("/organizator/utworz-glosowanie");
  }

  if (accessToken && tokenExpiryDate < Date.now()) {
    refreshAuthToken();
  }

  return null;
};

export const loginRouteLoader = async ({ request }: { request: any }) => {
  const accessToken = localStorage.getItem(LOCAL_STOREGE_KEYS.ACCESS_TOKEN);
  const refreshTokenExpiryDate = Number(localStorage.getItem(LOCAL_STOREGE_KEYS.REFRESH_TOKEN_EXPITY_DATE));

  if (!accessToken || refreshTokenExpiryDate < Date.now()) {
    return null;
  }

  return redirect("/organizator");
};

const loadPageWithPreRequest = async <T>(
  request: Request,
  cbRequest: (token: string) => Promise<AxiosResponse>,
  customErrorHandler?: (status?: number, data?: T) => void
) => {
  const url = new URL(request.url);
  const token = url.searchParams.get("token");

  if (token) {
    try {
      const response = await cbRequest(token);
      if (response.status === 200 || response.status === 204) {
        return null;
      }

      if (customErrorHandler) {
        return customErrorHandler?.(response.status);
      }
    } catch (err) {
      console.error(err);

      if (customErrorHandler) {
        return customErrorHandler?.((err as AxiosError).response?.status, (err as AxiosError<T>).response?.data);
      }
    }
  }
  return redirect("/blad-serwera");
};

export const registrationConfirmRouteLoader = async ({ request }: { request: Request }) => {
  const handleError = (status?: number) =>
    redirect(status === 422 ? "/blad-potwierdzenia-rejestracji" : "/blad-serwera");

  return loadPageWithPreRequest(request, activateAccount, handleError);
};

export const voteConfirmRouteLoader = async ({ request }: { request: Request }) => {
  const handleError = (status?: number, data?: ConfirmVotingErrorDataType) =>
    redirect(status === 422 ? `/glosowanie/${data?.voting_id}/blad-potwierdzenia-glosowania` : "/blad-serwera");

  return loadPageWithPreRequest<ConfirmVotingErrorDataType>(request, confirmVote, handleError);
};

export const removeAccountRouteLoader = async ({ request }: { request: Request }) => {
  const handleError = (status?: number) =>
    redirect(status === 422 ? "/blad-potwierdzenia-usuniecia-konta" : "/blad-serwera");

  return loadPageWithPreRequest<ConfirmVotingErrorDataType>(request, confirmDeactivateAccount, handleError);
};

export const addLeadEmailConfirmRouteLoader = async ({ request }: { request: Request }) => {
  const handleError = (status?: number) =>
    redirect(status === 422 ? "/blad-potwierdzenia-zapisu-na-liste" : "/blad-serwera");

  return loadPageWithPreRequest(request, confirmAddEmail, handleError);
};

export const landingPageLoader = async ({ params }: { params: { id?: string } }) => {
  if (params.id) {
    try {
      const response = await getLandingPage(String(params.id));

      if (response.status === 200 && response.data) {
        const { landing_page: data } = response.data;
        const isBeforePublication = data.start_date && new Date(data.start_date).getTime() > Date.now();
        if (isBeforePublication) {
          return redirect("/nie-znaleziono-strony");
        }
        return data;
      }

      return redirect("/blad-serwera");
    } catch (err: any) {
      console.error(err);
      return redirect(err.response?.status === 404 ? "/nie-znaleziono-strony" : "/blad-serwera");
    }
  } else {
    return redirect("/nie-znaleziono-strony");
  }
};
