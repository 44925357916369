import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";

import { theme } from "@lubbezposrednio-frontend/core";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/Router";
import { CustomLoader, TokenExpiryDateValidator } from "@lubbezposrednio-frontend/commons";

export const App = () => (
  <ChakraProvider theme={theme}>
    <TokenExpiryDateValidator />
    <RouterProvider router={router} fallbackElement={<CustomLoader />} />
  </ChakraProvider>
);
