import { useCallback } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

type PaginationItemProps = ButtonProps & {
  updatedPage: number;
  content: string;
  changePage: (page: number) => void;
  isDisabled?: boolean;
};

export const PaginationItem: React.FC<PaginationItemProps> = ({
  updatedPage,
  content,
  changePage,
  isDisabled,
  ...props
}) => {
  const onClick = useCallback(async () => {
    if (!isDisabled) {
      changePage(updatedPage);
    }
  }, [isDisabled, updatedPage, changePage]);

  return (
    <Button
      variant="ghost"
      textStyle="text"
      onClick={onClick}
      color="primary"
      borderRadius="standard"
      size={{ base: "sm", sm: "md" }}
      {...props}
    >
      {content}
    </Button>
  );
};
