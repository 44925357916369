import React from "react";
import { VotingViewQuestion as VotingViewQuestionType } from "@lubbezposrednio-frontend/core";
import { VotingViewTile } from "../VotingViewTile";
import { Checkbox, CheckboxGroup, Text, VStack } from "@chakra-ui/react";
import { VotingViewModel } from "../../VotingViewModel";
import { observer } from "mobx-react-lite";

type VotingViewQuestionProps = {
  question: VotingViewQuestionType;
  model: VotingViewModel;
};

const PADDING_LEFT = { sm: "37px" };

export const VotingViewQuestion: React.FC<VotingViewQuestionProps> = observer(({ question, model }) => {
  const error = model.getQuestionError(question.id);

  return (
    <VotingViewTile title={question.content}>
      <VStack
        spacing="25px"
        pt={{ base: "21px", sm: "11px" }}
        pl={PADDING_LEFT}
        pb={{ base: "52px", sm: "35px" }}
        align="flex-start"
      >
        <CheckboxGroup
          onChange={values => {
            const value = values[values.length - 1];
            model.selectAnswer(question.id, value ? +value : undefined);
          }}
          value={question.selectedAnswerId !== undefined ? [question.selectedAnswerId] : undefined}
        >
          {question.answers.map(answer => (
            <Checkbox key={answer.id} value={answer.id} variant="votingAnswer">
              <Text variant="votingSummaryLabel">{answer.content}</Text>
            </Checkbox>
          ))}
        </CheckboxGroup>
        {error && <Text variant="error">{error}</Text>}
      </VStack>
    </VotingViewTile>
  );
});
