import { action, computed, makeObservable, observable } from "mobx";
import {
  Model,
  MIN_INPUT_LENGTH,
  AnswerErrorType,
  QuestionErrorType,
  VotingAnswerType,
  VotingQuestionArrayType,
  VotingQuestionType,
  ToastType,
  addLinksToText,
  VotingDraftDetails,
  getPlainTextFromTextWithLinks
} from "@lubbezposrednio-frontend/core";

const EMPTY_QUESTION = {
  question: "",
  answers: new Map([
    [1, { id: 1, content: "" }],
    [2, { id: 2, content: "" }]
  ])
};

const MIN_ANSWERS = 2;

export class VotingQuestionsModel extends Model {
  questions: Map<number, VotingQuestionType<Map<number, VotingAnswerType>>> = new Map([
    [1, { ...EMPTY_QUESTION, id: 1 }]
  ]);
  questionsErrors: QuestionErrorType[] = [];
  answerErrors: AnswerErrorType[] = [];

  constructor() {
    super();

    makeObservable(this, {
      questions: observable,
      changeQuestion: action.bound,
      changeAnswer: action.bound,
      addAnswer: action.bound,
      removeAnswer: action.bound,
      addQuestion: action.bound,
      removeQuestion: action.bound,

      questionsErrors: observable,
      saveQuestionError: action.bound,
      answerErrors: observable,
      saveAnswersError: action.bound,

      removeLastEmptyAnswer: action.bound,
      removeLastEmptyQuestion: action.bound,
      loadDataFromDraft: action.bound,

      questionsArray: computed,
      isError: computed,

      clearData: action.bound,
      clearErrors: action.bound
    });
  }

  public loadDataFromDraft(draft: VotingDraftDetails) {
    draft.questions?.forEach((question, i) => {
      const answersMap = new Map<number, VotingAnswerType>();

      question.options.forEach((answer, index) => {
        answersMap.set(index + 1, { id: index + 1, content: answer });
      });

      this.questions.set(i + 1, {
        id: i + 1,
        question: getPlainTextFromTextWithLinks(question.question),
        transformedQuestion: question.question,
        answers: answersMap
      });
    });
  }

  private checkQuestion(questionId: number, value: string) {
    const isError = this.questionsErrors.find(error => error.id === questionId);

    if (isError) {
      this.saveQuestionError(
        questionId,
        this.isMinLength(value) ? "" : `Pytanie musi mieć minimum ${MIN_INPUT_LENGTH} znaki`
      );
    }
  }

  public changeQuestion(questionId: number, value: string) {
    const currQuestion = this.questions.get(questionId);
    if (currQuestion) {
      const updatedQuestion = { ...currQuestion, question: value };
      this.questions.set(questionId, updatedQuestion);

      this.checkQuestion(questionId, value);
    }
  }

  private checkAnswer(questionId: number, answerId: number, value: string) {
    const isError = this.answerErrors.find(error => error.answerId === answerId && error.questionId === questionId);

    if (isError) {
      this.saveAnswersError(
        questionId,
        answerId,
        this.isMinLength(value) ? "" : `Odpowiedź musi mieć minimum ${MIN_INPUT_LENGTH} znaki`
      );
    }
  }

  public changeAnswer(questionId: number, answerId: number, value: string) {
    const updatedAnswer = { id: answerId, content: value };
    this.questions.get(questionId)?.answers.set(answerId, updatedAnswer);

    this.checkAnswer(questionId, answerId, value);
  }

  public addAnswer(questionId: number, toast: ToastType) {
    const currAnswers = this.questions.get(questionId)?.answers;
    if (currAnswers) {
      const answersArr = Array.from(currAnswers);

      const isAllAnswers = answersArr.every(answer => !!answer[1].content.trim());

      if (isAllAnswers) {
        const newAnswerId = answersArr[currAnswers.size - 1][0] + 1;

        return currAnswers.set(newAnswerId, { id: newAnswerId, content: "" });
      }
      toast({
        title: "Uzupełnij wszystkie odpowiedzi",
        status: "error",
        isClosable: true
      });
    }
  }

  public removeAnswer(questionId: number, answerId: number) {
    this.questions.get(questionId)?.answers.delete(answerId);
    this.saveAnswersError(questionId, answerId);
  }

  public removeQuestion(questionId: number) {
    this.questions.delete(questionId);
    this.saveQuestionError(questionId);
  }

  public addQuestion(toast: ToastType) {
    const questionsArray = Array.from(this.questions);

    const isAllQuestions = questionsArray.every(question => !!question[1].question.trim());

    if (isAllQuestions) {
      const newQuestionId = questionsArray[this.questions.size - 1][0] + 1;
      return this.questions.set(newQuestionId, {
        ...EMPTY_QUESTION,
        id: newQuestionId
      });
    }

    toast({
      title: "Uzupełnij wszystkie pytania",
      status: "error",
      isClosable: true
    });
  }

  private isMinLength(value?: string, length?: number): boolean {
    return (value?.length ?? 0) >= (length ?? MIN_INPUT_LENGTH);
  }

  saveQuestionError(questionId: number, message?: string) {
    this.questionsErrors = this.questionsErrors.filter(error => error.id !== questionId);

    if (!!message) {
      this.questionsErrors = [...this.questionsErrors, { id: questionId, message }];
    }
  }

  saveAnswersError(questionId: number, answerId: number, message?: string) {
    this.answerErrors = this.answerErrors.filter(
      error => error.questionId !== questionId || error.answerId !== answerId
    );

    if (!!message) {
      this.answerErrors = [...this.answerErrors, { questionId, answerId, message }];
    }
  }

  removeLastEmptyQuestion() {
    const lastQuestion = this.questionsArray[this.questionsArray.length - 1];

    this.questionsArray.length > 1 && !lastQuestion.question.trim() && this.questions.delete(lastQuestion.id);
  }

  removeLastEmptyAnswer(questionId: number): VotingAnswerType[] {
    const answers = this.questionsArray.find(question => question.id === questionId)?.answers;

    if (answers && answers.length > MIN_ANSWERS) {
      const lastAnswer = answers[answers.length - 1];
      if (!lastAnswer.content.trim()) {
        this.questions.get(questionId)?.answers.delete(lastAnswer.id);
        return this.removeLastEmptyAnswer(questionId);
      }
    }
    return answers ?? [];
  }

  private validateFieldsLength() {
    this.questionsArray.forEach(question => {
      this.removeLastEmptyQuestion();

      const isQuestionCorrect = this.isMinLength(question.question);

      this.saveQuestionError(
        question.id,
        isQuestionCorrect ? "" : `Pytanie musi mieć minimum ${MIN_INPUT_LENGTH} znaki`
      );

      const updatedAnswers = this.removeLastEmptyAnswer(question.id);

      updatedAnswers.forEach(answer => {
        const isAnswerCorrect = this.isMinLength(answer.content, 1);

        this.saveAnswersError(question.id, answer.id, isAnswerCorrect ? "" : `Odpowiedź musi mieć minimum 1 znak`);
      });
    });
  }

  private validateFields() {
    this.validateFieldsLength();
  }

  private transformQuestionsLinks() {
    this.questions.forEach(question => {
      const updatedQuestion = addLinksToText(question.question);

      this.questions.set(question.id, {
        ...question,
        transformedQuestion: `<p>${updatedQuestion}</p>`
      });
    });
  }

  public handleSumUp() {
    this.validateFields();
    !this.isError && this.transformQuestionsLinks();
  }

  override async onInit() {}

  clearErrors() {
    this.questionsErrors = [];
    this.answerErrors = [];
  }

  clearData() {
    this.questions = new Map([[1, { ...EMPTY_QUESTION, id: 1 }]]);
    this.clearErrors();
  }

  get questionsArray(): VotingQuestionArrayType[] {
    const questionsArray: VotingQuestionArrayType[] = [];

    this.questions.forEach(question => {
      const answersArray: VotingAnswerType[] = [];
      question.answers.forEach(answer => answersArray.push(answer));

      questionsArray.push({ ...question, answers: answersArray });
    });

    return questionsArray;
  }

  get isError(): boolean {
    return !!this.questionsErrors.length || !!this.answerErrors.length;
  }
}
