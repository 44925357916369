import { observer } from "mobx-react-lite";
import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import { CustomButton } from "@lubbezposrednio-frontend/commons";
import { LandingPageInput } from "./LandingPageInput";
import { CreateLandingPageTile } from "../CreateLandingPageTile";
import { LANDING_PAGE_LIMITS, LandingPageTextsModel } from "./LandingPageTextsModel";

export const LandingPageTexts: React.FC<{ model: LandingPageTextsModel; isActive: boolean }> = observer(
  ({ model, isActive }) => {
    return (
      <CreateLandingPageTile title="Treści widoczne na stronie">
        <VStack alignItems="left" spacing={0}>
          <LandingPageInput
            label="Tytuł"
            tooltip={`Tytuł może mieć maksymalnie ${LANDING_PAGE_LIMITS.TITLE} znaków. Ze względu na limit znaków nazwę głosowania zaleca się umieścić w kolejnym polu - "Opis".`}
            maxLength={LANDING_PAGE_LIMITS.TITLE}
            value={model.pageTitle}
            onChange={model.changePageTitle.bind(model)}
            h={{ base: "60px", sm: "38px" }}
            lineHeight={{ sm: "32px" }}
            isDisabled={isActive}
          />
          {model.pageTitleError && (
            <Text variant="error" pb="16px">
              {model.pageTitleError}
            </Text>
          )}

          <LandingPageInput
            label="Opis"
            tooltip={`Opis może mieć maksymalnie ${LANDING_PAGE_LIMITS.DESCRIPTION} znaków. Może on np. zawierać nazwę planowanego głosowania. Możliwe jest też umieszczenie linku. W tym celu dodaj w pytaniu odnośnik do pełnego linku np. https://google.com/.`}
            maxLength={LANDING_PAGE_LIMITS.DESCRIPTION}
            valueForCounter={model.pageDescriptionWithLinks}
            value={model.pageDescription}
            onChange={model.changePageDescription.bind(model)}
            h={{ base: "250px", sm: "125px" }}
            isDisabled={isActive}
          />
          {model.pageDescriptionError && (
            <Text variant="error" pb="16px">
              {model.pageDescriptionError}
            </Text>
          )}

          {model.additionalDescriptions.map((description, index) => (
            <LandingPageInput
              key={description.id}
              label={`Dodatkowy opis - paragraf ${index + 1}`}
              tooltip={`Każdy paragraf dodatkowego opisu może mieć maksymalnie ${LANDING_PAGE_LIMITS.ADDITIONAL_DESCRIPTION} znaków. Możliwe jest też umieszczenie linku. W tym celu dodaj w pytaniu odnośnik do pełnego linku np. https://google.com/.`}
              maxLength={LANDING_PAGE_LIMITS.ADDITIONAL_DESCRIPTION}
              value={description.content}
              onChange={value => model.changeAdditionalDescription(description.id, value)}
              valueForCounter={description.contentWithLinks}
              h={{ base: "350px", sm: "275px" }}
              onRemove={!isActive ? () => model.removeAdditionalDescription(description.id) : undefined}
              isDisabled={isActive}
            />
          ))}

          {!isActive && (
            <CustomButton
              fontSize="14px"
              maxW="100%"
              text={model.additionalDescriptions.length ? "Dodaj kolejny paragraf" : "Dodaj dodatkowy opis"}
              backgroundColor="primary"
              onClick={model.addAdditionalDescription.bind(model)}
            />
          )}
        </VStack>
      </CreateLandingPageTile>
    );
  }
);
