import { VStack, useToast } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { withModel, bindToCommand, VOTING_STATUSES } from "@lubbezposrednio-frontend/core";
import { VotingViewModel } from "./VotingViewModel";
import { VotingInfoTile } from "./components/VotingInfo/VotingInfoTile";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { VotingViewQuestion } from "./components/VotingViewQuestion/VotingViewQuestion";
import { VotingViewEmail } from "./components/VotingViewEmail/VotingViewEmail";
import { CustomButton, Layout, CustomLoader } from "@lubbezposrednio-frontend/commons";
import ReportPopup from "./components/ReportPopup/ReportPopup";

export const VOTE_TILE_WIDTH = 813;

interface VotingsViewProps {
  model: VotingViewModel;
}

const VotingView: React.FC<VotingsViewProps> = observer(({ model }) => {
  const { id } = useParams();

  useEffect(() => {
    document.title = "Głosowanie";
    id && model.getVoting(id, toast, navigate);
  }, []);

  useEffect(() => {
    switch (model.status) {
      case VOTING_STATUSES.FINISHED: {
        navigate(`/glosowanie/${id}/zakonczone`, { state: { endDate: model.endDate, name: model.name } });
        break;
      }
      case VOTING_STATUSES.PLANNED: {
        navigate(`/glosowanie/${id}/planowane`, { state: { startDate: model.startDate, name: model.name } });
        break;
      }
    }
  }, [model.status]);

  const toast = useToast();
  const navigate = useNavigate();
  const [isReportPopupOpen, setReportPopupOpen] = useState(false);


  const sendCommand = useMemo(() => model.handleSend(toast, navigate), [toast, navigate]);

  return (
    <Layout>
      {model.isLoading ? (
        <CustomLoader />
      ) : (

        <VStack flex={1} pt={{ base: "98px", sm: "135px" }} pb={{ base: "86px", sm: "156px" }} gap="33px">
          {!model.isEmailNecessary && <VotingInfoTile model={model} />}

          {!model.isEmailNecessary &&
            model.questions?.map(question => (
              <VotingViewQuestion question={question} key={question.id} model={model} />
            ))}

          <VotingViewEmail model={model} />

          <CustomButton text="Wyślij" mt={{ base: "7px", sm: "24px" }} {...bindToCommand(sendCommand)} />
          
          <button onClick={() => setReportPopupOpen(true)}><b>Zgłoś głosowanie</b></button>
          {isReportPopupOpen && <ReportPopup Id={id as string} onClose={() => setReportPopupOpen(false)} reportableType="voting" model={model} />}
        </VStack>
      )}
    </Layout>
  );
});

const VotingViewWithModel = withModel<VotingViewModel>(VotingView, VotingViewModel);

export default VotingViewWithModel;
