import axios, { AxiosError } from "axios";
import { LOCAL_STOREGE_KEYS } from "../utils/constants";
import { setTokenInLocalStorage } from "../utils/helpers";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

export const axiosClient = axios.create({
  baseURL: baseUrl,
  headers: { "content-type": "application/json" }
});

axiosClient.interceptors.request.use(config => {
  config.headers.Authorization =
    config.headers["Authorization"] ?? "Bearer " + localStorage.getItem(LOCAL_STOREGE_KEYS.ACCESS_TOKEN);
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    // If token was refreshed set new token in local storage
    if (response.data.session) {
      const {
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_expires_in: refreshExpiresIn
      } = response.data.session;
      setTokenInLocalStorage(accessToken, expiresIn, refreshExpiresIn);
      setAuthHeaders();
    }
    return response;
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.request.responseURL !== baseUrl + "/login" && error.request.status === 401) {
      window.location.href = "/";
    }
    if (error.request.status === 404) {
      window.location.href = "/nie-znaleziono-strony";
    }
    return Promise.reject(error);
  }
);

export const setAuthHeaders = () => {
  axiosClient.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem(LOCAL_STOREGE_KEYS.ACCESS_TOKEN);
};
