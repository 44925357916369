import { useEffect, useMemo, useState, useCallback } from "react";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { ActiveVotingsViewModel } from "../ActiveVotingsViewModel";

enum COUNTDOWN_KEYS {
  DAY,
  HOUR,
  MINUTE,
  SECOND
}

const COUNTDOWN_DIVIDERS: { key: COUNTDOWN_KEYS; divider: number; label: string }[] = [
  { key: COUNTDOWN_KEYS.DAY, divider: 1000 * 60 * 60 * 24, label: "dni" },
  { key: COUNTDOWN_KEYS.HOUR, divider: 1000 * 60 * 60, label: "h" },
  { key: COUNTDOWN_KEYS.MINUTE, divider: 1000 * 60, label: "min" },
  { key: COUNTDOWN_KEYS.SECOND, divider: 1000, label: "sek" }
];

type VotingCountdownProps = {
  start?: string; // DD-MM-YYYY HH:MM
  id: string;
  model: ActiveVotingsViewModel;
};

export const VotingCountdown: React.FC<VotingCountdownProps> = ({ start, model, id }) => {
  const startDateTime = useMemo(() => {
    const [date, time] = start?.split(" ") ?? ["", ""];
    const formattedDate = date.split("-").reverse().join("-") + ` ${time}`;
    return formattedDate.trim() ? new Date(formattedDate).getTime() : 0;
  }, [start]);

  const [timeLeft, setTimeLeft] = useState(startDateTime - Date.now());
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const id = setInterval(getTimeLeft, 1000);
    setIntervalId(id);

    return () => clearInterval(intervalId);
  }, []);

  const getTimeLeft = useCallback(() => {
    const timeDiff = startDateTime - Date.now();

    if (timeDiff < 0) {
      return model.markVotingAsOngoing(id);
    }
    setTimeLeft(timeDiff);
  }, [startDateTime]);

  const countdownItems = useMemo(() => {
    const items: { key: COUNTDOWN_KEYS; value: number; label: string }[] = [];

    COUNTDOWN_DIVIDERS.reduce((timeLeft, { key, divider, label }) => {
      const timePassed = timeLeft % divider;
      const value = Math.max((timeLeft - timePassed) / divider, 0);
      items.push({ key, value, label });

      return timePassed;
    }, timeLeft);

    return items;
  }, [timeLeft]);

  return (
    <Flex
      pb={{ base: "29px", sm: "15px" }}
      wrap={"wrap"}
      justify={{ base: "center", sm: "flex-start" }}
      align="center"
      columnGap="36px"
      rowGap="24px"
    >
      <Text ml={{ sm: "6px" }} color="primary" textStyle="text" fontWeight="bold" h="fit-content">
        Głosowanie rozpocznie się za:
      </Text>
      <Flex gap="7px" wrap={"wrap"} justify={{ base: "center", sm: "flex-start" }}>
        {countdownItems.map(({ key, value, label }) => (
          <VotingCountdownItem key={key} value={value} label={label} />
        ))}
      </Flex>
    </Flex>
  );
};

const VotingCountdownItem: React.FC<{ value: number; label: string }> = ({ value, label }) => {
  return (
    <VStack spacing="4px" maxW="100%">
      <Box py="9px" px="13px" borderColor="primary" borderRadius="standard" borderWidth="1px" minW="44px" maxW="100%">
        <Text textAlign="center" textStyle="largeText" fontWeight="bold" color="accent">
          {value}
        </Text>
      </Box>
      <Text textStyle="text" color="accent">
        {label}
      </Text>
    </VStack>
  );
};
