import { action, computed, makeObservable, observable } from "mobx";
import {
  PUBLICITY_TYPES,
  DATE_SORTERS,
  VOTING_STATUSES,
  VotingRequestFeedback,
  VotingsListQuestion,
  VOTINGS_LIST_PAGE_SIZE,
  getOrganizerVotings,
  getVotingDetails,
  sendVotingProtocole,
  ApiVotingDetails,
  ToastType,
  Command,
  VotingDraftDetails,
  ApiVotingDraftDetails,
  ApiVotingQuestion,
  deleteVotingDraft
} from "@lubbezposrednio-frontend/core";
import { VotingsListModel } from "../../models/VotingsListModel";
import { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { createVotingModel } from "../CreateVotingView/CreateVotingViewModel";

const INITIAL_OPEN_ITEM_POS = { height: 0, offset: 0, isOutside: false };

export class CreatedVotingsListViewModel extends VotingsListModel<{
  publicityFilter?: PUBLICITY_TYPES;
  sorter?: DATE_SORTERS;
}> {
  votingsDetailsList: Map<string, VotingsListQuestion[]> = new Map();
  draftsDetailsList: Map<string, VotingDraftDetails> = new Map();
  publicityFilter?: PUBLICITY_TYPES;
  sorter?: DATE_SORTERS;

  expandedVotingId?: string;
  openItemPositionData = INITIAL_OPEN_ITEM_POS;

  constructor() {
    super();

    makeObservable(this, {
      publicityFilter: observable,
      setPublicityFilter: action.bound,
      sorter: observable,
      setSorter: action.bound,
      expandedVotingId: observable,
      setExpandedVotingId: action.bound,
      votingsDetailsList: observable,
      draftsDetailsList: observable,
      updateVotingsDetailsList: action.bound,
      updateDraftsDetailsList: action.bound,
      openItemPositionData: observable,
      setOpenItemPositionData: action.bound,
      openVotingDetails: computed,
      cacheFilters: action.bound,
      restoreCachedFilters: action.bound
    });
  }

  private getFormmatedQuestions(questions?: ApiVotingQuestion[]): VotingsListQuestion[] {
    return (
      questions?.map(question => ({
        id: question.id,
        question: question.content,
        options: question.answers.map(answer => answer.content)
      })) ?? []
    );
  }

  updateVotingsDetailsList(votingsDetailsList: Partial<ApiVotingDetails>, id: string) {
    const questions = this.getFormmatedQuestions(votingsDetailsList.voting?.questions);
    this.votingsDetailsList.set(id, questions);
  }

  updateDraftsDetailsList(draftsDetailsList: ApiVotingDraftDetails) {
    const { voting } = draftsDetailsList;
    const draftDetails: VotingDraftDetails = {
      id: voting.id,
      name: voting.title,
      startDate: voting.start_date,
      endDate: voting.end_date,
      questions: this.getFormmatedQuestions(voting.questions),
      privacyPolicyUrl: voting.privacy_policy_url
        ? process.env.REACT_APP_BACKEND_URL + voting.privacy_policy_url
        : undefined,
      termsAndConditionsUrl: voting.terms_and_conditions_url
        ? process.env.REACT_APP_BACKEND_URL + voting.terms_and_conditions_url
        : undefined,
      emails: voting.emails ?? undefined,
      votersListUrl: voting.voter_list_url ? process.env.REACT_APP_BACKEND_URL + voting.voter_list_url : undefined,
      isPublic: voting.public
    };
    this.draftsDetailsList.set(voting.id, draftDetails);
  }

  setPublicityFilter(publicityFilter?: PUBLICITY_TYPES, apply?: boolean) {
    apply && this.cacheFilters();
    this.publicityFilter = publicityFilter;
    apply && this.applyFilter();
  }

  setSorter(sorter?: DATE_SORTERS, apply?: boolean) {
    apply && this.cacheFilters();
    this.sorter = sorter;
    apply && this.applyFilter();
  }

  changeStatusFilter(status?: VOTING_STATUSES) {
    this.cacheFilters();
    this._changeStatusFilter(status);
    this.setPublicityFilter();
    this.setSorter();
    this.applyFilter();
  }

  setExpandedVotingId(expandedVotingId?: string) {
    this.expandedVotingId = expandedVotingId;
  }

  setOpenItemPositionData(openItemPositionData?: { height: number; offset: number; isOutside: boolean }) {
    this.openItemPositionData = openItemPositionData ?? INITIAL_OPEN_ITEM_POS;
  }

  async getVotings(page: number) {
    try {
      return getOrganizerVotings({
        per_page: VOTINGS_LIST_PAGE_SIZE,
        page_number: page,
        status: this.statusFilter,
        public: this.publicityFilter && this.publicityFilter === PUBLICITY_TYPES.PUBLIC,
        sort: this.sorter
      });
    } catch (err) {
      console.error(err);
    }
  }

  private async fetchVotingDetails(id: string, isDraft: boolean): Promise<VotingRequestFeedback> {
    try {
      const response = await getVotingDetails(id);
      if (response.status === 200) {
        this.updateVotingsDetailsList(response.data, id);
        isDraft && this.updateDraftsDetailsList(response.data);
        this.setExpandedVotingId(id == this.expandedVotingId ? undefined : id);

        return { isSuccess: true };
      }

      return {
        isSuccess: false,
        message: "Wystąpił błąd podczas próby pobrania szczegółów głosowania"
      };
    } catch (err) {
      return {
        isSuccess: false,
        message: `Wystąpił błąd podczas próby pobrania szczegółów głosowania: ${(err as Error).message}`
      };
    }
  }

  private getSendProtocoleErrorMessage(status?: number): string {
    return status === 425
      ? "Protokół nie został jeszcze wygenerowany. Spróbuj ponownie za kilka minut"
      : "Wystąpił błąd podczas próby przesłania protokołu.";
  }

  private async sendVotingProtocole(id: string): Promise<VotingRequestFeedback> {
    try {
      const response = await sendVotingProtocole(id);

      return {
        isSuccess: response?.status === 202,
        message:
          response?.status === 202
            ? "Wiadomość e-mail z protokołem zostanie przesłana."
            : this.getSendProtocoleErrorMessage(response.status)
      };
    } catch (err) {
      console.error(err);
      return { isSuccess: false, message: this.getSendProtocoleErrorMessage((err as AxiosError)?.response?.status) };
    }
  }

  public onTileButtonClick(id: string, status?: VOTING_STATUSES) {
    if (status === VOTING_STATUSES.FINISHED) {
      return this.sendVotingProtocole(id);
    }
    if (!this.votingsDetailsList.get(id)) {
      return this.fetchVotingDetails(id, status === VOTING_STATUSES.DRAFT);
    }
    this.setExpandedVotingId(id == this.expandedVotingId ? undefined : id);
  }

  public async handleCreateVotingFromDraft(navigate: NavigateFunction, id: string, toast: ToastType) {
    if (!this.draftsDetailsList.get(id)) {
      try {
        const response = await getVotingDetails(id);
        response.status === 200 && this.updateDraftsDetailsList(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    const foundDraft = this.draftsDetailsList.get(id);
    if (foundDraft) {
      createVotingModel.loadDataFromDraft(foundDraft);
      navigate("/organizator/utworz-glosowanie");
    } else {
      this.showGenericErrorToast(toast, "Nie udało się pobrać szczegółów wersji roboczej. Spróbuj ponownie później");
    }
  }

  public handleDraftDelete(id: string, toast: ToastType, closeModal: () => void) {
    return new Command(async () => {
      try {
        const response = await deleteVotingDraft(id);

        if (response?.status === 204) {
          toast({
            status: "success",
            isClosable: true,
            title: "Wersja robocza została pomyślnie usunięta"
          });
          this.fetchList(this.currPage);
          closeModal();
        } else {
          this.showGenericErrorToast(toast);
          closeModal();
        }
      } catch (err) {
        this.showGenericErrorToast(toast);
        console.log(err);
        closeModal();
      }
    });
  }

  override cacheFilters(): void {
    if (!this.fetchTimeout) {
      this.cacheCommonFilters();
      this.cachedFilters.publicityFilter = this.cachedFilters.publicityFilter ?? this.publicityFilter;
      this.cachedFilters.sorter = this.cachedFilters.sorter ?? this.sorter;
    }
  }

  override restoreCachedFilters() {
    this.restoreCommonFilters();
    this.publicityFilter = this.cachedFilters.publicityFilter ?? this.publicityFilter;
    this.sorter = this.cachedFilters.sorter ?? this.sorter;
  }

  override async onInit() {}

  get openVotingDetails(): VotingsListQuestion[] {
    return (this.expandedVotingId && this.votingsDetailsList.get(this.expandedVotingId)) || [];
  }
}
