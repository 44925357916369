import { NumberInput, NumberInputField } from "@chakra-ui/react";
import { CustomDatePickerProps } from "./CustomDatePicker";
import { DATE_KEYS, DateItemType } from "@lubbezposrednio-frontend/core";

type DateInputProps = Omit<CustomDatePickerProps, "label"> & {
  dateItem: DateItemType;
};

export const DateInput: React.FC<DateInputProps> = ({ dateItem, value, onChange, onBlur, ...props }) => {
  const isYear = dateItem.key === DATE_KEYS.YEAR;

  return (
    <NumberInput
      variant="custom"
      borderColor="primary"
      opacity={props.isDisabled ? "0.5" : "1"}
      borderWidth="2px"
      borderRadius="standard"
      w={dateItem.width ?? "50px"}
      display="flex"
      alignItems="center"
      onChange={val => onChange(dateItem.key, val)}
      value={value[dateItem.key]}
      onBlur={onBlur}
      ml={{ base: isYear ? "5px" : 0, sm: 0 }}
      {...props}
    >
      <NumberInputField
        bg="transparent"
        placeholder={dateItem.placeholder}
        p={0}
        textAlign="center"
        maxLength={isYear ? 4 : 2}
        h="34px"
        _placeholder={{ color: "primary" }}
      />
    </NumberInput>
  );
};
