import { Text } from "@chakra-ui/react";
import { CreateLandingPageTile } from "../CreateLandingPageTile";
import { observer } from "mobx-react-lite";
import { CustomDatePicker } from "@lubbezposrednio-frontend/commons";
import { LandingPageDatesModel } from "./LandingPageDatesModel";
import { DATE_KEYS } from "@lubbezposrednio-frontend/core";

export const LandingPageDates: React.FC<{ model: LandingPageDatesModel; isActive: boolean }> = observer(
  ({ model, isActive }) => {
    return (
      <CreateLandingPageTile title="Daty publikacji" pb={{ sm: "16px" }}>
        <CustomDatePicker
          label="od"
          onChange={(key: DATE_KEYS, value: string) => model.changeStartOfPublicationDate(key, value, isActive)}
          value={model.startOfPublicationDate}
          onBlur={() => model.checkIsDateCorrect(true, isActive)}
          isDisabled={isActive}
        />
        {model.startOfPublicationDateError && <Text variant="error">{model.startOfPublicationDateError}</Text>}

        <CustomDatePicker
          label="do"
          onChange={(key: DATE_KEYS, value: string) => model.changeEndOfPublicationDate(key, value, isActive)}
          value={model.endOfPublicationDate}
          onBlur={() => model.checkIsDateCorrect(false, isActive)}
        />
        {model.endOfPublicationDateError && <Text variant="error">{model.endOfPublicationDateError}</Text>}
      </CreateLandingPageTile>
    );
  }
);
