import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Text } from "@chakra-ui/react";
import { LandingPageViewModel } from "../LandingPageViewModel";
import { convertHtmlToText } from "@lubbezposrednio-frontend/core";

export const LandingPageDescriptions: React.FC<{ model: LandingPageViewModel }> = observer(({ model }) => {
  return (
    <Box w="100%" columnGap={{ sm: "16px" }} sx={{ columnCount: { base: 1, sm: 2 } }} px={{ base: "37px", md: 0 }}>
      {model.textContents.additionalDescriptions?.map((description, index, arr) => (
        <Text key={description.id} color="primary" textStyle="largeText" pb={index !== arr.length - 1 ? "25px" : 0}>
          {convertHtmlToText(description.content)}
        </Text>
      ))}
    </Box>
  );
});
