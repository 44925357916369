import { VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { CustomCheckbox } from "@lubbezposrednio-frontend/commons";
import { AuthorizationModel, bindProperty } from "@lubbezposrednio-frontend/core";

interface CheckboxesSectionProps {
  authorization: AuthorizationModel;
}

export const CheckboxesSection: React.FC<CheckboxesSectionProps> = observer(({ authorization }) => {
  return (
    <VStack
      align="flex-start"
      alignSelf="flex-start"
      ml="12px"
      mt={{ base: "12px", md: "24px" }}
      mb={{ base: 0, md: "12px" }}
      flexGrow={1}
    >
      <CustomCheckbox
        required
        label="Zapoznałem się z Regulaminem i Polityką Prywatności"
        {...bindProperty(authorization, "applicationServiceTerms")}
      />
      <CustomCheckbox
        label="Wyrażam zgodę na otrzymywanie materiałów informacyjnych i marketingowych w związku z działalnością platformy decyduj.lubbezposrednio.pl i realizacją zadania Instytut Demokracji Bezpośredniej"
        {...bindProperty(authorization, "applicationMarketingTerms")}
      />
    </VStack>
  );
});
