import { DATE_SORTERS } from "./general";

export enum LP_STATUSES {
  PLANNED = "planned",
  ONGOING = "ongoing",
  FINISHED = "finished",
  DRAFT = "draft"
}

export type LandingPageConsentType = { content: string; id: number; isChecked: boolean; isRequired?: true };

export type LandingPageUser = {
  id: string;
  email: string;
  first_name?: string | null;
  last_name?: string | null;
  organization_name?: string | null;
  is_organization: boolean;
  accepted_organizations_service_terms: boolean;
  accepted_organizations_marketing_terms: boolean;
  accepted_applications_service_terms: boolean;
  accepted_applications_marketing_terms: boolean;
  avatar_url?: string | null;
  verified: boolean;
  representatives_verified: boolean | null;
};

export type LandingPageDataType = {
  landing_page: {
    id: string;
    page_title: string;
    page_description: string;
    additional_descriptions?: string[];
    start_date: string;
    end_date: string;
    status: LP_STATUSES;
    privacy_policy_url: string;
    terms_and_conditions_url: string;
    user: LandingPageUser;
  };
};

export type LandingPageTextContents = {
  pageTitle?: string;
  pageDescription?: string;
  additionalDescriptions?: { id: string; content: string }[];
};

export type CreateLandingPageParams = {
  page_title?: string;
  page_description?: string;
  additional_descriptions?: string[];
  terms_and_conditions?: Blob;
  privacy_policy?: Blob;
  end_date: string;
  start_date: string;
  status: LP_STATUSES;
  published?: string;
  privacy_policy_url?: string;
  terms_and_conditions_url?: string;
};

export type LandingPagesListItem = {
  id: string;
  pageTitle: string;
  isActive: boolean;
  startDate?: string;
  endDate?: string;
  leadUrl?: string;
  status: LP_STATUSES;
};

type ApiLandingPageListItem = {
  id: string;
  page_title: string;
  page_description: string;
  additional_descriptions: string[] | null;
  start_date: string;
  end_date: string;
  status: LP_STATUSES;
  user: {
    id: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
    organization_name: string | null;
    is_organization: boolean;
    accepted_organizations_service_terms: boolean;
    accepted_organizations_marketing_terms: boolean;
    accepted_applications_service_terms: boolean;
    accepted_applications_marketing_terms: boolean;
    avatar_url: string | null;
    verified: boolean;
    representatives_verified: boolean | null;
  };
  spreadsheet_url: string | null;
};

export type ApiLandingPageListType = {
  landing_pages: (ApiLandingPageListItem | ApiLandingPageDraftListItem)[];
  meta: {
    total_pages: number;
  };
};

export type GetLandingPagesParams = {
  per_page: number;
  page_number?: number;
  sort?: DATE_SORTERS;
  status?: LP_STATUSES[];
  page_title?: string;
};

export type AddEmailParams = {
  accepted_organizations_service_terms?: boolean;
  accepted_organizations_marketing_terms?: boolean;
  accepted_applications_service_terms: boolean;
  accepted_applications_marketing_terms: boolean;
  email: string;
};

type ApiLandingPageDraftListItem = Partial<Omit<ApiLandingPageListItem, "user" | "id" | "status">> &
  Pick<ApiLandingPageListItem, "id" | "user" | "status">;

export type LandingPageDraftDataType = {
  landing_page: Partial<Omit<LandingPageDataType["landing_page"], "user" | "id">> &
    Pick<LandingPageDataType["landing_page"], "id" | "user">;
};
