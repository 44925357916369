import { Flex, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { ProfileViewModel } from "../../ProfileViewModel";
import { PersonalDataSection } from "../PersonalDataSection/PersonalDataSection";
import { PhotoUploadSection } from "../PhotoUploadSection/PhotoUploadSection";
import { PostalCodeSection } from "../PostalCodeSection/PostalCodeSection";
import { ProfileButtonsSection } from "../ProfileButtonsSection/ProfileButtonsSection";
import { StatementUploadSection } from "../StatementUploadSection/StatementUploadSection";
import { VerifiedCheck } from "@lubbezposrednio-frontend/commons";

interface UserProfileProps {
  model: ProfileViewModel;
  isOrganization?: boolean;
}

export const UserProfile: React.FC<UserProfileProps> = observer(({ model, isOrganization }) => {
  return (
    <>
      <VStack w={{ base: "100%", md: "800px" }} gap="30px" mb="50px">
        {model.isUserVerified && <VerifiedCheck text="Użytkownik zweryfikowany" my="-20px" />}
        <Flex direction={{ base: "column", md: "row" }} gap="20px" w="100%">
          <PhotoUploadSection
            profilePhoto={model.userProfilePhoto}
            setProfilePhoto={model.setUserProfilePhoto}
            previewSrc={model.userPhotoPreview}
            title="Zdjęcie profilowe"
          />
          <StatementUploadSection
            setPdf={model.setProfileStatement}
            downloadTemplate={model.downloadAffirmationTemplate}
            statementUrl={model.statementUrl ?? ""}
          />
        </Flex>
        <PersonalDataSection model={model} isOrganization={isOrganization} />
        <PostalCodeSection model={model} />
        <ProfileButtonsSection model={model} />
      </VStack>
    </>
  );
});
