import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { CreateVotingViewModel } from "../../CreateVotingView/CreateVotingViewModel";
import { observer } from "mobx-react-lite";

export const VotersListSummary: React.FC<{ model: CreateVotingViewModel }> = observer(({ model: parentModel }) => {
  const { eligibleVotersModel: model } = parentModel;

  return (
    <Box my="auto" textAlign="center">
      <Text variant="votingSummaryLabel" display="inline-block">
        Lista uprawnionych do głosowania:&nbsp;
      </Text>
      {model.eligibleVotersListType === "csv" && (
        <Text variant="votersSummary">{model.votersCsv?.name ?? model.votersCsvUrlName}</Text>
      )}
      {model.eligibleVotersListType === "manual" &&
        model.votersEmails.map((voter, index, arr) => (
          <Text key={voter.id} variant="votersSummary">
            {voter.value}
            {index !== arr.length - 1 && <>,&nbsp;</>}
          </Text>
        ))}
    </Box>
  );
});
