import React from "react";
import { CustomTile } from "@lubbezposrednio-frontend/commons";
import { BoxProps } from "@chakra-ui/react";

type CreateLandingPageTileProps = BoxProps & {
  title: string;
  children: React.ReactNode;
  absoluteChildren?: React.ReactNode;
  containerProps?: BoxProps;
};

export const CreateLandingPageTile: React.FC<CreateLandingPageTileProps> = ({ children, ...props }) => {
  return (
    <CustomTile
      tileWidth={813}
      pr="31px"
      pt="16px"
      pb="40px"
      titleStyle={{ variant: "createVotingTileTitle" }}
      maxWidth="80vw"
      {...props}
    >
      {children}
    </CustomTile>
  );
};
