import React from "react";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { CreateVotingConfirmation } from "./components/CreateVotingConfirmation";
import { Summary } from "./components/Summary";
import { createVotingModel } from "../CreateVotingView/CreateVotingViewModel";
import { observer } from "mobx-react-lite";

export const CreateVotingSummaryView: React.FC = observer(() => {
  return <Layout isMenu>{createVotingModel.confirmData.isVisible ? <CreateVotingConfirmation /> : <Summary />}</Layout>;
});
