export const inputStyles = {
  variants: {
    profile: {
      field: {
        textColor: "accent",
        fontWeight: "bold"
      }
    }
  }
};
