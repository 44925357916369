import React, { useEffect } from "react";
import { withModel } from "@lubbezposrednio-frontend/core";
import { ProfileViewModel } from "./ProfileViewModel";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { observer } from "mobx-react-lite";
import { UserProfile } from "./components/UserProfile/UserProfile";
import { OrganizationProfile } from "./components/OrganizationProfile/OrganizationProfile";
import { Text, useToast } from "@chakra-ui/react";

interface ProfileViewProps {
  model: ProfileViewModel;
}

export const ProfileView: React.FC<ProfileViewProps> = observer(({ model }) => {
  useEffect(() => {
    document.title = "Konto";
    model.getProfileData(toast);
  }, []);

  const toast = useToast();

  return (
    <Layout isMenu>
      {model.isOrganization ? (
        <>
          <OrganizationProfile model={model} />
          <Text textStyle="header" color="primary" mb="40px" textAlign="center">
            Profil i dane reprezentanta
          </Text>
          <UserProfile model={model} isOrganization />
        </>
      ) : (
        <>
          <Text textStyle="header" color="primary" mb="40px" mt={{ base: "30px", md: "50px" }}>
            Konto
          </Text>
          <UserProfile model={model} />
        </>
      )}
    </Layout>
  );
});

const ProfileViewWithModel = withModel<ProfileViewModel>(ProfileView, ProfileViewModel);

export default ProfileViewWithModel;
