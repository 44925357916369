import { useRef, ChangeEvent } from "react";
import { Property } from "csstype";
import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  ResponsiveValue,
  Text
} from "@chakra-ui/react";
import { handleKeyboardNavigation } from "@lubbezposrednio-frontend/core";
type CustomFileUploaderProps = Omit<InputProps, "onChange" | "accept"> & {
  onCancel?: () => void;
  onChange: (value?: File) => void;
  fileName?: string;
  accept?: string[];
  mobileWidth?: string;
  wrapperDisplay?: ResponsiveValue<Property.Display>;
  customRef?: React.RefObject<HTMLInputElement>;
};

export const CustomFileUploader: React.FC<CustomFileUploaderProps> = ({
  onCancel,
  fileName,
  onChange,
  accept,
  mobileWidth,
  wrapperDisplay,
  customRef,
  ...props
}) => {
  const newRef = useRef<HTMLInputElement>(null);
  const fileInputRef = customRef ?? newRef;

  const onInputClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box flex={1} maxW={{ base: "auto", sm: "50%" }} w={{ base: mobileWidth, sm: "auto" }} display={wrapperDisplay}>
      <InputGroup
        borderRadius="standard"
        borderColor="primary"
        borderWidth="2px"
        bg="background"
        alignItems="center"
        width={props.width ?? props.w}
        flex={1}
        justifyContent="space-between"
        opacity={props.isDisabled ? "0.5" : "1"}
      >
        <Flex
          flex={1}
          px="21px"
          h="100%"
          alignItems="center"
          cursor={props.isDisabled ? "not-allowed" : "pointer"}
          {...handleKeyboardNavigation(onInputClick)}
          maxW="85%"
          tabIndex={0}
        >
          <Text textStyle="text" color="primary" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {fileName || props.placeholder}
          </Text>
        </Flex>
        <Input
          type="file"
          ref={fileInputRef}
          display={"none"}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.files?.[0])}
          accept={accept?.map(format => `.${format}`).join(",")}
          {...props}
        />
        <InputRightAddon bg="transparent" border="none">
          <Image
            src="/Icon metro-cross.svg"
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.files = null;
                fileInputRef.current.value = "";
              }
              onCancel?.();
            }}
            cursor="pointer"
          />
        </InputRightAddon>
      </InputGroup>
      {accept && (
        <Text textStyle="text" color="primary" pl="22px" pt="12px">
          Format {accept.map(format => `*${format}`).join(", ")}
        </Text>
      )}
    </Box>
  );
};
