import { Text, VStack, Flex, Box, useToast, ChakraProps } from "@chakra-ui/react";
import { CustomButton, CustomCheckbox, CustomInput, CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { Command, NEW_TAB_WARNING, bindToCommand, convertHtmlToText } from "@lubbezposrednio-frontend/core";
import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { LandingPageViewModel } from "../LandingPageViewModel";

export const SignUpForVotingForm: React.FC<{ model: LandingPageViewModel }> = observer(({ model }) => {
  const toast = useToast();

  const addEmailCommand = useMemo(() => model.addEmail(toast), [toast, model.addEmail]);

  return (
    <VStack
      w={{ base: "100%", sm: "675px" }}
      bg="primary"
      borderRadius={{ sm: "22px" }}
      py="45px"
      px={{ base: "36px", sm: "40px" }}
      gap={0}
      maxW={{ base: "100vw", md: "50%" }}
      alignSelf={{ sm: "center", md: "auto" }}
    >
      <Text
        textAlign="center"
        fontSize={{ base: "40px", sm: "29px" }}
        lineHeight={{ base: "49px", sm: "35px" }}
        color="background"
        fontWeight="bold"
        pb={{ base: "16px", sm: "44px" }}
      >
        Zostaw e-mail, by zapisać się na listę!
      </Text>
      <Text textAlign="center" pb={{ base: "30px", sm: "61px" }} color="background" textStyle="largeText">
        Zanim zostawisz e-mail przeczytaj opis, sprawdź informacje o organizatorze i zapoznaj się z regulaminami i
        polityką prywatności poniżej. W informacjach od organizatora głosowań znaleźć powinieneś informacje o celu
        zbiórki adresów e-mail i planowanych głosowaniach, w których mają zostać wykorzystane. Po dodaniu adresu e-mail
        sprawdź skrzynkę pocztową i potwierdź zapis.
      </Text>
      <Box px={{ sm: "8px" }} w="100%" pb={{ base: "25px", sm: "58px" }}>
        <Flex gap="11px">
          <CustomInput
            value={model.email}
            onChange={model.updateEmail.bind(model)}
            h="46px"
            flex={1}
            px="25px"
            textAlign={{ base: "center", sm: "left" }}
            fontSize="16px"
            color="accent"
            fontWeight="bold"
            onKeyDown={(e: React.KeyboardEvent) =>
              (e.key === "Enter" || e.key === "Return") && addEmailCommand.execute()
            }
          />
          <AddEmailButton addEmailCommand={addEmailCommand} display={{ base: "none", sm: "inline-flex" }} />
        </Flex>
        {!!model.emailError && <Text variant="error">{model.emailError}</Text>}
      </Box>
      <CustomCheckbox
        label="Zaznacz wszystko"
        value={model.isAllConsents}
        onChange={model.checkAllConsents.bind(model)}
        labelProps={{ color: "background", fontSize: "12px", lineHeight: "22px" }}
        variant="landingPageForm"
      />
      {model.consents.map(consent => (
        <CustomCheckbox
          pl="20px"
          key={consent.id}
          required={consent.isRequired}
          label={
            <CustomTooltip message={consent.content.includes("<a") ? NEW_TAB_WARNING : ""}>
              <Text display="inline">{convertHtmlToText(consent.content)}</Text>
            </CustomTooltip>
          }
          value={consent.isChecked}
          onChange={() => model.toggleConsent(consent.id)}
          labelProps={{ color: "background", fontSize: "12px", fontWeight: "normal", lineHeight: "22px" }}
          variant="landingPageForm"
        />
      ))}
      {model.consentsError && <Text variant="error">{convertHtmlToText(model.consentsError)}</Text>}
      <AddEmailButton addEmailCommand={addEmailCommand} display={{ base: "inline-flex", sm: "none" }} mt="23px" />
    </VStack>
  );
});

type AddEmailButtonProps = Pick<ChakraProps, "mt" | "display"> & {
  addEmailCommand: Command;
};

const AddEmailButton: React.FC<AddEmailButtonProps> = observer(({ addEmailCommand, ...props }) => {
  return <CustomButton text="Dodaj mail" fontSize="16px" {...bindToCommand(addEmailCommand)} w="186px" {...props} />;
});
