import React, { useEffect } from "react";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { Text, VStack } from "@chakra-ui/layout";

export const FAQView: React.FC = () => {
  useEffect(() => {
    document.title = "Najczęściej zadawane pytania";
  }, []);

  return (
    <Layout>
      <VStack flex={1}>
        <Text textStyle="header" color="primary">
          FAQ
        </Text>
      </VStack>
    </Layout>
  );
};
