import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { withModel } from "@lubbezposrednio-frontend/core";
import { CreatedLandingPagesListViewModel } from "./CreatedLandingPagesListViewModel";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { LandingPagesList } from "./components/LandingPagesList";
import { LandingPagesListFilters } from "./components/LandingPagesListFilters";

interface CreatedLandingPagesListViewProps {
  model: CreatedLandingPagesListViewModel;
}

const CreatedLandingPagesListView: React.FC<CreatedLandingPagesListViewProps> = ({ model }) => {
  useEffect(() => {
    document.title = "Lista zapisów na głosowanie";
    model.fetchList(model.currPage);
  }, []);

  return (
    <Layout isMenu>
      <Text textStyle="header" color="primary" pt={{ sm: "34px", base: "41px" }} textAlign="center">
        Lista zapisów na głosowanie
      </Text>
      <LandingPagesListFilters model={model} />
      <LandingPagesList model={model} />
    </Layout>
  );
};

const CreatedLandingPagesListViewWithModel = withModel<CreatedLandingPagesListViewModel>(
  CreatedLandingPagesListView,
  CreatedLandingPagesListViewModel
);

export default CreatedLandingPagesListViewWithModel;
