import { InfoIcon } from "@chakra-ui/icons";
import { Tooltip, TooltipProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface CustomTooltipProps extends TooltipProps {
  message: string;
  children: ReactNode;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ message, children, ...props }) => {
  return (
    <Tooltip
      py="6px"
      px="12px"
      bg="primary"
      borderRadius="standard"
      label={
        message && (
          <>
            <InfoIcon w="12px" mb="2px" mr="4px" />
            {message}
          </>
        )
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
