import React, { useEffect, useState } from "react";
import { LOCAL_STOREGE_KEYS, clearLocalStorageToken, refreshAuthToken } from "@lubbezposrednio-frontend/core";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text
} from "@chakra-ui/react";

interface TokenExpiryDateValidatorProps {}

export const TokenExpiryDateValidator: React.FC<TokenExpiryDateValidatorProps> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const onConfirm = () => {
    onClose();
    refreshAuthToken();
  };

  useEffect(() => {
    const interval = window.setInterval(() => {
      const refreshTokenExpiryDate = Number(localStorage.getItem(LOCAL_STOREGE_KEYS.REFRESH_TOKEN_EXPITY_DATE));

      if (window.location.pathname.includes("/organizator/") && Date.now() > refreshTokenExpiryDate) {
        clearLocalStorageToken();
        window.location.href = "/sesja-wygasla";
        return;
      }

      if (window.location.pathname.includes("/organizator/") && Date.now() + 300000 > refreshTokenExpiryDate) {
        setIsOpen(true);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="background">
        <ModalHeader>
          <Text color="primary">Twoja sesja wkrótce wygaśnie</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="primary">Do wygaśnięcia sesji pozostało mniej niż 5 minut</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onConfirm}>
            <Text color="primary">Przedłuż sesję</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
