import { Text, VStack } from "@chakra-ui/layout";
import React from "react";
import { observer } from "mobx-react-lite";
import { LandingPageViewModel } from "../LandingPageViewModel";
import { convertHtmlToText } from "@lubbezposrednio-frontend/core";

export const VotingDescription: React.FC<{ model: LandingPageViewModel }> = observer(({ model }) => {
  return (
    <VStack pt={{ base: "24.5px", sm: "36.5px" }} pb="29px" gap="8.5px" pr={{ md: "154px" }} align="start">
      <Text
        fontSize={{ base: "38px", sm: "46px" }}
        lineHeight={{ base: "47px", sm: "55px" }}
        fontWeight="bold"
        color="primary"
        textAlign="start"
      >
        {convertHtmlToText(model.textContents.pageTitle ?? "")}
      </Text>
      <Text textStyle="largeText" color="primary" textAlign="start">
        {convertHtmlToText(model.textContents.pageDescription ?? "")}
      </Text>
    </VStack>
  );
});
