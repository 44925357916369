import { action, computed, makeObservable, observable } from "mobx";
import {
  Model,
  MIN_INPUT_LENGTH,
  VOTING_DATA_ERR_KEYS,
  DATE_KEYS,
  getDateObject,
  checkDateCorrectness,
  VotingDraftDetails,
  getDateModelType,
  correctDate
} from "@lubbezposrednio-frontend/core";

type ErrorsType = Record<VOTING_DATA_ERR_KEYS, string>;

export class VotingDataModel extends Model {
  name = "";
  startVotingDate: Record<DATE_KEYS, string> = this.getInitialDate();
  endVotingDate: Record<DATE_KEYS, string> = this.getInitialDate(true);
  errors: ErrorsType = {} as ErrorsType;

  constructor() {
    super();

    makeObservable(this, {
      name: observable,
      setName: action.bound,
      startVotingDate: observable.ref,
      changeStartDate: action.bound,
      endVotingDate: observable.ref,
      changeEndDate: action.bound,

      errors: observable,
      saveError: action.bound,
      isError: computed,

      clearData: action.bound,
      clearErrors: action.bound
    });
  }

  private setInitialDate(updateCb: (key: DATE_KEYS, value: string) => void, date?: string) {
    if (date) {
      const dateObject = getDateModelType(new Date(date));
      updateCb(DATE_KEYS.YEAR, dateObject[DATE_KEYS.YEAR]);
      updateCb(DATE_KEYS.MONTH, dateObject[DATE_KEYS.MONTH]);
      updateCb(DATE_KEYS.DAY, dateObject[DATE_KEYS.DAY]);
      updateCb(DATE_KEYS.HOUR, dateObject[DATE_KEYS.HOUR]);
      updateCb(DATE_KEYS.MINUTES, dateObject[DATE_KEYS.MINUTES]);
    }
  }

  public loadDataFromDraft(draft: VotingDraftDetails) {
    this.setName(draft.name ?? "");
    this.setInitialDate(this.changeStartDate, draft.startDate);
    this.setInitialDate(this.changeEndDate, draft.endDate);
  }

  private validateName() {
    const key = VOTING_DATA_ERR_KEYS.NAME;
    const name = this.name.trim();

    const isNotEmpty = this.saveError(key, !!name, "Podaj nazwę głosowania");

    isNotEmpty &&
      this.saveError(key, name.length >= MIN_INPUT_LENGTH, `Nazwa musi mieć minimum ${MIN_INPUT_LENGTH} znaki`);
  }

  setName(name: string) {
    this.name = name;
    this.errors[VOTING_DATA_ERR_KEYS.NAME] && this.validateName();
  }

  public changeStartDate(key: DATE_KEYS, value: string) {
    this.startVotingDate = { ...this.startVotingDate, [key]: value };

    this.errors[VOTING_DATA_ERR_KEYS.SUBMIT_START] && this.validateDates();
  }

  public changeEndDate(key: DATE_KEYS, value: string) {
    this.endVotingDate = { ...this.endVotingDate, [key]: value };

    this.errors[VOTING_DATA_ERR_KEYS.SUBMIT_END] && this.validateDates();
  }

  private isDateComplete(date: Record<DATE_KEYS, string>, allowEmpty?: boolean): boolean {
    return (
      Object.values(date).every(value => !!value) || (!!allowEmpty && Object.values(date).every(value => value === ""))
    );
  }

  private getInitialDate(isEnd?: boolean) {
    const currDateObject = new Date();

    const date = {
      [DATE_KEYS.MINUTES]: currDateObject.getMinutes() + (isEnd ? 0 : 1),
      [DATE_KEYS.HOUR]: currDateObject.getHours() + (isEnd ? 1 : 0),
      [DATE_KEYS.DAY]: currDateObject.getDate(),
      [DATE_KEYS.MONTH]: currDateObject.getMonth() + 1,
      [DATE_KEYS.YEAR]: currDateObject.getFullYear()
    };

    return correctDate(date);
  }

  private validateDatesValues() {
    const startDateObject = getDateObject(this.startVotingDate);

    const isStartInFuture = startDateObject.getTime() - Date.now() > 0;

    this.saveError(VOTING_DATA_ERR_KEYS.SUBMIT_START, isStartInFuture, "Data nie moze być wcześniejsza niż aktualna");

    if (isStartInFuture) {
      const endDateObject = getDateObject(this.endVotingDate);
      const isEndAfterStart = startDateObject.getTime() - endDateObject.getTime() < 0;

      this.saveError(
        VOTING_DATA_ERR_KEYS.SUBMIT_END,
        isEndAfterStart,
        "Data zakończenia musi być po dacie rozpoczęcia"
      );
    }
  }

  public validateDatesCompleteness(allowEmpty?: boolean): boolean {
    const message = "Wymagane jest podanie pełnej daty";
    const isStartComplete = this.isDateComplete(this.startVotingDate, allowEmpty);
    const isEndComplete = this.isDateComplete(this.endVotingDate, allowEmpty);

    this.saveError(VOTING_DATA_ERR_KEYS.SUBMIT_START, isStartComplete, message);
    this.saveError(VOTING_DATA_ERR_KEYS.SUBMIT_END, isEndComplete, message);
    return isStartComplete && isEndComplete;
  }

  private validateDates() {
    const isComplete = this.validateDatesCompleteness();
    isComplete && this.validateDatesValues();
  }

  public saveDateError(date: Record<DATE_KEYS, string>, key: VOTING_DATA_ERR_KEYS) {
    const { isCorrect, message } = checkDateCorrectness(date);
    this.saveError(key, isCorrect, message);
  }

  saveError(key: VOTING_DATA_ERR_KEYS, condition: boolean, message: string): boolean {
    this.errors[key] = condition ? "" : message;
    return condition;
  }

  validateFields() {
    this.validateName();
    this.validateDates();
  }

  override async onInit() {}

  clearErrors() {
    this.errors = {} as ErrorsType;
  }

  clearData() {
    this.name = "";
    this.startVotingDate = this.getInitialDate();
    this.endVotingDate = this.getInitialDate(true);
    this.clearErrors();
  }

  get isError(): boolean {
    return Object.values(this.errors).some(error => error);
  }
}
