import { Box, Flex, Text, VStack } from "@chakra-ui/layout";
import { CustomTooltip, PhotoDisplay, VerifiedCheck } from "@lubbezposrednio-frontend/commons";
import React, { useMemo } from "react";
import { LandingPageViewModel } from "../LandingPageViewModel";
import { observer } from "mobx-react";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { VerifiedCheckIcon } from "../../../assets/VerifiedCheckIcon";

type OrganizerDataProps = {
  model: LandingPageViewModel;
};

export const OrganizerData: React.FC<OrganizerDataProps> = observer(({ model }) => {
  const { organizerData: organizer } = model;

  const organizerName = useMemo(() => {
    const name = organizer.is_organization
      ? organizer.organization_name
      : `${organizer.first_name ?? ""} ${organizer.last_name ?? ""}`;

    return name?.trim() || organizer.email;
  }, [JSON.stringify(organizer)]);

  return (
    <Flex justify="start" align="center" gap="19px" wrap="wrap">
      <PhotoDisplay
        previewSrc={organizer.avatar_url ? `${process.env.REACT_APP_BACKEND_URL}${organizer.avatar_url}` : undefined}
        maxSize={{ base: "94px", sm: "120px" }}
        maxPhotoSize={{ base: "70px", sm: "90px" }}
      />
      <VStack h="100%" justify="space-between" align="start">
        {model.organizerData.verified && (
          <VerifiedCheck
            text="Organizator zaufany"
            color="accent"
            fontSize={{ base: "14px", sm: "18px" }}
            lineHeight={{ base: "14px", sm: "20px" }}
            fontWeight="bold"
            alignSelf="auto"
            customCheck={<VerifiedCheckIcon w={{ base: "24px", sm: "28px" }} h={{ base: "24px", sm: "28px" }} />}
          />
        )}
        <Text
          fontSize={{ base: "20px", sm: "27px" }}
          lineHeight={{ base: "22px", sm: "30px" }}
          fontWeight="bold"
          color="primary"
        >
          {organizerName}
        </Text>
        {model.organizerData.affirmation_url ? (
          <CustomTooltip message={`${model.affirmationSize},PDF`}>
            <Button
              variant="regular"
              py={{ base: "3.5px", sm: "7.5px" }}
              fontSize={{ base: "14px", sm: "11px" }}
              lineHeight={{ base: "12px", sm: "16px" }}
              fontWeight="bold"
              gap="10px"
              h={{ base: "25px", sm: "30px" }}
              onClick={model.downloadOrganizerAffirmation.bind(model)}
            >
              Pobierz oświadczenie
              <Image src="/Icon feather-download.svg" />
            </Button>
          </CustomTooltip>
        ) : (
          <Box />
        )}
      </VStack>
    </Flex>
  );
});
