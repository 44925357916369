import { Flex, Image, Text, VStack, Box } from "@chakra-ui/react";
import React from "react";
import { InternalLinksSection } from "./components/InternalLinksSection";
import { SocialMediaSection } from "./components/SocialMediaSection";
import { Link } from "react-router-dom";
import { LAYOUT_CONTENT_WIDTHS } from "@lubbezposrednio-frontend/core";
import { InternalLink } from "../InternalLink/InternalLink";

interface FooterProps {
  redirectBaseUrl?: string;
}

export const Footer: React.FC<FooterProps> = ({ redirectBaseUrl }) => {
  return (
    <Flex
      background="background"
      w="100%"
      borderTopWidth="2px"
      borderColor="primary"
      borderLeftWidth={{ base: 0, md: "2px" }}
      borderRightWidth={{ base: 0, md: "2px" }}
      borderTopRadius={{ base: 0, md: "15px" }}
      gap="26px"
      py={{ base: "30px", md: "40px" }}
      px={{ base: "30px", md: "60px", xl: "140px" }}
      maxW={LAYOUT_CONTENT_WIDTHS}
      boxShadow="standard"
      direction={{ base: "column", md: "row" }}
      justify="space-between"
    >
      <VStack gap="20px" align={{ base: "center", md: "flex-start" }} flex={1}>
        <a href={redirectBaseUrl ?? "/"}>
          <Image src="/LBP_Logo_Black.svg" alt="logo" />
        </a>
        <Box>
          <Text
            textStyle="text"
            color="primary"
            textAlign={{ base: "center", md: "left" }}
            maxW="100%"
            display="inline"
          >
            LubBezpośrednio.pl to obywatelska kampania edukacji społecznej Instytutu Demokracji Bezpośredniej. Działamy
            na rzecz wyegzekwowania art. 4 Konstytucji RP. Chcemy wzmocnić zwierzchnią władzę Narodu i popularyzować
            bezpośrednie formy jej sprawowania. Promujemy referenda i istniejące w polskim prawie instrumenty
            zaangażowania obywateli. Propagujemy jawność i rozliczalność władzy na każdym szczeblu. Tworzymy też
            platformę decyduj.lubbezposrednio.pl, by upowszechniać demokratyczne, bezpieczne i wiarygodne podejmowanie
            decyzji na najniższym szczeblu: w organizacjach i społecznościach obywateli.{" "}
          </Text>
          <InternalLink
            isInline
            to="https://lubbezposrednio.pl/"
            openInNewTab
            text="Przejdź na LubBezposrednio.pl, by dowiedzieć się więcej."
          />
          <br />
          <br />
          <Text textStyle="text" color="primary" textAlign={{ base: "center", md: "left" }} maxW="100%">
            Zadanie Instytut Demokracji Bezpośredniej jest dofinansowane z budżetu państwa ze środków otrzymanych od
            Kancelarii Prezesa Rady Ministrów w ramach realizacji zadania publicznego. Wartość dofinansowania 4284900
            zł, co stanowi 100,00% wartości zadania publicznego.
          </Text>
        </Box>
      </VStack>
      <VStack
        gap="32px"
        align={{ base: "center", md: "flex-end" }}
        minW={{ base: undefined, md: "300px" }}
        maxW={{ md: "30%" }}
      >
        <SocialMediaSection />
        <InternalLinksSection redirectBaseUrl={redirectBaseUrl} />
      </VStack>
    </Flex>
  );
};
