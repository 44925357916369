import { Button, Flex, Image, Text, useToast, VStack } from "@chakra-ui/react";
import React from "react";
import { usePdfDropzone } from "../../../../hooks/usePdfDropzone";
import { ProfileViewSectionContainer } from "../ProfileViewSectionContainer/ProfileViewSectionContainer";
import { Link } from "react-router-dom";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { NEW_TAB_WARNING } from "@lubbezposrednio-frontend/core";

interface StatementUploadSectionProps {
  setPdf: (file: File) => void;
  statementUrl?: string;
  downloadTemplate: () => void;
}

export const StatementUploadSection: React.FC<StatementUploadSectionProps> = ({
  setPdf,
  downloadTemplate,
  statementUrl
}) => {
  const toast = useToast();
  const { getInputProps, open } = usePdfDropzone(setPdf, toast);

  return (
    <ProfileViewSectionContainer>
      <VStack align={{ base: "center", md: "flex-start" }} h="100%" gap="12px" justify="space-between">
        <Text textStyle="text" color="primary" fontWeight="bold">
          Oświadczenie
        </Text>
        <input {...getInputProps()} />
        <CustomTooltip message="917KB, PDF">
          <Button
            w="100%"
            bg="background"
            color="primary"
            variant="custom"
            borderRadius="standard"
            borderWidth="2px"
            borderColor="primary"
            gap="12px"
            py="24px"
            onClick={downloadTemplate}
          >
            <Text textStyle="text">Pobierz oświadczenie</Text>
            <Image src="/DOWNLOAD.svg" />
          </Button>
        </CustomTooltip>
        <Flex w="100%" gap="12px" flexDir={{ base: "column", sm: "row" }}>
          <Button
            flex={1}
            h="48px"
            bg="primary"
            color="white"
            variant="custom"
            borderRadius="standard"
            py="15px"
            onClick={open}
          >
            <Text textStyle="text">Wgraj podpisane oświadczenie</Text>
          </Button>
          {statementUrl && (
            <CustomTooltip message={NEW_TAB_WARNING}>
              <Link to={statementUrl} target="blank" tabIndex={-1}>
                <Button
                  bg="primary"
                  color="white"
                  variant="custom"
                  borderRadius="standard"
                  py="24px"
                  w={{ base: "100%", sm: "auto" }}
                >
                  <Text textStyle="text">Otwórz</Text>
                </Button>
              </Link>
            </CustomTooltip>
          )}
        </Flex>
      </VStack>
    </ProfileViewSectionContainer>
  );
};
