import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface CollapseRedirectButtonProps {
  redirectPath: string;
  redirectButtonText: string;
}

export const CollapseRedirectButton: React.FC<CollapseRedirectButtonProps> = ({ redirectPath, redirectButtonText }) => {
  return (
    <Link to={redirectPath} data-cy="collapse-redirect">
      <HStack
        bg="accent"
        py={{ base: "12px", md: "20px" }}
        px={{ base: "20px", md: "30px" }}
        borderRadius="standard"
        justify="center"
        cursor="pointer"
      >
        <Text
          fontSize={{ base: "14px", md: "18px" }}
          lineHeight={{ base: "16px", md: "20px" }}
          color="background"
          fontWeight="bold"
        >
          {redirectButtonText + " >>"}
        </Text>
      </HStack>
    </Link>
  );
};
