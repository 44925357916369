import { useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import { SinglePage } from "./SinglePage";
import { PaginationItem } from "./PaginationItem";
import { BREAKPOINTS } from "@lubbezposrednio-frontend/core";

const START_PAGE = 1;
const MAX_VISIBLE_ITEMS_QTY = window.innerWidth < BREAKPOINTS.MOBILE ? 3 : 5;
const MAX_OFFSET = Math.floor(MAX_VISIBLE_ITEMS_QTY / 2);

type CustomPaginationProps = {
  size: number;
  currentPage: number;
  changePage: (page: number) => void;
};

export const CustomPagination: React.FC<CustomPaginationProps> = ({ size, currentPage, changePage }) => {
  const visiblePages = useMemo(
    () =>
      new Array(MAX_VISIBLE_ITEMS_QTY)
        .fill(1)
        .map((_, index) => currentPage - MAX_OFFSET + index)
        .filter(page => page > START_PAGE && page < size),
    [currentPage, size]
  );

  return (
    <Flex justify="center" gap={{ base: 0, sm: "8px" }} maxW="95vw">
      <PaginationItem
        isDisabled={currentPage === START_PAGE}
        changePage={changePage}
        updatedPage={currentPage - 1}
        content="<"
      />

      <SinglePage page={START_PAGE} isSelected={START_PAGE === currentPage} changePage={changePage} />

      {START_PAGE + (MAX_OFFSET + 1) < currentPage && (
        <PaginationItem
          changePage={changePage}
          updatedPage={Math.max(START_PAGE, currentPage - MAX_VISIBLE_ITEMS_QTY)}
          content="..."
        />
      )}

      {visiblePages.map(page => (
        <SinglePage key={page} page={page} isSelected={page === currentPage} changePage={changePage} />
      ))}

      {size - (MAX_OFFSET + 1) > currentPage && (
        <PaginationItem
          changePage={changePage}
          updatedPage={Math.min(size, currentPage + MAX_VISIBLE_ITEMS_QTY)}
          content="..."
        />
      )}
      {START_PAGE !== size && <SinglePage page={size} isSelected={size === currentPage} changePage={changePage} />}
      <PaginationItem
        isDisabled={currentPage === size}
        changePage={changePage}
        updatedPage={currentPage + 1}
        content=">"
      />
    </Flex>
  );
};
