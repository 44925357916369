import { RadioGroup, VStack, Radio, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { AuthorizationModel, bindProperty, ACCOUNT_TYPES } from "@lubbezposrednio-frontend/core";

interface RadioButtonsSectionProps {
  authorization: AuthorizationModel;
}

export const RadioButtonsSection: React.FC<RadioButtonsSectionProps> = observer(({ authorization }) => {
  return (
    <RadioGroup
      {...bindProperty(authorization, "accountType")}
      alignSelf="flex-start"
      ml="12px"
      mt="12px"
      variant="primary"
    >
      <VStack align="flex-start">
        <Radio value={ACCOUNT_TYPES.PERSON}>
          <CustomTooltip
            message="Wybierz opcję „osoba fizyczna”, jeżeli reprezentujesz grupę nieformalną: np. grono sąsiadów, którzy chcą podjąć decyzję o zagospodarowaniu wspólnej przestrzeni albo grupę przyjaciół, którzy chcą zagłosować w sprawie wyboru miejsca wspólnej wycieczki."
            placement="right"
          >
            <Text textStyle="text" fontWeight="bold" color="primary">
              Osoba fizyczna
            </Text>
          </CustomTooltip>
        </Radio>
        <Radio value={ACCOUNT_TYPES.ORGANIZATION}>
          <CustomTooltip
            message=" Wybierz opcję „organizacja”, jeżeli formalnie (tj. jesteś członkiem zarządu albo uprawnionym pełnomocnikiem) reprezentujesz jakąś instytucję: stowarzyszenie, związek zawodowy, spółdzielnie mieszkaniową, koło gospodyń wiejskich etc."
            placement="right"
          >
            <Text textStyle="text" fontWeight="bold" color="primary">
              Organizacja
            </Text>
          </CustomTooltip>
        </Radio>
      </VStack>
    </RadioGroup>
  );
});
