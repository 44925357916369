import { Flex, Text } from "@chakra-ui/react";

type VotingItemDateProps = { date?: string; label: string };

export const VotingItemDate: React.FC<VotingItemDateProps> = ({ date, label }) => {
  return (
    <Flex columnGap="5px" pb={{ base: "8px", sm: "5px" }} wrap={"wrap"} justify={{ base: "center", sm: "flex-start" }}>
      <Text color="primary" textStyle="text" fontWeight="bold">
        {label}:
      </Text>
      <Text color="accent" textStyle="text" fontWeight="bold">
        {date}
      </Text>
    </Flex>
  );
};
