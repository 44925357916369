import { Text, VStack } from "@chakra-ui/react";
import { CreateVotingAnswers } from "./CreateVotingAnswers";
import { VotingQuestionsModel } from "../VotingQuestionsModel";
import { observer } from "mobx-react-lite";
import { CustomInputWithCounter, CustomIconWIthTooltip } from "@lubbezposrednio-frontend/commons";
import { useMemo } from "react";
import { VotingQuestionArrayType } from "@lubbezposrednio-frontend/core";

type VotingQuestionProps = {
  question: VotingQuestionArrayType;
  model: VotingQuestionsModel;
  order: number;
  isRemovable: boolean;
};

export const CreateVotingQuestion: React.FC<VotingQuestionProps> = observer(
  ({ question, model, order, isRemovable }) => {
    const error = useMemo(
      () => model.questionsErrors.find(error => error.id === question.id),
      [JSON.stringify(model.questionsErrors)]
    );

    return (
      <VStack align="flex-start" gap={0}>
        <CustomIconWIthTooltip
          pb="10px"
          tooltipMsg="Pytanie może mieć maksymalnie 500 znaków. Jeżeli potrzebujesz szerzej opisać problem, w którym decydujecie – możecie dodać link do swojej strony lub dokumentu, w którym prezentujecie szerszy kontekst problemu. W tym celu dodaj w pytaniu odnośnik do pełnego linku np. https://google.com/"
        >
          <Text variant="smallTitle">Pytanie {order}</Text>
        </CustomIconWIthTooltip>

        <CustomInputWithCounter
          value={question.question}
          onChange={value => model.changeQuestion(question.id, value)}
          color="primary"
          h="38px"
          maxLength={500}
          onRemove={isRemovable ? () => model.removeQuestion(question.id) : undefined}
        />

        {error?.message && <Text variant="error">{error?.message}</Text>}
        <Text variant="smallTitle" pt="21px">
          Odpowiedzi
        </Text>
        <CreateVotingAnswers model={model} question={question} />
      </VStack>
    );
  }
);
