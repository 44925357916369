import { Flex, Image, InputRightElement, Text } from "@chakra-ui/react";
import { VotingQuestionsModel } from "../VotingQuestionsModel";
import { CustomInputWithCounter } from "@lubbezposrednio-frontend/commons";
import { AnswerErrorType, VotingAnswerType, getAnswerChar } from "@lubbezposrednio-frontend/core";

const INPUT_HEIGHT = "38px";
const PADDING_LEFT = "33px";

type CreateVotingAnswerProps = {
  error?: AnswerErrorType;
  index: number;
  model: VotingQuestionsModel;
  answer: VotingAnswerType;
  questionId: number;
};

export const CreateVotingAnswer: React.FC<CreateVotingAnswerProps> = ({ error, index, model, answer, questionId }) => {
  return (
    <>
      <Flex pl={PADDING_LEFT} align="start" gap={{ base: "34px", sm: "38px" }} w="100%">
        <Text variant="smallTitle" lineHeight={INPUT_HEIGHT}>
          {getAnswerChar(index)}
        </Text>
        <CustomInputWithCounter
          flex={1}
          w="100%"
          value={answer.content}
          onChange={value => model.changeAnswer(questionId, answer.id, value)}
          color="primary"
          h={INPUT_HEIGHT}
          maxLength={300}
          onRemove={index > 1 ? () => model.removeAnswer(questionId, answer.id) : undefined}
        />
      </Flex>
      {error?.message && (
        <Text pl={PADDING_LEFT} variant="error">
          {error.message}
        </Text>
      )}
    </>
  );
};
