import { makeObservable, observable, action } from "mobx";
import { Model } from "@lubbezposrednio-frontend/core";

export class HomeViewModel extends Model {
  number = 0;

  constructor() {
    super();

    makeObservable(this, {
      number: observable,
      increaseNumber: action.bound
    });
  }

  override async onInit() {
    this.number = 4;
  }

  increaseNumber() {
    this.number++;
  }
}
