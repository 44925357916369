import { action, computed, makeObservable, observable } from "mobx";
import { MAX_PDF_SIZE, Model, LandingPageDraftDataType, LandingPageDataType } from "@lubbezposrednio-frontend/core";

export class LandingPageDocumentsModel extends Model {
  customPrivacyPolicy?: File;
  customPrivacyPolicyUrl?: string;
  customPrivacyPolicyError = "";
  customRegulations?: File;
  customRegulationsUrl?: string;
  customRegulationsError = "";

  constructor() {
    super();

    makeObservable(this, {
      customPrivacyPolicy: observable.ref,
      setCustomPrivacyPolicy: action.bound,
      customPrivacyPolicyError: observable,
      customRegulations: observable.ref,
      setCustomRegulations: action.bound,
      customRegulationsError: observable,
      validatePrivacyPolicy: action.bound,
      validateRegulations: action.bound,

      setInitialData: action.bound,

      clearData: action.bound,
      clearErrors: action.bound,
      isError: computed,

      customRegulationsUrl: observable,
      customPrivacyPolicyUrl: observable,
      customRegulationsUrlName: computed,
      customPrivacyPolicyUrlName: computed
    });
  }

  public async setInitialData(data: (LandingPageDataType | LandingPageDraftDataType)["landing_page"]) {
    this.customPrivacyPolicyUrl =
      data.privacy_policy_url && process.env.REACT_APP_BACKEND_URL + data.privacy_policy_url;
    this.customRegulationsUrl =
      data.terms_and_conditions_url && process.env.REACT_APP_BACKEND_URL + data.terms_and_conditions_url;
  }
  validatePrivacyPolicy() {
    const isValid = this.validateDocumentSize(this.customPrivacyPolicy);
    this.customPrivacyPolicyError = isValid ? "" : `Maksymalna wielkość pliku to ${MAX_PDF_SIZE / 1024 / 1024} MB`;
  }

  setCustomPrivacyPolicy(customPrivacyPolicy?: File) {
    this.customPrivacyPolicy = customPrivacyPolicy;
    this.customPrivacyPolicyUrl = undefined;
    this.validatePrivacyPolicy();
  }

  validateRegulations() {
    const isValid = this.validateDocumentSize(this.customRegulations);
    this.customRegulationsError = isValid ? "" : `Maksymalna wielkość pliku to ${MAX_PDF_SIZE / 1024 / 1024} MB`;
  }

  setCustomRegulations(customRegulations?: File) {
    this.customRegulations = customRegulations;
    this.customRegulationsUrl = undefined;
    this.validateRegulations();
  }

  private validateDocumentSize(document?: File): boolean {
    return (document?.size ?? 0) <= MAX_PDF_SIZE;
  }

  clearErrors() {
    this.customPrivacyPolicyError = "";
    this.customRegulationsError = "";
  }

  clearData() {
    this.customPrivacyPolicy = undefined;
    this.customRegulations = undefined;
    this.clearErrors();
  }

  get isError(): boolean {
    return !!(this.customRegulationsError || this.customPrivacyPolicyError);
  }

  get customRegulationsUrlName(): string {
    const lastSlashIndex = this.customRegulationsUrl?.lastIndexOf("/");
    return decodeURI(this.customRegulationsUrl?.slice((lastSlashIndex ?? 0) + 1) ?? "");
  }

  get customPrivacyPolicyUrlName(): string {
    const lastSlashIndex = this.customPrivacyPolicyUrl?.lastIndexOf("/");
    return decodeURI(this.customPrivacyPolicyUrl?.slice((lastSlashIndex ?? 0) + 1) ?? "");
  }

  override async onInit() {}
}
