import { VOTING_STATUSES } from "@lubbezposrednio-frontend/core";
import { VotingTileButtonProps } from "../../CreatedVotingsListView/components/VotingList/VotingTileButton";
import { Link } from "react-router-dom";
import { CustomButton } from "@lubbezposrednio-frontend/commons";

const BUTTON_HEIGHT = 48;

export const ActiveVotingButton: React.FC<VotingTileButtonProps> = ({ voting }) => {
  const isOngoing = voting.status === VOTING_STATUSES.ONGOING;

  return isOngoing ? (
    <Link to={`/glosowanie/${voting.id}`} tabIndex={-1}>
      <CustomButton
        text="Głosuj"
        position="absolute"
        h={`${BUTTON_HEIGHT}px`}
        bottom={`-${BUTTON_HEIGHT / 2}px`}
        left={{ base: 0, sm: "auto" }}
        right={0}
        m="auto"
        w="fit-content"
        px="15px"
      />
    </Link>
  ) : (
    <></>
  );
};
