import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Flex, Text, VStack } from "@chakra-ui/react";
import { CreateVotingTile } from "../CreateVotingTile";
import { CustomCheckbox, CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { CsvVotersAdd } from "./components/CsvVotersAdd";
import { ManualVotersAdd } from "./components/ManualVotersAdd";
import { observer } from "mobx-react-lite";
import { EligibleVotersModel } from "./EligibleVotersModel";

type EligibleVotersProps = {
  model: EligibleVotersModel;
};

export const EligibleVoters: React.FC<EligibleVotersProps> = observer(({ model }) => {
  const { eligibleVotersListType: type } = model;

  return (
    <CreateVotingTile title="Lista uprawnionych do głosowania" pt="16px" pr="22px">
      <VStack align="flex-start" spacing={0} pb={!type ? { base: "35px", sm: "44px" } : { base: "24px", sm: "27px" }}>
        <CustomCheckbox
          label={"PLIK .CSV"}
          value={type === "csv"}
          onChange={value => model.setEligibleVotersListType(value ? "csv" : undefined)}
          wrapperProps={{ lineHeight: 1, pb: "9px" }}
        />
        <Flex gap="10px">
          <CustomCheckbox
            label={"WPISZ LISTĘ RĘCZNIE"}
            value={type === "manual"}
            onChange={value => model.setEligibleVotersListType(value ? "manual" : undefined)}
            wrapperProps={{ lineHeight: 1 }}
          />
          <CustomTooltip message="Aby wprowadzić więcej niż jeden adres email należy je rozdzielić spacją, enterem, przecinkiem lub średnikiem">
            <InfoOutlineIcon color="primary" tabIndex={0} />
          </CustomTooltip>
        </Flex>
        {type === "csv" && <CsvVotersAdd model={model} />}
        {type === "manual" && <ManualVotersAdd model={model} />}
        {model.error && (
          <Text variant="error" pt="5px" pb={0}>
            {model.error}
          </Text>
        )}
      </VStack>
    </CreateVotingTile>
  );
});
