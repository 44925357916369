import { Flex, Text, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { ProfileViewModel } from "../../ProfileViewModel";
import { OrganizationDataSection } from "../OrganizationDataSection/OrganizationDataSection";
import { PhotoUploadSection } from "../PhotoUploadSection/PhotoUploadSection";
import { StatementUploadSection } from "../StatementUploadSection/StatementUploadSection";
import { VerifiedCheck } from "@lubbezposrednio-frontend/commons";

interface OrganizationProfileProps {
  model: ProfileViewModel;
}

export const OrganizationProfile: React.FC<OrganizationProfileProps> = observer(({ model }) => {
  return (
    <>
      <Text textStyle="header" color="primary" mb="40px" mt={{ base: "30px", md: "50px" }}>
        Konto
      </Text>
      <Text textStyle="header" color="primary" mb="40px" textAlign="center">
        Profil i dane organizacji
      </Text>
      <VStack w={{ base: "100%", md: "800px" }} gap="30px" mb="50px">
        {model.isOrganisationVerified && <VerifiedCheck text="Organizacja zweryfikowana" my="-20px" />}
        <Flex direction={{ base: "column", md: "row" }} gap="20px" w="100%">
          <PhotoUploadSection
            profilePhoto={model.organizationProfilePhoto}
            setProfilePhoto={model.setOrganizationProfilePhoto}
            title="Logo organizacji"
            previewSrc={model.organizationPhotoPreview}
          />
          <StatementUploadSection
            setPdf={model.setOrganizationStatement}
            downloadTemplate={model.downloadAffirmationTemplate}
            statementUrl={model.organizationStatementUrl ?? ""}
          />
        </Flex>
        <OrganizationDataSection model={model} />
      </VStack>
    </>
  );
});
