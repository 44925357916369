import React, { useEffect, useMemo } from "react";
import { bindToCommand, withModel } from "@lubbezposrednio-frontend/core";
import { CreateLandingPageViewModel } from "./CreateLandingPageViewModel";
import { CustomButton, Layout } from "@lubbezposrednio-frontend/commons";
import { observer } from "mobx-react-lite";
import { Button, Flex, Text, VStack, useToast } from "@chakra-ui/react";
import { LandingPageTexts } from "./components/LandingPageTexts/LandingPageTexts";
import { LandingPageDocuments } from "./components/LandingPageDocuments/LandingPageDocuments";
import { LandingPageDates } from "./components/LandingPageDates/LandingPageDates";
import { useLocation, useNavigate } from "react-router-dom";

interface CreateLandingPageViewProps {
  model: CreateLandingPageViewModel;
}

export const CreateLandingPageView: React.FC<CreateLandingPageViewProps> = observer(({ model }) => {
  const { state } = useLocation();

  const title = state?.isEdit ? "Edytuj stronę zapisu na głosowanie" : "Utwórz stronę zapisu na głosowanie";

  useEffect(() => {
    model.clearData();
    if (state?.isEdit || state?.id) {
      model.getInitialData(state?.isEdit, state?.id);
    }
  }, [JSON.stringify(state)]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    document.title = title;
  }, []);

  const toast = useToast();
  const navigate = useNavigate();

  const submitCommand = useMemo(() => model.handleSubmit(toast, navigate), [toast, navigate]);

  return (
    <Layout isMenu>
      <Text textStyle="header" color="primary" py={{ base: "41px", sm: "34px" }} textAlign="center">
        {title}
      </Text>
      <VStack gap="50px">
        <LandingPageTexts model={model.textsModel} isActive={model.isActive} />
        <LandingPageDocuments model={model.documentsModel} isActive={model.isActive} />
        <LandingPageDates model={model.datesModel} isActive={model.isActive} />
        <Flex mb="50px" gap="20px" wrap="wrap" justify="center">
          {!model.isEdit && (
            <Button
              h="37px"
              py="20px"
              variant="custom"
              background="background"
              color="primary"
              borderRadius="standard"
              borderColor="primary"
              borderWidth="2px"
              fontSize="18px"
              fontWeight="bold"
              lineHeight="20px"
              w="285px"
              maxW="80vw"
              onClick={() => model.handleCreateDraft(navigate, toast)}
            >
              Zapisz wersję roboczą
            </Button>
          )}
          <CustomButton text={model.isEdit ? "Zapisz" : "Utwórz"} {...bindToCommand(submitCommand)} />
        </Flex>
      </VStack>
    </Layout>
  );
});

const CreateLandingPageViewWithModel = withModel<CreateLandingPageViewModel>(
  CreateLandingPageView,
  CreateLandingPageViewModel
);

export default CreateLandingPageViewWithModel;
