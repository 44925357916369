import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Flex, FlexProps, Text, useToast } from "@chakra-ui/react";
import { LIST_BOTTOM_PADDING, ListModel } from "@lubbezposrednio-frontend/core";
import { CustomLoader } from "../CustomLoader/CustomLoader";
import { CustomPagination } from "../CustomPagination/CustomPagination";

type CustomListProps = FlexProps & {
  model: ListModel;
  noItemsMessage?: string;
};

export const CustomList: React.FC<CustomListProps> = observer(({ model, children, noItemsMessage, ...props }) => {
  const toast = useToast();

  const onChangePage = useCallback(
    async (page: number) => {
      model.changePage(page, toast);
    },
    [model.changePage]
  );

  const isListLoader = model.isListLoading;
  const isEmptyTable = !model.currentPageItems.length && !isListLoader;
  const emptyPlaceholder = model.listError
    ? `Wystąpił błąd podczas pobierania listy głosowań: ${model.listError}`
    : noItemsMessage ?? "Brak utworzonych głosowań.";

  return (
    <Flex mt={{ base: "41px", sm: "53px" }} flex={1} direction={"column"} {...props}>
      <CustomLoader isVisible={isListLoader} />
      {children}

      {isEmptyTable && (
        <Text textStyle="largeText" pb={`${LIST_BOTTOM_PADDING}px`} align="center">
          {emptyPlaceholder}
        </Text>
      )}

      {!!model.totalPages && (
        <CustomPagination currentPage={model.currPage} size={model.totalPages} changePage={onChangePage} />
      )}
    </Flex>
  );
});
