import { VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomButton, Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";

interface SessionExpiredViewProps {}

export const SessionExpiredView: React.FC<SessionExpiredViewProps> = ({}) => {
  useEffect(() => {
    document.title = "Sesja wygasła";
  }, []);

  return (
    <Layout>
      <VStack spacing={0} flex={1} py={{ base: 0, sm: "135px" }} maxW="100vw">
        <MessageWithIcon
          image="/ICON_REGISTER_CONFIRM.svg"
          title="Sesja wygasła"
          message=""
          bottomElement={
            <Link to="/logowanie" tabIndex={-1}>
              <CustomButton
                text="Zaloguj się"
                backgroundColor="primary"
                mb="-20px"
                display={{ base: "flex", md: "none" }}
                w="200px"
              />
            </Link>
          }
        />
        <Link to="/logowanie" tabIndex={-1}>
          <CustomButton text="Zaloguj się" backgroundColor="primary" my="50px" display={{ base: "none", md: "flex" }} />
        </Link>
      </VStack>
    </Layout>
  );
};
