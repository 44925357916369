import { CheckCircleIcon } from "@chakra-ui/icons";
import { BoxProps, HStack, Text } from "@chakra-ui/react";
import React from "react";

interface VerifiedCheckProps extends BoxProps {
  text: string;
  color?: string;
  customCheck?: React.ReactNode;
}

export const VerifiedCheck: React.FC<VerifiedCheckProps> = ({ text, color, customCheck, ...rest }) => {
  return (
    <HStack alignSelf="flex-end" {...rest}>
      {customCheck ?? <CheckCircleIcon color={color ?? "green.500"} />}
      <Text color={color ?? "green.500"}>{text}</Text>
    </HStack>
  );
};
