import { Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { VotingViewModel } from "../../../VotingViewModel";

export const VotingInfoDates: React.FC<{ model: VotingViewModel }> = observer(({ model }) => {
  return (
    <Flex justify="space-between" wrap="wrap">
      <Flex wrap="wrap" justify="center">
        <Text variant="votingSummaryLabel">Data rozpoczęcia:&nbsp;</Text>
        <Text variant="votingSummaryValue">{model.startDate}</Text>
      </Flex>
      <Flex wrap="wrap" justify="center">
        <Text variant="votingSummaryLabel">Data zakończenia:&nbsp;</Text>
        <Text variant="votingSummaryValue">{model.endDate}</Text>
      </Flex>
    </Flex>
  );
});
