import { useState } from "react";
import { Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { CreatedVotingsListViewModel } from "../../CreatedVotingsListViewModel";
import { CustomLoader } from "@lubbezposrednio-frontend/commons";
import { VOTING_STATUSES } from "@lubbezposrednio-frontend/core";
import { VotingsListModel } from "../../../../models/VotingsListModel";

export type VotingTileButtonProps = {
  model: VotingsListModel & Partial<CreatedVotingsListViewModel>;
  voting: { status?: VOTING_STATUSES; id: string };
  isOpen: boolean;
};

const MORE_BUTTON_HEIGHT = 48;

export const VotingTileButton: React.FC<VotingTileButtonProps> = ({ model, voting, isOpen }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const isFinished = voting.status === VOTING_STATUSES.FINISHED;

  const handleOnClick = async () => {
    if (!isLoading) {
      setIsLoading(true);
      model.setOpenItemPositionData?.();
      const result = await model.onTileButtonClick?.(voting.id, voting.status);

      setIsLoading(false);

      if (result?.message) {
        toast({
          description: result.message,
          status: result.isSuccess ? "success" : "error"
        });
      }
    }
  };

  const topButtonPosition = MORE_BUTTON_HEIGHT / 2;
  const bottomButtonPosition = topButtonPosition + (model.openItemPositionData?.height ?? 0);

  return (
    <Button
      position="absolute"
      h={`${MORE_BUTTON_HEIGHT}px`}
      bottom={{
        base: `-${bottomButtonPosition}px`,
        sm: `-${topButtonPosition}px`
      }}
      left={{ base: 0, sm: "auto" }}
      right={0}
      m="auto"
      variant="custom"
      bg="primary"
      color="background"
      textStyle="largeText"
      fontWeight="bold"
      borderRadius={"standard"}
      w="149px"
      justifyContent={"center"}
      onClick={handleOnClick}
      zIndex={isOpen ? 2 : 0}
    >
      <CustomLoader isVisible={isLoading} size="sm" color="background" mr="5px" />
      {isFinished ? (
        <Flex gap="15px">
          <Text>Protokół</Text>
          <Image src="/DownloadIcon.svg" />
        </Flex>
      ) : (
        `${isOpen ? "Mniej" : "Więcej"} >>`
      )}
    </Button>
  );
};
