export const HEADER_X_PADDING = {
  base: "38px",
  sm: "100px",
  lg: "180px",
  xl: "278px"
};

export const MAIN_MENU_ITEMS = [
  {
    label: "Stwórz listę zapisów na głosowanie",
    href: "/organizator/modyfikuj-strone-zapisu-na-glosowanie",
    customProps: { bg: "accent", color: "background", borderColor: "accent" }
  },
  {
    label: "Twoje głosowania",
    href: "/organizator/twoje-glosowania",
    customProps: { bg: "primary", color: "background" }
  },
  {
    label: "Twoje listy zapisów",
    href: "/organizator/lista-zapisow-na-glosowanie",
    customProps: { bg: "primary", color: "background" }
  },
  {
    label: "Konto",
    href: "/organizator/konto"
  },
  {
    label: "Regulamin",
    href: `${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/terms_of_service`,
    openInNewTab: true
  },
  {
    label: "Polityka prywatności",
    href: `${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/privacy_policy`,
    openInNewTab: true
  },
  {
    label: "Zgody",
    href: "/organizator/zgody"
  }
];
