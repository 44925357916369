export enum CONSENT_NAMES {
  ORGANIZATIONS_MARKETING = "accepted_organizations_marketing_terms",
  APPLICATIONS_MARKETING = "accepted_applications_marketing_terms",
  APPLICATIONS_SERVICE = "accepted_applications_service_terms",
  ORGANIZATIONS_SERVICE = "accepted_organizations_service_terms"
}

export type ConsentType = {
  id: number;
  name: CONSENT_NAMES;
  title: string;
  content: string;
  isChecked: boolean;
};
