import { extendTheme } from "@chakra-ui/react";
import { BREAKPOINTS } from "../utils/constants";
import {
  buttonStyles,
  checkboxStyles,
  dividerStyles,
  inputStyles,
  radioStyles,
  switchStyles,
  textStyles
} from "./components";

export const config = {
  breakpoints: {
    sm: `${BREAKPOINTS.MOBILE}px`,
    md: `${BREAKPOINTS.MD}px`,
    lg: `${BREAKPOINTS.LG}px`,
    xl: `${BREAKPOINTS.XL}px`,
    "2xl": `${BREAKPOINTS.FULL_HD}px`
  },
  fonts: {
    heading:
      "Montserrat,Averta,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    body: "Montserrat,Averta,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"
  },
  colors: {
    primary: "#430098",
    accent: "#E51F44",
    background: "#FFFAEB",
    error: "#E53E3E",
    success: "#2FCE25"
  },
  components: {
    Modal: {
      baseStyle: {
        dialogContainer: {
          "@supports(height: -webkit-fill-available)": {}
        }
      }
    },
    Button: buttonStyles,
    Radio: radioStyles,
    Divider: dividerStyles,
    Switch: switchStyles,
    Checkbox: checkboxStyles,
    Text: textStyles,
    Input: inputStyles
  },
  textStyles: {
    header: {
      fontSize: { base: "32px", md: "50px" },
      lineHeight: { base: "40px", md: "61px" },
      fontWeight: "bold"
    },
    largeText: {
      fontSize: "18px",
      lineHeight: "20px"
    },
    text: {
      fontSize: "14px",
      lineHeight: "20px"
    },
    title: {
      fontSize: { base: "27px", sm: "48px" },
      lineHeight: { base: "28px", sm: "58px" },
      fontWeight: "bold"
    }
  },
  shadows: {
    standard: "6px 6px 6px #00000029"
  },
  radii: { standard: "15px" }
};

export const theme = extendTheme(config);
