import { observer } from "mobx-react-lite";
import { CreateLandingPageTile } from "../CreateLandingPageTile";
import { Box, Text, VStack } from "@chakra-ui/react";
import { CustomDocumentAdd } from "@lubbezposrednio-frontend/commons";
import { LandingPageDocumentsModel } from "./LandingPageDocumentsModel";

export const LandingPageDocuments: React.FC<{ model: LandingPageDocumentsModel; isActive: boolean }> = observer(
  ({ model, isActive }) => {
    return (
      <CreateLandingPageTile title="Własne dokumenty">
        <VStack align="start" gap="34px">
          <Box w="100%">
            <CustomDocumentAdd
              placeholder="Wczytaj własny regulamin..."
              onChange={model.setCustomRegulations.bind(model)}
              value={model.customRegulations}
              buttonLabel={"Zobacz regulamin"}
              previewUrl={model.customRegulationsUrl}
              isDisabled={isActive}
              previewUrlName={model.customRegulationsUrlName}
            />
            {model.customRegulationsError && <Text variant="error">{model.customRegulationsError}</Text>}
          </Box>
          <Box w="100%">
            <CustomDocumentAdd
              placeholder="Wczytaj własną politykę prywatności..."
              onChange={model.setCustomPrivacyPolicy.bind(model)}
              value={model.customPrivacyPolicy}
              buttonLabel={"Zobacz politykę prywatności"}
              previewUrl={model.customPrivacyPolicyUrl}
              isDisabled={isActive}
              previewUrlName={model.customPrivacyPolicyUrlName}
            />
            {model.customPrivacyPolicyError && <Text variant="error">{model.customPrivacyPolicyError}</Text>}
          </Box>
        </VStack>
      </CreateLandingPageTile>
    );
  }
);
