import { Button, Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { DATE_SORTERS, LP_STATUSES } from "@lubbezposrednio-frontend/core";
import { CloseIcon } from "@chakra-ui/icons";
import { CreatedLandingPagesListViewModel } from "../CreatedLandingPagesListViewModel";
import { RadioFilterButton } from "../../CreatedVotingsListView/components/VotingsListFilters/RadioFilterButton";
import { SorterIcon } from "../../../assets/SorterIcon";
import { SearchFilter } from "../../CreatedVotingsListView/components/VotingsListFilters/SearchFilter";
import { useNavigate } from "react-router-dom";

const STATUS_FILTERS = [
  { label: "AKTYWNE", value: [LP_STATUSES.ONGOING] },
  { label: "NIEAKTYWNE", value: [LP_STATUSES.PLANNED, LP_STATUSES.FINISHED] },
  { label: "WERSJE ROBOCZE", value: [LP_STATUSES.DRAFT] }
];

const LIST_SORTERS = [
  { label: "Od najstarszych", value: DATE_SORTERS.DATE_ASC },
  { label: "Od najnowszych", value: DATE_SORTERS.DATE_DESC }
];

const BUTTON_WIDTH = "260px";

type LandingPagesListFiltersProps = {
  model: CreatedLandingPagesListViewModel;
};

export const LandingPagesListFilters: React.FC<LandingPagesListFiltersProps> = observer(({ model }) => {
  const navigate = useNavigate();
  const isFilterSelected = !!(model.statusFilter || model.pageTitleFilter || model.sorter);

  return (
    <Flex
      gap={{ base: "12px", sm: "40px" }}
      pt={{ base: "28px", sm: "34px" }}
      wrap={{ base: "wrap-reverse", sm: "wrap" }}
      justify="center"
      w={{ base: BUTTON_WIDTH, sm: "auto" }}
    >
      <Flex gap={{ base: "12px", sm: "16px" }} wrap="wrap" justify="center">
        {STATUS_FILTERS.map((filter, i) => {
          const isSelected = JSON.stringify(filter.value) === JSON.stringify(model.statusFilter);
          return (
            <Button
              key={i}
              variant="statusFilter"
              bg={isSelected ? "primary" : "background"}
              color={isSelected ? "background" : "primary"}
              onClick={() => model.changeStatusFilter(isSelected ? undefined : filter.value, true)}
            >
              {filter.label}
            </Button>
          );
        })}
        <Flex w={{ base: "100%", sm: "auto" }} justify="space-evenly" pos={{ base: "relative" }} gap="16px">
          <RadioFilterButton<DATE_SORTERS>
            label="Sorter"
            getIcon={(fill: string) => <SorterIcon fill={fill} />}
            options={LIST_SORTERS}
            onChange={value => model.setSorter?.(value, true)}
            value={model.sorter}
          />

          <SearchFilter
            value={model.pageTitleFilter}
            onChange={(value?: string) => model.changePageTitleFilter(value, true)}
          />

          {isFilterSelected && (
            <IconButton
              aria-label="Clear filters"
              variant="filter"
              icon={<CloseIcon color="primary" />}
              onClick={() => model.clearFilters()}
            />
          )}
        </Flex>
      </Flex>

      <Button
        h="auto"
        py="12px"
        bg="accent"
        color="background"
        _hover={{}}
        gap="13px"
        borderRadius="standard"
        onClick={() => navigate("/organizator/modyfikuj-strone-zapisu-na-glosowanie")}
        minW={{ base: BUTTON_WIDTH, sm: "240px" }}
      >
        <Image src="/Icon awesome-plus.svg" />
        <Text textStyle="text" whiteSpace="normal" textTransform="uppercase">
          Utwórz stronę zapisu na głosowanie
        </Text>
      </Button>
    </Flex>
  );
});
