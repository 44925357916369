export const FilterIcon: React.FC<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="17.75"
      viewBox="0 0 19.5 17.75"
    >
      <path
        id="Icon_feather-filter"
        data-name="Icon feather-filter"
        d="M20.5,4.5H3l7,8.277V18.5l3.5,1.75V12.777Z"
        transform="translate(-2 -3.5)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
