import { useEffect, useState } from "react";
import { refreshToken } from "../api/authApi";
import { setAuthHeaders } from "../api/axiosClient";
import {
  ANCHOR_TAG_REGEX,
  BREAKPOINTS,
  FIRST_LETTER_CHAR_CODE,
  LAST_LETTER_CHAR_CODE,
  LINK_REGEX,
  LOCAL_STOREGE_KEYS
} from "./constants";
export function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`);
  }
}

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export const PASSWORD_REGEX = /^(?=.*\W|.+_)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
type SetIsOpenType = React.Dispatch<React.SetStateAction<boolean>>;

const checkIsListClosing = (
  event: MouseEvent,
  setIsOpen: SetIsOpenType,
  listRef: React.RefObject<HTMLDivElement>,
  iconRef: React.RefObject<SVGSVGElement | HTMLButtonElement>
) => {
  assertIsNode(event.target);
  const isOnBlurAction = !listRef.current?.contains(event.target) && !iconRef.current?.contains(event.target);

  if (isOnBlurAction) {
    setIsOpen(false);
    document.removeEventListener("mousedown", e => checkIsListClosing(e, setIsOpen, listRef, iconRef));
  }
};

export const onIconWithListClick = (
  setIsOpen: SetIsOpenType,
  listRef: React.RefObject<HTMLDivElement>,
  iconRef: React.RefObject<HTMLButtonElement>
) => {
  setIsOpen(isOpen => {
    if (isOpen) {
      document.removeEventListener("mousedown", e => checkIsListClosing(e, setIsOpen, listRef, iconRef));
    } else {
      document.addEventListener("mousedown", e => checkIsListClosing(e, setIsOpen, listRef, iconRef));
    }

    return !isOpen;
  });
};

export const useIsMobile = (customBreakpoint?: number) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < (customBreakpoint ?? BREAKPOINTS.MOBILE));

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < (customBreakpoint ?? BREAKPOINTS.MOBILE));
  };

  useEffect(() => {
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return isMobile;
};

export const getTokenExpiryDate = (expiresIn: number) => Date.now() + expiresIn * 1000; //convert seconds to ms

export const setTokenInLocalStorage = (token: string, tokenExpiresIn: number, refreshTokenExpiresIn: number) => {
  localStorage.setItem(LOCAL_STOREGE_KEYS.ACCESS_TOKEN, token);
  localStorage.setItem(LOCAL_STOREGE_KEYS.TOKEN_EXPITY_DATE, String(getTokenExpiryDate(tokenExpiresIn)));
  localStorage.setItem(LOCAL_STOREGE_KEYS.REFRESH_TOKEN_EXPITY_DATE, String(getTokenExpiryDate(refreshTokenExpiresIn)));
};
export const clearLocalStorageToken = () => {
  localStorage.removeItem(LOCAL_STOREGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STOREGE_KEYS.TOKEN_EXPITY_DATE);
  localStorage.removeItem(LOCAL_STOREGE_KEYS.REFRESH_TOKEN_EXPITY_DATE);
};

export const DEFAULT_DEBOUNCE_TIME = 500;

export const handleKeyboardNavigation = (cb: () => void | Promise<void>) => {
  return {
    onClick: () => cb(),
    onKeyDown: (e: React.KeyboardEvent) => (e.key === "Enter" || e.key === "Return") && cb()
  };
};

export const downloadFile = (file: Blob, fileName: string) => {
  const href = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const getAnswerChar = (index: number, char = "") => {
  const maxOffset = LAST_LETTER_CHAR_CODE - FIRST_LETTER_CHAR_CODE + 1;
  const firstIndex = Math.floor(index / maxOffset);
  const lastLetterIndex = index % maxOffset;

  if (firstIndex) {
    char = getAnswerChar(firstIndex - 1, char);
  }
  return char + String.fromCharCode(FIRST_LETTER_CHAR_CODE + lastLetterIndex);
};

export const refreshAuthToken = async () => {
  try {
    const refreshTokenResponse = await refreshToken();
    const {
      access_token: accessToken,
      expires_in: expiresIn,
      refresh_expires_in: refreshExpiresIn
    } = refreshTokenResponse.data;
    setTokenInLocalStorage(accessToken, expiresIn, refreshExpiresIn);
    setAuthHeaders();
  } catch (err) {
    console.error(err);
  }
};

export const addLinksToText = (text: string): string => {
  const textLinks = text.match(LINK_REGEX);

  return (
    textLinks?.reduce(
      (finalText, link) => finalText.replace(link, `<a target='_blank' href=${link} >${link}</a>`),
      text
    ) ?? text
  ).trim();
};

export const getPlainTextFromTextWithLinks = (textWithLinks: string) => {
  const texts = textWithLinks.replaceAll(ANCHOR_TAG_REGEX, "$1");
  return texts;
};
