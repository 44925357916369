import { action, computed, makeObservable, observable } from "mobx";
import {
  deactivateAccountSendEMail,
  clearLocalStorageToken,
  downloadFile,
  EditProfileType,
  ProfileApiData,
  Model,
  FilePreview,
  editProfile,
  getAffirmationTemplate,
  getProfile,
  ToastType
} from "@lubbezposrednio-frontend/core";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";

const AFFIRMATION_ERRORS = [
  {
    responseMessage: "Sent file is not the original affirmation",
    toastMessage:
      "Błędne oświadczenie. W polu oświadczenie dodaj podpisany cyfrowo plik oświadczenia, które pobrałeś z naszej strony. Nie możesz modyfikować jego treści inaczej niż podpisem."
  },
  {
    responseMessage: "File is not signed by profil zaufany",
    toastMessage:
      "Błędne oświadczenie, nie zostało prawidłowo podpisane. W polu oświadczenie dodaj podpisany cyfrowo plik oświadczenia."
  }
];

export class ProfileViewModel extends Model {
  userProfilePhoto: FilePreview | null = null;
  organizationProfilePhoto: FilePreview | null = null;
  profileStatement: File | null = null;
  organizationStatement: File | null = null;
  firstName = "";
  lastName = "";
  email = "test@test.com";
  phoneNumber = "";
  postCode = "";
  organizationName = "";
  organizationAdress = "";
  organizationCity = "";
  organizationPostCode = "";
  organizationPhoneNumber = "";
  organizationNip = "";
  organizationKrs = "";
  organizationRegon = "";
  isOrganization = false;
  userPhotoPreview: string | null = null;
  organizationPhotoPreview: string | null = null;
  statementUrl: string | null = null;
  organizationStatementUrl: string | null = null;
  isUserVerified = false;
  isOrganisationVerified = false;

  constructor() {
    super();

    makeObservable(this, {
      userProfilePhoto: observable,
      organizationProfilePhoto: observable,
      profileStatement: observable,
      organizationStatement: observable,
      firstName: observable,
      lastName: observable,
      email: observable,
      phoneNumber: observable,
      postCode: observable,
      organizationName: observable,
      organizationAdress: observable,
      organizationCity: observable,
      organizationPostCode: observable,
      organizationPhoneNumber: observable,
      organizationNip: observable,
      organizationKrs: observable,
      organizationRegon: observable,
      isOrganization: observable,
      userPhotoPreview: observable,
      organizationPhotoPreview: observable,
      statementUrl: observable,
      organizationStatementUrl: observable,
      isUserVerified: observable,
      isOrganisationVerified: observable,
      setUserProfilePhoto: action.bound,
      setOrganizationProfilePhoto: action.bound,
      setProfileStatement: action.bound,
      setOrganizationStatement: action.bound,
      loadProfileData: action.bound,
      isUserStatementUploaded: computed
    });
  }

  override async onInit() {}

  async loadProfileData({ user }: ProfileApiData) {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;

    if (user.is_organization) {
      this.organizationPhotoPreview = user.avatar_url && baseUrl + user.avatar_url;
      this.organizationName = user.organization_name ?? "";
      this.organizationNip = user.nip ?? "";
      this.organizationKrs = user.krs ?? "";
      this.organizationRegon = user.regon ?? "";
      this.organizationPostCode = user.zip_code ?? "";
      this.organizationAdress = user.address ?? "";
      this.organizationCity = user.city ?? "";
      this.organizationPhoneNumber = user.phone_number ?? "";
      this.organizationStatementUrl = user.affirmation_url && baseUrl + user.affirmation_url;
      this.isOrganisationVerified = !!user.verified;

      this.userPhotoPreview = user.representatives_avatar_url && baseUrl + user.representatives_avatar_url;
      this.firstName = user.representatives_first_name ?? "";
      this.lastName = user.representatives_last_name ?? "";
      this.phoneNumber = user.representatives_phone_number ?? "";
      this.postCode = user.representatives_zip_code ?? "";
      this.statementUrl = user.representatives_affirmation_url && baseUrl + user.representatives_affirmation_url;
      this.isUserVerified = !!user.representatives_verified;
    } else {
      this.userPhotoPreview = user.avatar_url && baseUrl + user.avatar_url;
      this.firstName = user.first_name ?? "";
      this.lastName = user.last_name ?? "";
      this.phoneNumber = user.phone_number ?? "";
      this.postCode = user.zip_code ?? "";
      this.statementUrl = user.affirmation_url && baseUrl + user.affirmation_url;
      this.isUserVerified = !!user.verified;
    }

    this.email = user.email;
    this.isOrganization = !!user.is_organization;
  }

  public async getProfileData(toast: ToastType) {
    try {
      const response = await getProfile();
      if (response.status === 200) {
        return this.loadProfileData(response.data);
      }
      this.showGenericErrorToast(toast);
    } catch (err) {
      this.showGenericErrorToast(toast);
    }
  }

  setUserProfilePhoto(photo: FilePreview) {
    this.userProfilePhoto = photo;
  }

  setOrganizationProfilePhoto(photo: FilePreview) {
    this.organizationProfilePhoto = photo;
  }

  setProfileStatement(file: File) {
    this.profileStatement = file;
  }

  setOrganizationStatement(file: File) {
    this.organizationStatement = file;
  }

  public async downloadAffirmationTemplate() {
    const response = await getAffirmationTemplate();
    if (response?.status === 200 && response?.data) {
      downloadFile(response?.data, "oswiadczenie.pdf");
    }
  }

  private getSavedData() {
    const savedData = {} as EditProfileType;

    if (this.isOrganization) {
      savedData.organization_name = this.organizationName;
      savedData.krs = this.organizationKrs;
      savedData.nip = this.organizationNip;
      savedData.regon = this.organizationRegon;
      savedData.address = this.organizationAdress;
      savedData.city = this.organizationCity;
      savedData.affirmation = this.organizationStatement;
      savedData.zip_code = this.organizationPostCode;
      savedData.phone_number = this.organizationPhoneNumber;
      savedData.avatar = this.organizationProfilePhoto;
      savedData.representatives_affirmation = this.profileStatement;
      savedData.representatives_avatar = this.userProfilePhoto;
      savedData.representatives_phone_number = this.phoneNumber;
      savedData.representatives_zip_code = this.postCode;
      savedData.representatives_first_name = this.firstName;
      savedData.representatives_last_name = this.lastName;
    } else {
      savedData.zip_code = this.postCode;
      savedData.avatar = this.userProfilePhoto;
      savedData.affirmation = this.profileStatement;
      savedData.phone_number = this.phoneNumber;
      savedData.first_name = this.firstName;
      savedData.last_name = this.lastName;
    }

    return savedData;
  }

  public async saveProfileData(toast: ToastType) {
    try {
      const response = await editProfile(this.getSavedData());
      if (response.status === 200) {
        const baseUrl = process.env.REACT_APP_BACKEND_URL;
        const user = response.data?.user;

        if (this.isOrganization) {
          this.statementUrl = user?.representatives_affirmation_url
            ? baseUrl + user?.representatives_affirmation_url
            : undefined;
          this.organizationStatementUrl = user?.affirmation_url ? baseUrl + user?.affirmation_url : undefined;
        } else {
          this.statementUrl = user?.affirmation_url ? baseUrl + user?.affirmation_url : undefined;
        }

        return toast({ status: "success", isClosable: true, title: "Zmiany zostały zapisane" });
      }
    } catch (err: any) {
      const affirmationErrors = [
        ...(err?.response?.data?.errors?.affirmation ?? []),
        ...(err?.response?.data?.errors?.representatives_affirmation ?? [])
      ];

      const affirmationErrorMessage =
        (err as AxiosError)?.response?.status === 422 &&
        AFFIRMATION_ERRORS.find(affirmationErr => affirmationErrors?.includes?.(affirmationErr.responseMessage))
          ?.toastMessage;

      const detailedErrors: Record<string, string[]> = err?.response?.data?.errors;
      const detailedMessages =
        detailedErrors && Object.entries(detailedErrors)?.map(([key, messages]) => `${key}: ${messages?.join(", ")}`);

      this.showGenericErrorToast(
        toast,
        affirmationErrorMessage ||
          `Nie udało się zapisać zmian, spróbuj ponownie później. Treść błędu: ${detailedMessages ?? err?.message}`
      );
    }
  }

  public async deactivateAccount(toast: ToastType, navigate: NavigateFunction) {
    try {
      const response = await deactivateAccountSendEMail();
      if (response.status === 202) {
        navigate("/wyslanie-maila-z-potwierdzeniem-usuniecia-konta");
        clearLocalStorageToken();
      }
    } catch (err: any) {
      this.showGenericErrorToast(toast);
    }
  }

  get isUserStatementUploaded(): boolean {
    return !!(this.statementUrl || this.profileStatement);
  }
}
