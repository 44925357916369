import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { VotingViewModel } from "../../../VotingViewModel";
import { VotingCreatorData } from "../../../../CreatedVotingsListView/components/VotingList/VotingCreatorData";

export const VotingInfoData: React.FC<{ model: VotingViewModel }> = observer(({ model }) => {
  return (
    <Flex
      pb={{ base: "31px", sm: "17.5px" }}
      justify="space-between"
      flexDir={{ base: "column", sm: "row" }}
      rowGap="21px"
    >
      <Box>
        <Flex justify={{ base: "center", sm: "start" }}>
          <Text variant="votingSummaryLabel" whiteSpace="nowrap">
            Głosowanie:&nbsp;
          </Text>
          <Text variant="votingSummaryValue">{model.isPublic ? "jawne" : "niepubliczne"}</Text>
        </Flex>
      </Box>
      {model.organizer && (
        <VotingCreatorData
          votingId={model.id as string}
          maxW={{ base: "100%", sm: "50%" }}
          creatorData={{ pictureUrl: model.organizer.imageUrl, name: model.organizer.name }}
          gap="15px"
          textDirection="column"
          textProps={{
            textStyle: "largeText",
            textAlign: { base: "center", sm: "start" }
          }}
          justify="flex-end"
          flexDir={{ base: "column", sm: "row" }}
        />
      )}
    </Flex>
  );
});
