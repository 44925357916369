import { VStack, Flex } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { CustomFormControl, CustomInput } from "@lubbezposrednio-frontend/commons";
import { bindProperty } from "@lubbezposrednio-frontend/core";
import { ProfileViewModel } from "../../ProfileViewModel";
import { ProfileViewSectionContainer } from "../ProfileViewSectionContainer/ProfileViewSectionContainer";

interface OrganizationDataSectionProps {
  model: ProfileViewModel;
}

export const OrganizationDataSection: React.FC<OrganizationDataSectionProps> = observer(({ model }) => {
  return (
    <ProfileViewSectionContainer>
      <VStack mb="20px" gap="20px">
        <Flex direction={{ base: "column", md: "row" }} gap="12px" w="100%">
          <CustomFormControl labelTitle="Nazwa organizacji">
            <CustomInput autoComplete="given-name" {...bindProperty(model, "organizationName")} variant="profile" />
          </CustomFormControl>
          <CustomFormControl labelTitle="KRS">
            <CustomInput autoComplete="given-name" {...bindProperty(model, "organizationKrs")} variant="profile" />
          </CustomFormControl>
        </Flex>
        <Flex direction={{ base: "column", md: "row" }} gap="12px" w="100%">
          <CustomFormControl labelTitle="NIP">
            <CustomInput autoComplete="given-name" {...bindProperty(model, "organizationNip")} variant="profile" />
          </CustomFormControl>
          <CustomFormControl labelTitle="REGON">
            <CustomInput autoComplete="given-name" {...bindProperty(model, "organizationRegon")} variant="profile" />
          </CustomFormControl>
        </Flex>
        <Flex direction={{ base: "column", md: "row" }} gap="12px" w="100%">
          <CustomFormControl labelTitle="Adres">
            <CustomInput autoComplete="given-name" {...bindProperty(model, "organizationAdress")} variant="profile" />
          </CustomFormControl>
          <CustomFormControl labelTitle="Miasto">
            <CustomInput autoComplete="given-name" {...bindProperty(model, "organizationCity")} variant="profile" />
          </CustomFormControl>
        </Flex>
        <Flex direction={{ base: "column", md: "row" }} gap="12px" w="100%">
          <CustomFormControl labelTitle="Kod pocztowy">
            <CustomInput autoComplete="given-name" {...bindProperty(model, "organizationPostCode")} variant="profile" />
          </CustomFormControl>
          <CustomFormControl labelTitle="Nr telefonu">
            <CustomInput
              autoComplete="given-name"
              {...bindProperty(model, "organizationPhoneNumber")}
              variant="profile"
            />
          </CustomFormControl>
        </Flex>
      </VStack>
    </ProfileViewSectionContainer>
  );
});
