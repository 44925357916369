import { Box, Image, ChakraProps } from "@chakra-ui/react";
import React from "react";
import { FilePreview } from "@lubbezposrednio-frontend/core";

interface PhotoDisplayProps {
  profilePhoto?: FilePreview | null;
  previewSrc?: string | null;
  maxSize?: ChakraProps["maxW"];
  maxPhotoSize?: ChakraProps["maxW"];
}

export const PhotoDisplay: React.FC<PhotoDisplayProps> = ({ profilePhoto, previewSrc, maxSize, maxPhotoSize }) => {
  const src = profilePhoto?.src || previewSrc;

  return src ? (
    <Image src={src} maxW={maxSize ?? "120px"} maxH={maxSize ?? "120px"} borderRadius="standard" />
  ) : (
    <Box
      w={maxSize ?? "120px"}
      h={maxSize ?? "120px"}
      borderColor="primary"
      borderWidth="2px"
      borderRadius="standard"
      position="relative"
    >
      <Image
        src="/PERSON_ICON.svg"
        position="absolute"
        bottom={0}
        left="50%"
        transform="translate(-50%)"
        maxW={maxPhotoSize}
        maxH={maxPhotoSize}
      />
    </Box>
  );
};
