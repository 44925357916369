import { Flex, SpinnerProps, Spinner } from "@chakra-ui/react";

export const CustomLoader: React.FC<{ isVisible?: boolean } & SpinnerProps> = ({
  isVisible = true,
  ...props
}) => {
  return (
    <Flex align="center" justify="center">
      {isVisible && (
        <Spinner color="primary" size="xl" speed={"1s"} {...props} />
      )}
    </Flex>
  );
};
