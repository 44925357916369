import { Button, ButtonProps, Divider, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { LP_STATUSES, LandingPagesListItem, NEW_TAB_WARNING } from "@lubbezposrednio-frontend/core";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreatedLandingPagesListViewModel } from "../CreatedLandingPagesListViewModel";
import { ConfirmEndPublicationModal } from "./ConfirmEndPublicationModal";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { ConfirmDeleteDraftModal } from "./ConfirmDeleteDraftModal";

type TitleButtonType = {
  label: string;
  id: number;
  customProps?: ButtonProps;
  onClick: () => void;
  tooltipMessage?: string;
};

export const LandingPageData: React.FC<{ landingPage: LandingPagesListItem; model: CreatedLandingPagesListViewModel }> =
  observer(({ landingPage, model }) => {
    const [isDeleteDraftModal, setIsDeleteDraftModal] = useState(false);
    const [isEndModal, setIsEndModal] = useState(false);

    const navigate = useNavigate();

    const DRAFT_TILE_BUTTONS: TitleButtonType[] = useMemo(
      () => [
        {
          label: "Utwórz stronę zapisu",
          id: 1,
          onClick: () =>
            navigate("/organizator/modyfikuj-strone-zapisu-na-glosowanie", {
              state: { isEdit: false, id: landingPage.id, isActive: false }
            }),
          customProps: { bg: "primary", color: "background" }
        },
        { label: "Usuń wersję roboczą", id: 2, onClick: () => setIsDeleteDraftModal(true) }
      ],
      []
    );

    const TILE_BUTTONS: TitleButtonType[] = useMemo(
      () => [
        {
          label: "Edytuj publikację",
          id: 1,
          onClick: () =>
            navigate("/organizator/modyfikuj-strone-zapisu-na-glosowanie", {
              state: { isEdit: true, id: landingPage.id, isActive: landingPage.isActive }
            }),
          customProps: { isDisabled: landingPage.status === LP_STATUSES.FINISHED }
        },
        {
          label: "Zakończ publikację",
          id: 2,
          onClick: () => setIsEndModal(true),
          customProps: { isDisabled: landingPage.status !== LP_STATUSES.ONGOING }
        },
        {
          label: "Zebrane leady",
          id: 4,
          onClick: () => window.open(landingPage.leadUrl),
          customProps: { bg: "primary", color: "background", isDisabled: !landingPage.leadUrl },
          tooltipMessage: landingPage.leadUrl && NEW_TAB_WARNING
        }
      ],
      [navigate, JSON.stringify(landingPage)]
    );

    return (
      <Flex direction="column" gap="5px">
        <Flex textStyle="text" fontWeight="bold" whiteSpace="nowrap" flexWrap={"wrap"}>
          <Text color="primary">Data publikacji:&nbsp;</Text>
          <Text color="accent">{landingPage.startDate}</Text>
        </Flex>
        <Flex textStyle="text" fontWeight="bold" whiteSpace="nowrap" flexWrap={"wrap"}>
          <Text color="primary">Data zakończenia publikacji:&nbsp;</Text>
          <Text color="accent">{landingPage.endDate}</Text>
        </Flex>
        <Divider my="14.5px" />
        <Flex justify={{ base: "center", sm: "end" }} gap="16px" flexWrap={"wrap"}>
          {(landingPage.status !== LP_STATUSES.DRAFT ? TILE_BUTTONS : DRAFT_TILE_BUTTONS).map(button => (
            <CustomTooltip key={button.id} message={button.tooltipMessage ?? ""}>
              <Button
                w="218px"
                bg="transparent"
                borderColor="primary"
                borderRadius="standard"
                borderWidth="2px"
                color="primary"
                fontSize="18px"
                lineHeight="20px"
                fontWeight="bold"
                h="48px"
                onClick={button.onClick}
                _hover={{}}
                {...button.customProps}
                whiteSpace="normal"
              >
                {button.label}
              </Button>
            </CustomTooltip>
          ))}
        </Flex>
        <ConfirmEndPublicationModal
          isOpen={isEndModal}
          onClose={() => setIsEndModal(false)}
          model={model}
          id={landingPage.id}
        />
        <ConfirmDeleteDraftModal
          isOpen={isDeleteDraftModal}
          onClose={() => setIsDeleteDraftModal(false)}
          model={model}
          id={landingPage.id}
        />
      </Flex>
    );
  });
