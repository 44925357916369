import { action, computed, makeObservable, observable, runInAction } from "mobx";
import {
  Model,
  getFormattedDate,
  createVoting,
  ToastType,
  MAX_PDF_SIZE,
  FORM_ERROR_MESSAGE,
  getDateObject,
  VotingDraftDetails,
  getOrganizerVotings,
  DRAFTS_LIMIT,
  CreateVotingParams,
  VOTING_STATUSES,
  getApiParamsDate
} from "@lubbezposrednio-frontend/core";
import { VotingQuestionsModel } from "./components/VotingQuestions/VotingQuestionsModel";
import { EligibleVotersModel } from "./components/EligibleVoters/EligibleVotersModel";
import { VotingDataModel } from "./components/VotingData/VotingDataModel";
import { NavigateFunction } from "react-router-dom";

export enum CREATE_VOTING_ERR_KEYS {
  IS_PUBLIC,
  REGULATIONS,
  PRIVACY_POLICY
}

export class CreateVotingViewModel extends Model {
  isPublic?: boolean;
  customPrivacyPolicyUrl?: string;
  customPrivacyPolicy?: File;
  customRegulationsUrl?: string;
  customRegulations?: File;
  errors: Record<CREATE_VOTING_ERR_KEYS, string> = {} as Record<CREATE_VOTING_ERR_KEYS, string>;
  votingQuestionsModel: VotingQuestionsModel = new VotingQuestionsModel();
  eligibleVotersModel: EligibleVotersModel = new EligibleVotersModel();
  votingDataModel: VotingDataModel = new VotingDataModel();
  confirmData: { isVisible: boolean; startDate?: string } = { isVisible: false };
  draftsQuantity = 0;

  constructor() {
    super();

    makeObservable(this, {
      isPublic: observable,
      setIsPublic: action.bound,
      customPrivacyPolicy: observable.ref,
      setCustomPrivacyPolicy: action.bound,
      customRegulations: observable.ref,
      setCustomRegulations: action.bound,
      votingQuestionsModel: observable.ref,
      eligibleVotersModel: observable.ref,
      votingDataModel: observable.ref,
      confirmData: observable.ref,
      customPrivacyPolicyUrl: observable,
      customRegulationsUrl: observable,
      draftsQuantity: observable,
      loadDataFromDraft: action.bound,

      errors: observable,
      saveError: action.bound,
      isError: computed,

      clearData: action.bound,
      clearErrors: action.bound,
      customRegulationsUrlName: computed,
      customPrivacyPolicyUrlName: computed
    });
  }

  public loadDataFromDraft(draft: VotingDraftDetails) {
    this.setIsPublic(draft.isPublic);
    this.votingDataModel.loadDataFromDraft(draft);
    this.votingQuestionsModel.loadDataFromDraft(draft);
    this.eligibleVotersModel.loadDataFromDraft(draft);
    this.customRegulationsUrl = draft.termsAndConditionsUrl;
    this.customPrivacyPolicyUrl = draft.privacyPolicyUrl;
  }

  setIsPublic(isPublic?: boolean) {
    this.isPublic = isPublic;
    this.errors[CREATE_VOTING_ERR_KEYS.IS_PUBLIC] && this.validateIsPublic();
  }

  setCustomPrivacyPolicy(customPrivacyPolicy?: File) {
    this.customPrivacyPolicy = customPrivacyPolicy;
    this.customPrivacyPolicyUrl = undefined;
    this.errors[CREATE_VOTING_ERR_KEYS.PRIVACY_POLICY] && this.validateCustomDocuments();
  }

  setCustomRegulations(customRegulations?: File) {
    this.customRegulations = customRegulations;
    this.customRegulationsUrl = undefined;
    this.errors[CREATE_VOTING_ERR_KEYS.REGULATIONS] && this.validateCustomDocuments();
  }

  private validateIsPublic() {
    this.saveError(CREATE_VOTING_ERR_KEYS.IS_PUBLIC, this.isPublic !== undefined, "Wybierz typ głosowania");
  }

  private validateDocumentSize(document?: File): boolean {
    return (document?.size ?? 0) <= MAX_PDF_SIZE;
  }

  private validateCustomDocuments() {
    const documentValidations = [
      {
        key: CREATE_VOTING_ERR_KEYS.REGULATIONS,
        value: this.customRegulations
      },
      {
        key: CREATE_VOTING_ERR_KEYS.PRIVACY_POLICY,
        value: this.customPrivacyPolicy
      }
    ];

    documentValidations.forEach(({ key, value }) => {
      this.saveError(
        key,
        this.validateDocumentSize(value),
        `Maksymalna wielkość pliku to ${MAX_PDF_SIZE / 1024 / 1024} MB`
      );
    });
  }

  saveError(key: CREATE_VOTING_ERR_KEYS, condition: boolean, message: string): boolean {
    this.errors[key] = condition ? "" : message;
    return condition;
  }

  validateFields() {
    this.validateIsPublic();
    this.votingDataModel?.validateFields();
    this.eligibleVotersModel?.validateFields();
    this.validateCustomDocuments();
  }

  public handleSumUp(navigate: NavigateFunction, toast: ToastType) {
    this.confirmData.isVisible &&
      runInAction(() => {
        this.confirmData = { isVisible: false };
      });

    this.votingQuestionsModel?.handleSumUp();
    this.validateFields();

    if (!this.isError) {
      return navigate("/organizator/utworz-glosowanie/podsumowanie");
    }
    this.showGenericErrorToast(toast, FORM_ERROR_MESSAGE);
  }

  async getDraftsQuantity() {
    try {
      const { data } = await getOrganizerVotings({ status: VOTING_STATUSES.DRAFT, per_page: 1 });
      const quantity = data.meta.total_pages;
      quantity &&
        runInAction(() => {
          this.draftsQuantity = quantity;
        });
    } catch (err) {
      console.error(err);
    }
  }

  private getVotingParams(isDraft?: boolean): Partial<CreateVotingParams> {
    const start = getApiParamsDate(this.votingDataModel.startVotingDate, isDraft);
    const end = getApiParamsDate(this.votingDataModel.endVotingDate, isDraft);

    const isEmptyQuestions = this.votingQuestionsModel.questionsArray.every(
      question => !question.question && question.answers.every(answer => !answer.content)
    );
    const questions = !isDraft || !isEmptyQuestions ? this.votingQuestionsModel.questionsArray : undefined;

    const isPublic = !isDraft || this.isPublic !== undefined ? String(!!this.isPublic) : undefined;

    return {
      title: this.votingDataModel.name,
      public: isPublic,
      start_date: start,
      end_date: end,
      privacy_policy: this.customPrivacyPolicy,
      privacy_policy_url: this.customPrivacyPolicyUrl,
      terms_and_conditions: this.customRegulations,
      terms_and_conditions_url: this.customRegulationsUrl,
      voters_list_url:
        this.eligibleVotersModel.eligibleVotersListType === "csv"
          ? this.eligibleVotersModel.votersCsvUrl || undefined
          : undefined,
      voters_list_file:
        this.eligibleVotersModel.eligibleVotersListType === "csv" ? this.eligibleVotersModel.votersCsv : undefined,
      emails:
        this.eligibleVotersModel.eligibleVotersListType === "manual"
          ? this.eligibleVotersModel.votersEmails
          : undefined,
      questions,
      published: String(!isDraft)
    };
  }

  clearErrors() {
    this.errors = {} as Record<CREATE_VOTING_ERR_KEYS, string>;
    this.votingDataModel.clearErrors();
    this.votingQuestionsModel.clearErrors();
    this.eligibleVotersModel.clearErrors();
  }

  private async validateDraft(toast: ToastType): Promise<boolean> {
    this.clearErrors();
    await this.getDraftsQuantity();
    if (this.draftsQuantity >= DRAFTS_LIMIT) {
      this.showGenericErrorToast(
        toast,
        `Maksymalna ilość wersji roboczych to ${DRAFTS_LIMIT}. Aby dodać kolejną wersję roboczą, usuń jedną z uprzednio utworzonych wersji roboczych.`
      );

      return false;
    }

    this.eligibleVotersModel.validateCsvSize();
    this.validateCustomDocuments();
    this.votingDataModel.validateDatesCompleteness(true);

    this.isError && this.showGenericErrorToast(toast, FORM_ERROR_MESSAGE);
    return !this.isError;
  }

  public async handleCreateDraft(navigate: NavigateFunction, toast: ToastType) {
    const isValid = await this.validateDraft(toast);

    if (isValid) {
      const errMessage = "Nie udało się utworzyć wersji roboczej. Spróbuj ponownie później.";
      try {
        const response = await createVoting(this.getVotingParams(true));

        if (response?.status === 200) {
          toast({ status: "success", isClosable: true, title: "Wersja robocza została utworzona" });
          navigate("/organizator/twoje-glosowania");
        } else {
          this.showGenericErrorToast(toast, errMessage);
        }
      } catch (err) {
        this.showGenericErrorToast(toast, errMessage);
        console.error(err);
      }
    }
  }

  public async handleCreateVoting(toast: ToastType) {
    const response = await createVoting(this.getVotingParams() as CreateVotingParams);

    if (response?.status === 200) {
      const startDate = getFormattedDate(getDateObject(this.votingDataModel.startVotingDate));
      runInAction(() => {
        this.confirmData = { isVisible: true, startDate };
      });
      this.clearData();
    } else {
      toast({
        title: "Nie udało się utworzyć głosowania. Spróbuj ponownie później.",
        status: "error",
        isClosable: true
      });
    }
  }

  clearData() {
    this.isPublic = undefined;
    this.customPrivacyPolicy = undefined;
    this.customRegulations = undefined;
    this.customRegulationsUrl = undefined;
    this.customPrivacyPolicyUrl = undefined;

    this.errors = {} as Record<CREATE_VOTING_ERR_KEYS, string>;

    this.votingQuestionsModel?.clearData();
    this.eligibleVotersModel?.clearData();
    this.votingDataModel?.clearData();
  }

  override async onInit() {}

  get customRegulationsUrlName(): string {
    const lastSlashIndex = this.customRegulationsUrl?.lastIndexOf("/");
    return decodeURI(this.customRegulationsUrl?.slice((lastSlashIndex ?? 0) + 1) ?? "");
  }

  get customPrivacyPolicyUrlName(): string {
    const lastSlashIndex = this.customPrivacyPolicyUrl?.lastIndexOf("/");
    return decodeURI(this.customPrivacyPolicyUrl?.slice((lastSlashIndex ?? 0) + 1) ?? "");
  }

  get isError(): boolean {
    return (
      Object.values(this.errors).some(error => error) ||
      this.votingDataModel?.isError ||
      !!this.eligibleVotersModel?.error ||
      !!this.votingQuestionsModel?.isError
    );
  }
}

export const createVotingModel = new CreateVotingViewModel();
