import { CustomTile } from "@lubbezposrednio-frontend/commons";
import { VOTE_TILE_WIDTH } from "../VotingView";

type VotingViewTileProps = {
  title: string;
  children: React.ReactNode;
};

export const VotingViewTile: React.FC<VotingViewTileProps> = ({ title, children }) => {
  return (
    <CustomTile
      title={title}
      tileWidth={VOTE_TILE_WIDTH}
      titleStyle={{ variant: "votingViewTileTitle" }}
      containerProps={{ maxW: "78vw" }}
    >
      {children}
    </CustomTile>
  );
};
