import { VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomButton, Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";

interface ServerErrorScreenProps {
  mainPageUrl?: string;
}

export const ServerErrorScreen: React.FC<ServerErrorScreenProps> = ({ mainPageUrl }) => {
  useEffect(() => {
    document.title = "Błąd serwera";
  }, []);

  return (
    <Layout redirectBaseUrl={mainPageUrl}>
      <VStack spacing={0} flex={1} py={{ base: 0, sm: "135px" }} maxW="100vw">
        <MessageWithIcon
          image="/ICON_REGISTER_FAILED.svg"
          title="Błąd serwera"
          message="Coś poszło nie tak, spróbuj ponownie później"
          bottomElement={
            <Link to={mainPageUrl ?? "/"} tabIndex={-1}>
              <CustomButton
                text="Strona główna"
                backgroundColor="accent"
                mb="-20px"
                display={{ base: "flex", md: "none" }}
                w="200px"
              />
            </Link>
          }
          isError
        />
        <Link to={mainPageUrl ?? "/"} tabIndex={-1}>
          <CustomButton
            text="Strona główna"
            backgroundColor="accent"
            my="50px"
            display={{ base: "none", md: "flex" }}
          />
        </Link>
      </VStack>
    </Layout>
  );
};
