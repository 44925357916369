import { useCallback } from "react";
import { Box } from "@chakra-ui/react";
import { CreatedVotingsListViewModel } from "../../CreatedVotingsListViewModel";
import { MAX_TILE_WIDTH, TILE_WIDTH } from "./CreatedVotingTile";
import { VOTING_LIST_ID } from "./VotingsList";
import { VotingQuestion } from "./VotingQuestion";
import { VotingsListModel } from "../../../../models/VotingsListModel";

const LINE_MATCH_DIFFERENCE = 10;

type VotingDetailsProps = { model: VotingsListModel & Partial<CreatedVotingsListViewModel> };

export const VotingDetails: React.FC<VotingDetailsProps> = ({ model }) => {
  const listContainer = document.getElementById(VOTING_LIST_ID);

  const stretchListContainer = useCallback(
    (item: HTMLDivElement | null) => {
      const itemData = item?.getBoundingClientRect();
      const offset = (itemData?.bottom ?? 0) - (listContainer?.getBoundingClientRect().bottom ?? 0);

      model.setOpenItemPositionData?.({
        height: itemData?.height ?? 0,
        offset,
        isOutside: offset > 0
      });
    },
    [listContainer]
  );

  return (
    <Box
      position="absolute"
      borderWidth="2px"
      borderColor="primary"
      bg="background"
      borderTop={0}
      w={`${TILE_WIDTH}px`}
      maxW={MAX_TILE_WIDTH}
      borderRadius="0px 0px 15px 15px"
      left="-2px"
      mt={`-${LINE_MATCH_DIFFERENCE}px`}
      pt={`${LINE_MATCH_DIFFERENCE + 34}px`}
      top={"100%"}
      zIndex={1}
      pb="58px"
      ref={stretchListContainer}
    >
      {model.openVotingDetails?.map((question, index) => (
        <VotingQuestion key={question.id} question={question} isDivider={!!index} />
      ))}
    </Box>
  );
};
