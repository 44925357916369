import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomButton, Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";
import { VStack } from "@chakra-ui/react";

export const RemoveAccountConfirmScreen: React.FC = () => {
  useEffect(() => {
    document.title = "Potwierdzenie usunięcia konta";
  }, []);

  return (
    <Layout>
      <VStack spacing={0} flex={1} py={{ sm: "135px" }} maxW="100vw">
        <MessageWithIcon
          image="/ICON_THX-register.svg"
          title="Konto zostało usunięte"
          message=""
          p={{ base: "120px 35px 60px", md: "121px 72px 63px" }}
          bottomElement={
            <Link to="/" tabIndex={-1}>
              <CustomButton
                display={{ base: "flex", md: "none" }}
                text="Strona główna"
                backgroundColor="primary"
                mb="-20px"
                w="200px"
              />
            </Link>
          }
        />
        <Link to="/" tabIndex={-1}>
          <CustomButton
            display={{ base: "none", md: "flex" }}
            text="Strona główna"
            backgroundColor="primary"
            mt="50px"
          />
        </Link>
      </VStack>
    </Layout>
  );
};
