export const radioStyles = {
  baseStyle: {
    control: {
      h: "22px",
      w: "22px",
      borderColor: "primary",
      borderWidth: "2px",

      _checked: {
        background: "background",
        borderColor: "primary",
        color: "primary",

        _hover: {
          background: "background",
          borderColor: "primary",
          color: "primary"
        }
      }
    },
    label: {
      textStyle: "largeText",
      fontWeight: "bold",
      color: "primary"
    }
  }
};
