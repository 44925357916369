import { Button, Flex, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { createVotingModel } from "./CreateVotingViewModel";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { observer } from "mobx-react-lite";
import { VotingType } from "./components/VotingType";
import { VotingData } from "./components/VotingData/VotingData";
import { VotingQuestions } from "./components/VotingQuestions/VotingQuestions";
import { EligibleVoters } from "./components/EligibleVoters/EligibleVoters";
import { VotingDrafts } from "./components/VotingDrafts/VotingDrafts";
import { MAX_CREATE_VOTING_WIDTH } from "@lubbezposrednio-frontend/core";
import { useNavigate } from "react-router-dom";

export const CreateVotingView: React.FC = observer(() => {
  useEffect(() => {
    document.title = "Utwórz głosowanie";
  }, []);

  const navigate = useNavigate();
  const toast = useToast();

  return (
    <Layout isMenu>
      <Text textStyle="header" color="primary" py={{ base: "41px", sm: "34px" }}>
        Utwórz głosowanie
      </Text>
      <VStack flex={1} spacing={{ base: "20px", sm: "34px" }}>
        <VotingType model={createVotingModel} />
        <VotingData model={createVotingModel.votingDataModel} />
        <VotingQuestions model={createVotingModel.votingQuestionsModel} />
        <EligibleVoters model={createVotingModel.eligibleVotersModel} />
        <VotingDrafts model={createVotingModel} />
        <Flex
          pt={{ base: "54px", sm: "10px" }}
          pb={{ base: "74px", sm: "88px" }}
          columnGap="23px"
          rowGap="28px"
          wrap="wrap"
          justify="center"
        >
          <Button
            h="56px"
            variant="custom"
            background="background"
            color="primary"
            borderRadius="standard"
            borderColor="primary"
            borderWidth="1px"
            textStyle="largeText"
            fontWeight="bold"
            w="285px"
            maxW={MAX_CREATE_VOTING_WIDTH}
            onClick={() => createVotingModel.handleCreateDraft(navigate, toast)}
          >
            Zapisz wersję roboczą
          </Button>
          <Button
            h="56px"
            variant="regular"
            textStyle="largeText"
            fontWeight="bold"
            w="380px"
            maxW={MAX_CREATE_VOTING_WIDTH}
            onClick={() => createVotingModel.handleSumUp(navigate, toast)}
          >
            Podsumowanie głosowania {">>"}
          </Button>
        </Flex>
      </VStack>
    </Layout>
  );
});
