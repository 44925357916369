import { observer } from "mobx-react-lite";
import { VotingViewModel } from "../../VotingViewModel";
import { VotingViewTile } from "../VotingViewTile";
import { Box, Text, VStack } from "@chakra-ui/react";
import { CustomInput, CustomCheckbox, CustomTooltip } from "@lubbezposrednio-frontend/commons";
import { Link } from "react-router-dom";
import { NEW_TAB_WARNING, ConsentsType } from "@lubbezposrednio-frontend/core";
import { useMemo } from "react";

type ConsentDataItem = { url: string; label: string; key: keyof ConsentsType };

export const VotingViewEmail: React.FC<{ model: VotingViewModel }> = observer(({ model }) => {
  const customTermsAndConditionsData: ConsentDataItem = {
    url: model.termsAndConditionsUrl,
    label: "regulamin tego głosowania",
    key: "customTermsAndConditions"
  };
  const customPrivacyPolicyData: ConsentDataItem = {
    url: model.privacyPolicyUrl,
    label: "polityka prywatności tego głosowania",
    key: "customPrivacyPolicy"
  };

  const consentsData: ConsentDataItem[] = useMemo(
    () => [
      {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/terms_of_service`,
        label: "regulamin",
        key: "termsAndConditions"
      },
      {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/privacy_policy`,
        label: "politykę prywatności",
        key: "privacyPolicy"
      },
      ...(model.termsAndConditionsUrl ? [customTermsAndConditionsData] : []),
      ...(model.privacyPolicyUrl ? [customPrivacyPolicyData] : [])
    ],
    [model.termsAndConditionsUrl, model.privacyPolicyUrl]
  );

  return (
    <VotingViewTile title="E-mail">
      <Box pb="38px">
        {!model.isEmailNecessary && (
          <Text variant="smallTitle" pb="10px">
            Podaj adres e-mail, na który otrzymałeś zaproszenie do głosowania.
          </Text>
        )}
        <Box mb="27px">
          <CustomInput
            value={model.email}
            onChange={model.handleEmailChange}
            placeholder="E-mail..."
            _placeholder={{ color: "primary", fontSize: "14px" }}
            height="48px"
          />
          {model.emailError && <Text variant="error">{model.emailError}</Text>}
        </Box>
        {!model.isEmailNecessary && (
          <VStack gap="9px" align="start">
            {consentsData.map(consent => (
              <CustomCheckbox
                key={consent.key}
                label={
                  <Text>
                    Akceptuję{" "}
                    <CustomTooltip message={NEW_TAB_WARNING}>
                      <Link to={consent.url} target="_blank">
                        {consent.label}
                      </Link>
                    </CustomTooltip>
                  </Text>
                }
                value={model.consents[consent.key]}
                onChange={value => model.changeConsent({ [consent.key]: value })}
                size="lg"
                wrapperProps={{ lineHeight: 1 }}
              />
            ))}

            {model.consentsError && <Text variant="error">{model.consentsError}</Text>}
          </VStack>
        )}
      </Box>
    </VotingViewTile>
  );
});
