import { Button, Divider, Flex, FlexProps, useToast } from "@chakra-ui/react";
import { VotingQuestionsModel } from "../VotingQuestionsModel";

const BUTTON_HEIGHT = 48;

type AddQuestionButtonProps = FlexProps & { model: VotingQuestionsModel };

export const AddQuestionButton: React.FC<AddQuestionButtonProps> = ({
  model,
  ...props
}) => {
  const toast = useToast();

  return (
    <Flex align="center" w="100%" {...props}>
      <Divider h="2px" flex={1} display={{ base: "none", sm: "flex" }} />
      <Button
        variant="regular"
        pos={{ base: "absolute", sm: "static" }}
        bottom={`-${BUTTON_HEIGHT / 2}px`}
        left={0}
        right={0}
        m="auto"
        h={`${BUTTON_HEIGHT}px`}
        textStyle="largeText"
        fontWeight="bold"
        onClick={() => model.addQuestion(toast)}
        maxW="95%"
      >
        Dodaj kolejne pytanie {">>"}
      </Button>
    </Flex>
  );
};
