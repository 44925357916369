import { VStack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Footer } from "@lubbezposrednio-frontend/commons";
import { withModel } from "@lubbezposrednio-frontend/core";
import { CollapsesSection } from "./components/CollapsesSection/CollapsesSection";
import { HomeViewHeader } from "./components/HomeViewHeader/HomeViewHeader";
import { HomeViewModel } from "./HomeViewModel";

interface HomeViewProps {
  model: HomeViewModel;
}

const HomeView: React.FC<HomeViewProps> = observer(({ model }) => {
  useEffect(() => {
    document.title = "Strona główna";
  }, []);

  return (
    <VStack h="100%" backgroundImage="url('/pattern_background.svg')" justify="space-between" minH="100vh">
      <HomeViewHeader />
      <CollapsesSection />
      <Footer />
    </VStack>
  );
});

const HomeViewWithModel = withModel<HomeViewModel>(HomeView, HomeViewModel);

export default HomeViewWithModel;
