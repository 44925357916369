export const SearchIcon: React.FC<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      id="MDI_loupe"
      data-name="MDI / loupe"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Boundary" fill={fill} stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Path_loupe"
        data-name="Path / loupe"
        d="M12,20a8,8,0,1,1,8-8,8.011,8.011,0,0,1-8,8M12,2a10,10,0,0,0,0,20h8a2,2,0,0,0,2-2V12A10,10,0,0,0,12,2m1,5H11v4H7v2h4v4h2V13h4V11H13Z"
        fill={fill}
      />
    </svg>
  );
};
