export const convertHtmlToText = (html: string) => {
  const htmlWithoutTags = html
    .replaceAll("<p>", "")
    .replaceAll("</p>", "")
    .replaceAll(/<a([^>]*)>/g, "")
    .replaceAll("</a>", "");
  const splitHtml = htmlWithoutTags.split(/(https?:\/\/[^\s]+)/g);

  const finalElementsArray = splitHtml.map((part, index) => {
    if (index % 2 === 0) {
      return <span key={index}>{part}</span>;
    } else {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
  });

  return finalElementsArray;
};
