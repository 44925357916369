import { useMemo } from "react";
import { CustomConfirmModal } from "@lubbezposrednio-frontend/commons";
import { CreatedLandingPagesListViewModel } from "../CreatedLandingPagesListViewModel";
import { useToast } from "@chakra-ui/react";

export const ConfirmDeleteDraftModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  model: CreatedLandingPagesListViewModel;
  id: string;
}> = ({ isOpen, onClose, model, id }) => {
  const toast = useToast();
  const confirmCommand = useMemo(() => model.handleDraftDelete(id, toast, onClose), [toast, id, onClose]);

  return (
    <CustomConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      title="Usuń wersję roboczą"
      description="Czy na pewno chcesz usunąć wersję roboczą?"
      confirmCommand={confirmCommand}
    />
  );
};
