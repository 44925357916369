import React, { useEffect } from "react";
import { Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";
import { Box } from "@chakra-ui/react";

export const RemoveAccountConfirmEmailSentScreen: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    document.title = "Potwierdzenie wysłania wiadomości - Usunięcie konta";
  }, []);

  return (
    <Layout>
      <Box flex={1} py={{ base: 0, sm: "135px" }}>
        <MessageWithIcon
          image="/ICON_THX-register.svg"
          title="Dziękujemy!"
          message="Na podany email została przesłana wiadomość z linkiem weryfikacyjnym w celu zakończenia procesu usuwania konta. Kliknij w przesłany link, by skutecznie zakończyć proces usuwania konta."
        />
      </Box>
    </Layout>
  );
};
