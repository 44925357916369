import { Text, VStack, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { withModel } from "@lubbezposrednio-frontend/core";
import { ConsentsViewModel } from "./ConsentsViewModel";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { Consent } from "./components/Consent";
import { observer } from "mobx-react-lite";

interface ConsentsViewProps {
  model: ConsentsViewModel;
}

export const ConsentsView: React.FC<ConsentsViewProps> = observer(({ model }) => {
  useEffect(() => {
    document.title = "Zgody";
    model.getConsents();
  }, []);

  useEffect(() => {
    model.isGetConsentsError &&
      model.showGenericErrorToast(
        toast,
        "Wystąpił błąd podczas pobierania informacji o Twoich zgodach, spróbuj ponownie później"
      );
  }, [model.isGetConsentsError]);

  const toast = useToast();

  return (
    <Layout isMenu>
      <Text textStyle="header" color="primary" py={{ base: "41px", sm: "34px" }}>
        Zgody
      </Text>
      <VStack gap={{ base: "21px", sm: "34px" }} pb={{ base: "95px", sm: "80px" }} flex={1}>
        {model.consents.map(consent => (
          <Consent key={consent.id} {...consent} model={model} />
        ))}
      </VStack>
    </Layout>
  );
});

const ConsentsViewWithModel = withModel<ConsentsViewModel>(ConsentsView, ConsentsViewModel);

export default ConsentsViewWithModel;
