import { Button, VStack, Center, Toast, useToast } from '@chakra-ui/react';
import { CustomButton } from '@lubbezposrednio-frontend/commons';
import { CustomInput } from '@lubbezposrednio-frontend/commons/src/Form';
import { sendReport } from '@lubbezposrednio-frontend/core/src/api';
import { FC, useRef, useState } from 'react';
import { VotingViewTile } from '../VotingViewTile';
import ReCAPTCHA from 'react-google-recaptcha';
import { Model } from '@lubbezposrednio-frontend/core';
/*...*/

type ReportPopupProps = { onClose: () => void; Id: string, reportableType: string, model: Model};

const ReportPopup: FC<ReportPopupProps> = ({ onClose, Id, reportableType, model }) => {
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [recaptchaPassed, setRecaptchaPassed] = useState(false);
    const reCaptchaRef = useRef<ReCAPTCHA>(null);
    const toast = useToast();



    const handleSubmit = async () => {
        // toast error if email or reason is shorter than 5 characters or longer than 254 characters
        if (email.length < 5 || email.length > 255 || reason.length < 5 || reason.length > 255) {
            toast({
                status: "error",
                isClosable: true,
                title: "Wprowadź poprawne dane (od 5 do 255 znaków w polu email oraz powód zgłoszenia)"
            });
            return;
        }


        // toast error if reCaptcha is not passed
        if (!recaptchaPassed) {
            toast({
                status: "error",
                isClosable: true,
                title: "Potwierdź, że nie jesteś robotem"
            });
            return;
        }

        try {
            let response = await sendReport(Id, email, reason, reportableType, reCaptchaRef.current?.getValue() as string);
            if (response.status === 200) {
                toast({
                    status: "success",
                    isClosable: true,
                    title: "Pomyślnie wysłano zgłoszenie"
                  });
                setIsSubmitted(true);
                onClose();
            } else {
                model.showGenericErrorToast(toast)
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <VotingViewTile title="Zgłoś naruszenie">
            <form>
                <VStack spacing="5">
                    <CustomInput
                        id="email"
                        value={email}
                        onChange={setEmail}
                        placeholder="E-mail zgłaszającego"
                        required
                    />
                    <CustomInput
                        id="reason"
                        value={reason}
                        onChange={setReason}
                        placeholder="Powód zgłoszenia"
                        required
                    />
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                        ref={reCaptchaRef}
                        onChange={() => {
                            setRecaptchaPassed(true);
                        }}
                        onExpired={() => {
                            setRecaptchaPassed(false);
                            toast({
                                status: "error",
                                isClosable: true,
                                title: "ReCaptcha wygasła, spróbuj ponownie"
                            });
                        }}
                    />            <CustomButton text={"Zgłoś"} onClick={() => handleSubmit()} disabled={!recaptchaPassed || isSubmitted}></CustomButton>
                </VStack>
            </form>
            <Center>
                <Button variant="link" onClick={onClose} mt="5" mb="5" disabled={isSubmitted}>Anuluj</Button>
            </Center>
        </VotingViewTile>
    );
};

export default ReportPopup;