import { Image, Text, VStack, StackProps, TextProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface MessageWithIconProps extends StackProps {
  image: string;
  title: string;
  message: React.ReactNode;
  bottomElement?: ReactNode;
  titleStyle?: TextProps["textStyle"];
  isError?: boolean;
}

export const MessageWithIcon: React.FC<MessageWithIconProps> = ({
  image,
  title,
  message,
  bottomElement,
  titleStyle,
  isError,
  ...props
}) => {
  const color = isError ? "accent" : "primary";
  return (
    <VStack
      bg="background"
      maxW="500px"
      gap="24px"
      borderColor={color}
      borderWidth="2px"
      borderRadius="standard"
      p={{ base: "100px 40px 50px", md: "100px 90px 60px" }}
      position="relative"
      mx={{ base: "32px", md: 0 }}
      mt={{ base: "120px", md: 0 }}
      mb={{ base: "50px", md: 0 }}
      {...props}
    >
      <Image src={image} position="absolute" top="0" left="50%" transform="translate(-50%, -50%)" />
      <Text textStyle={titleStyle ?? "header"} color={color} textAlign="center">
        {title}
      </Text>
      <Text textStyle={{ base: "text", md: "largeText" }} color={color} textAlign="center" fontWeight="bold">
        {message}
      </Text>
      {bottomElement}
    </VStack>
  );
};
