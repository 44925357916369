import { Button, Flex, IconButton } from "@chakra-ui/react";
import { CreatedVotingsListViewModel } from "../../CreatedVotingsListViewModel";
import { RadioFilterButton } from "./RadioFilterButton";
import { observer } from "mobx-react-lite";
import { PUBLICITY_TYPES, DATE_SORTERS, VOTING_STATUSES } from "@lubbezposrednio-frontend/core";
import { SearchFilter } from "./SearchFilter";
import { FilterIcon } from "../../../../assets/FilterIcon";
import { SorterIcon } from "../../../../assets/SorterIcon";
import { VotingsListModel } from "../../../../models/VotingsListModel";
import { CloseIcon } from "@chakra-ui/icons";

const STATUS_FILTERS = [
  { label: "Trwające", value: VOTING_STATUSES.ONGOING },
  { label: "Planowane", value: VOTING_STATUSES.PLANNED },
  { label: "Zakończone", value: VOTING_STATUSES.FINISHED },
  { label: "Wersje robocze", value: VOTING_STATUSES.DRAFT }
];

const PUBLICITY_FILTERS = [
  { label: "Jawne", value: PUBLICITY_TYPES.PUBLIC },
  { label: "Niepubliczne", value: PUBLICITY_TYPES.NON_PUBLIC }
];

const LIST_SORTERS = [
  { label: "Od najstarszych", value: DATE_SORTERS.DATE_ASC },
  { label: "Od najnowszych", value: DATE_SORTERS.DATE_DESC }
];

type VotingsListFiltersProps = {
  model: VotingsListModel & Partial<CreatedVotingsListViewModel>;
  isSearch?: boolean;
  isOrganizerView?: boolean;
  customStatusFilters?: React.ReactNode;
  isCustomFiltersSelected?: boolean;
};

export const VotingsListFilters: React.FC<VotingsListFiltersProps> = observer(
  ({ model, isSearch, isOrganizerView, customStatusFilters, isCustomFiltersSelected }) => {
    const isSorter = !!model.setSorter;
    const isPublicityFIlter = !!model.setPublicityFilter;
    const isRightFilters = isSorter || isPublicityFIlter || isSearch;

    const isFilterSelected = !!(model.statusFilter || model.publicityFilter || model.sorter || isCustomFiltersSelected);

    return (
      <Flex
        gap={{ base: "22px", sm: "16px" }}
        pt={{ base: "28px", sm: "34px" }}
        wrap="wrap-reverse"
        justify="center"
        w={{ base: "260px", sm: "auto" }}
      >
        <Flex gap={{ base: "12px", sm: "16px" }} wrap="wrap" justify="center">
          {STATUS_FILTERS.filter(
            filter =>
              (filter.value !== VOTING_STATUSES.FINISHED && filter.value !== VOTING_STATUSES.DRAFT) || isOrganizerView
          ).map(filter => (
            <Button
              key={filter.value}
              bg={model.statusFilter === filter.value ? "primary" : "background"}
              color={model.statusFilter === filter.value ? "background" : "primary"}
              variant="statusFilter"
              onClick={() => model.changeStatusFilter(model.statusFilter === filter.value ? undefined : filter.value)}
            >
              {filter.label.toUpperCase()}
            </Button>
          ))}
          {customStatusFilters}
        </Flex>
        {isRightFilters && (
          <Flex
            gap={{ base: "auto", sm: "16px" }}
            justifyContent={isSearch ? "space-between" : "space-evenly"}
            w={{ base: "100%", sm: "auto" }}
          >
            {isPublicityFIlter && (
              <RadioFilterButton<PUBLICITY_TYPES>
                getIcon={(fill: string) => <FilterIcon fill={fill} />}
                label="Publicity filter"
                options={PUBLICITY_FILTERS}
                onChange={value => model.setPublicityFilter?.(value, true)}
                value={model.publicityFilter}
              />
            )}
            {isSorter && (
              <RadioFilterButton<DATE_SORTERS>
                label="Sorter"
                getIcon={(fill: string) => <SorterIcon fill={fill} />}
                options={LIST_SORTERS}
                onChange={value => model.setSorter?.(value, true)}
                value={model.sorter}
              />
            )}
            {isSearch && <SearchFilter onChange={() => {}} />}
            {isFilterSelected && (
              <IconButton
                aria-label="Clear filters"
                variant="filter"
                icon={<CloseIcon color="primary" />}
                onClick={() => model.changeStatusFilter()}
              />
            )}
          </Flex>
        )}
      </Flex>
    );
  }
);
