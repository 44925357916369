import { observer } from "mobx-react-lite";
import React from "react";
import { CustomPasswordInput, CustomFormControl, CustomInput } from "@lubbezposrednio-frontend/commons";
import { bindProperty, PASSWORD_TOOLTIP, AuthorizationModel } from "@lubbezposrednio-frontend/core";

interface RegisterFormProps {
  authorization: AuthorizationModel;
}

export const RegisterForm: React.FC<RegisterFormProps> = observer(({ authorization }) => {
  const inputIds = {
    email: "email",
    password: "password",
    confirmPassword: "confirm-password"
  };

  return (
    <>
      <CustomFormControl
        id={inputIds.email}
        isInvalid={authorization.getIsTouched(inputIds.email) && !authorization.isEmailCorrect}
        errorMessage="Podany adres e-mail jest niepoprawny."
        w="300px"
        labelTitle="Email"
      >
        <CustomInput
          autoComplete="email"
          {...bindProperty(authorization, "email")}
          onFocus={() => {
            authorization.setIsTouched(inputIds.email);
          }}
        />
      </CustomFormControl>
      <CustomFormControl
        id={inputIds.password}
        isInvalid={authorization.getIsTouched(inputIds.password) && !authorization.isPasswordCorrect}
        errorMessage={PASSWORD_TOOLTIP}
        tooltipMessage={PASSWORD_TOOLTIP}
        onFocus={() => {
          authorization.setIsTouched(inputIds.password);
        }}
        w="300px"
        labelTitle="Hasło"
      >
        <CustomPasswordInput {...bindProperty(authorization, "password")} />
      </CustomFormControl>
      <CustomFormControl
        id={inputIds.confirmPassword}
        isInvalid={authorization.getIsTouched(inputIds.confirmPassword) && !authorization.arePasswordsTheSame}
        errorMessage="Hasła nie są identyczne"
        onFocus={() => {
          authorization.setIsTouched(inputIds.confirmPassword);
        }}
        w="300px"
        labelTitle="Powtórz hasło"
      >
        <CustomPasswordInput {...bindProperty(authorization, "confirmPassword")} />
      </CustomFormControl>
    </>
  );
});
