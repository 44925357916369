import React, { useMemo } from "react";
import { Box, BoxProps, Text, ThemingProps } from "@chakra-ui/react";
import { convertHtmlToText, NEW_TAB_WARNING, TILE_X_PADDING } from "@lubbezposrednio-frontend/core";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";

type CustomTileProps = BoxProps & {
  title?: string;
  children: React.ReactNode;
  tileWidth: number;
  absoluteChildren?: React.ReactNode;
  titleStyle?: ThemingProps<"Text">;
  containerProps?: BoxProps;
  customTitle?: React.ReactNode;
};

export const CustomTile: React.FC<CustomTileProps> = ({
  title,
  tileWidth,
  children,
  absoluteChildren,
  titleStyle,
  maxWidth,
  containerProps,
  customTitle,
  ...props
}) => {
  const isLink = useMemo(() => title?.includes("<a"), [title]);

  return (
    <Box
      w={`${tileWidth}px`}
      maxW={maxWidth}
      borderTop={0}
      borderWidth="2px"
      borderColor="primary"
      borderRadius="20px 20px 15px 15px"
      bg="background"
      pos="relative"
      h="fit-content"
      {...containerProps}
    >
      {customTitle ?? (
        <CustomTooltip message={isLink ? NEW_TAB_WARNING : ""}>
          <Text variant="tileTitle" {...titleStyle}>
            {convertHtmlToText(title ?? "")}
          </Text>
        </CustomTooltip>
      )}
      <Box
        px={TILE_X_PADDING}
        pt={{ base: "15px", sm: "17px" }}
        pos="relative"
        borderBottomWidth={"2px"}
        borderRadius="standard"
        {...props}
      >
        {children}
      </Box>
      {absoluteChildren}
    </Box>
  );
};
