import { Text, useToast, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomButton, CustomFormControl, CustomPasswordInput, Layout } from "@lubbezposrednio-frontend/commons";
import { PASSWORD_TOOLTIP, bindProperty, bindToCommand, authorization } from "@lubbezposrednio-frontend/core";

interface ResetPasswordViewProps {}

const ResetPasswordView: React.FC<ResetPasswordViewProps> = observer(({}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    document.title = "Reset hasła";
  }, []);

  const inputIds = {
    email: "email",
    password: "password",
    confirmPassword: "confirm-password"
  };

  return (
    <Layout>
      <VStack gap="40px" my="40px">
        <Text textStyle="header" color="primary">
          Reset hasła
        </Text>

        <CustomFormControl
          id={inputIds.password}
          isInvalid={authorization.getIsTouched(inputIds.password) && !authorization.isPasswordCorrect}
          errorMessage={PASSWORD_TOOLTIP}
          tooltipMessage={PASSWORD_TOOLTIP}
          onFocus={() => {
            authorization.setIsTouched(inputIds.password);
          }}
          w="300px"
          labelTitle="Hasło"
        >
          <CustomPasswordInput {...bindProperty(authorization, "password")} />
        </CustomFormControl>
        <CustomFormControl
          id={inputIds.confirmPassword}
          isInvalid={authorization.getIsTouched(inputIds.confirmPassword) && !authorization.arePasswordsTheSame}
          errorMessage="Hasła nie są identyczne"
          onFocus={() => {
            authorization.setIsTouched(inputIds.confirmPassword);
          }}
          w="300px"
          labelTitle="Powtórz hasło"
        >
          <CustomPasswordInput {...bindProperty(authorization, "confirmPassword")} />
        </CustomFormControl>
        <CustomButton
          text="Resetuj hasło"
          {...bindToCommand(authorization.resetPassword(toast, params.get("token") ?? "", navigate))}
          backgroundColor="primary"
        />
      </VStack>
    </Layout>
  );
});

export default ResetPasswordView;
