import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Button, InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import { FC, useState } from "react";
import { CustomInput } from "../CustomInput/CustomInput";

export type CustomPasswordInputProps = Omit<InputProps, "value" | "onChange"> & {
  value: string;
  onChange: (value: string) => void;
};

export const CustomPasswordInput: FC<CustomPasswordInputProps> = ({ value, onChange, placeholder, id }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup>
      <CustomInput
        placeholder={placeholder}
        h="2.75rem"
        rounded="xl"
        id={id}
        value={value}
        onChange={onChange}
        type={showPassword ? "text" : "password"}
      ></CustomInput>
      <InputRightElement h={"full"}>
        <Button
          mr={"4px"}
          rounded={"xl"}
          variant={"ghost"}
          color="primary"
          onClick={() => setShowPassword(showPassword => !showPassword)}
        >
          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
