import { makeObservable, observable, action } from "mobx";
import {
  getVotingOrganizer,
  Model,
  ToastType,
  OrganizerDetail,
  OrganizerDetails
} from "@lubbezposrednio-frontend/core";

export class VotingOrganizerViewModel extends Model {
  organizerData: OrganizerDetail[] = [];
  representativeData: OrganizerDetail[] = [];
  organizationData: OrganizerDetail[] = [];
  isOrganization = false;
  profileImageUrl: string | null = null;
  organizationImageUrl: string | null = null;
  profileAffirmationUrl: string | null = null;
  organizationAffirmationUrl: string | null = null;
  isUserVerified = false;
  isOrganisationVerified = false;

  constructor() {
    super();

    makeObservable(this, {
      organizerData: observable,
      organizationData: observable,
      representativeData: observable,
      isOrganization: observable,
      profileImageUrl: observable,
      organizationImageUrl: observable,
      profileAffirmationUrl: observable,
      organizationAffirmationUrl: observable,
      isUserVerified: observable,
      isOrganisationVerified: observable,
      loadOrganizerData: action.bound
    });
  }

  override async onInit() {}

  async getOrganizerData(id: string, toast: ToastType) {
    try {
      const response = await getVotingOrganizer(id);
      if (response.status === 200) {
        return this.loadOrganizerData(response.data.user);
      }
      this.showGenericErrorToast(toast);
    } catch (err) {
      this.showGenericErrorToast(toast);
    }
  }

  loadOrganizerData(user: OrganizerDetails) {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;

    this.isOrganization = user.is_organization;
    if (user.is_organization) {
      this.representativeData = [
        { id: 0, fieldName: "Imię", value: user.representatives_first_name ?? "" },
        { id: 1, fieldName: "Nazwisko", value: user.representatives_last_name ?? "" },
        { id: 2, fieldName: "Email", value: user.email ?? "" },
        { id: 3, fieldName: "Numer telefonu", value: user.representatives_phone_number ?? "" }
      ];
      this.organizationData = [
        { id: 0, fieldName: "Nazwa", value: user.organization_name ?? "" },
        { id: 1, fieldName: "KRS", value: user.krs ?? "" },
        { id: 2, fieldName: "NIP", value: user.nip ?? "" },
        { id: 3, fieldName: "REGON", value: user.regon ?? "" },
        { id: 4, fieldName: "Adres", value: user.address ?? "" },
        { id: 5, fieldName: "Miasto", value: user.city ?? "" },
        { id: 6, fieldName: "Numer telefonu", value: user.phone_number ?? "" },
        { id: 7, fieldName: "Kod pocztowy", value: user.zip_code ?? "" }
      ];
      this.profileImageUrl = user.representatives_avatar_url ? `${baseUrl}${user.representatives_avatar_url}` : null;
      this.profileAffirmationUrl = user.representatives_affirmation_url
        ? `${baseUrl}${user.representatives_affirmation_url}`
        : null;
      this.organizationImageUrl = user.avatar_url ? `${baseUrl}${user.avatar_url}` : null;
      this.organizationAffirmationUrl = user.affirmation_url ? `${baseUrl}${user.affirmation_url}` : null;
      this.isOrganisationVerified = !!user.verified;
      this.isUserVerified = !!user.representatives_verified;
    } else {
      this.organizerData = [
        { id: 0, fieldName: "Imię", value: user.first_name ?? "" },
        { id: 1, fieldName: "Nazwisko", value: user.last_name ?? "" },
        { id: 2, fieldName: "Email", value: user.email ?? "" },
        { id: 3, fieldName: "Numer telefonu", value: user.phone_number ?? "" },
        { id: 4, fieldName: 'Id', value: user.id ?? ""}
      ];
      this.profileImageUrl = user.avatar_url ? `${baseUrl}${user.avatar_url}` : null;
      this.profileAffirmationUrl = user.affirmation_url ? `${baseUrl}${user.affirmation_url}` : null;
      this.isUserVerified = !!user.verified;
    }
  }
}
