import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { handleKeyboardNavigation } from "@lubbezposrednio-frontend/core";

interface CollapseHeaderProps {
  toggleIsOpen: () => void;
  title: string;
}

export const CollapseHeader: React.FC<CollapseHeaderProps> = ({ toggleIsOpen, title }) => {
  return (
    <Flex
      px={{ base: "16px", md: "40px" }}
      py="20px"
      bg="primary"
      direction={{ base: "column", md: "row" }}
      gap="12px"
      align="center"
      borderRadius="standard"
      cursor="pointer"
      boxShadow="standard"
      w="100%"
      justify="space-between"
      _focus={{ outlineColor: "accent" }}
      tabIndex={0}
      data-cy="collapse-header"
      {...handleKeyboardNavigation(toggleIsOpen)}
    >
      <Text
        fontSize={{ base: "22px", md: "28px" }}
        lineHeight={{ base: "27px", md: "34px" }}
        fontWeight="bold"
        color="background"
      >
        {title}
      </Text>
      <Image w="36px" src="/arrow-dropdown.svg" alt="arrow-dropdown" />
    </Flex>
  );
};
