import { Image } from "@chakra-ui/react";
import React, { useRef } from "react";
import { CustomTooltip } from "../../CustomTooltip/CustomTooltip";
import { NEW_TAB_WARNING } from "@lubbezposrednio-frontend/core";

interface SocialMediaLinkProps {
  icon: string;
  url: string;
}

export const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({ icon, url }) => {
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <CustomTooltip message={NEW_TAB_WARNING}>
      <a href={url} target="_blank" rel="noreferrer" tabIndex={-1} ref={ref}>
        <Image
          src={icon}
          onKeyDown={e => (e.key === "Enter" || e.key === "Return") && ref.current?.click()}
          tabIndex={0}
          _focus={{ outlineColor: "accent" }}
        />
      </a>
    </CustomTooltip>
  );
};
