import { action, computed, makeObservable, observable, runInAction } from "mobx";
import {
  LandingPageDataType,
  LandingPageDraftDataType,
  Model,
  addLinksToText,
  getPlainTextFromTextWithLinks
} from "@lubbezposrednio-frontend/core";

export const LANDING_PAGE_LIMITS = {
  TITLE: 80,
  DESCRIPTION: 400,
  ADDITIONAL_DESCRIPTION: 1000
};

export class LandingPageTextsModel extends Model {
  pageTitle = "";
  pageTitleError = "";
  pageDescription = "";
  pageDescriptionWithLinks = "";
  pageDescriptionError = "";
  additionalDescriptions: { content: string; contentWithLinks: string; id: number }[] = [];

  constructor() {
    super();

    makeObservable(this, {
      pageTitle: observable,
      pageTitleError: observable,
      pageDescription: observable,
      pageDescriptionWithLinks: observable,
      pageDescriptionError: observable,
      additionalDescriptions: observable.ref,

      changePageTitle: action.bound,
      changePageDescription: action.bound,
      addAdditionalDescription: action.bound,
      removeAdditionalDescription: action.bound,
      changeAdditionalDescription: action.bound,
      removeEmptyAdditionalDescriptions: action.bound,

      setInitialData: action.bound,

      clearData: action.bound,
      clearErrors: action.bound,
      isError: computed
    });
  }

  setInitialData(data: LandingPageDataType["landing_page"] | LandingPageDraftDataType["landing_page"]) {
    this.pageTitle = data.page_title ?? "";
    this.pageDescription = getPlainTextFromTextWithLinks(data.page_description ?? "");
    this.pageDescriptionWithLinks = data.page_description ?? "";
    this.additionalDescriptions =
      data.additional_descriptions?.map((description, index) => ({
        id: index + 1,
        contentWithLinks: description,
        content: getPlainTextFromTextWithLinks(description)
      })) ?? [];
  }

  private reduceTextWithLinksToLimit(text: string, limit: number): { text: string; textWithLinks: string } {
    const textWithLinks = addLinksToText(text);
    if (textWithLinks.trim().length > limit) {
      return this.reduceTextWithLinksToLimit(text.slice(0, -1), limit);
    }

    return { text, textWithLinks };
  }

  public changePageTitle(pageTitle: string) {
    this.pageTitle = pageTitle;
    this.pageTitleError && this.validateForm();
  }

  public changePageDescription(pageDescription: string) {
    const { text, textWithLinks } = this.reduceTextWithLinksToLimit(pageDescription, LANDING_PAGE_LIMITS.DESCRIPTION);
    this.pageDescription = text;
    this.pageDescriptionWithLinks = textWithLinks;

    this.pageDescriptionError && this.validateForm();
  }

  public addAdditionalDescription() {
    const id = (this.additionalDescriptions[this.additionalDescriptions.length - 1]?.id ?? 0) + 1;
    this.additionalDescriptions = [...this.additionalDescriptions, { id, content: "", contentWithLinks: "" }];
  }

  public removeAdditionalDescription(id: number) {
    this.additionalDescriptions = this.additionalDescriptions.filter(description => description.id !== id);
  }

  public changeAdditionalDescription(id: number, content: string) {
    this.additionalDescriptions = this.additionalDescriptions.map(description => {
      if (description.id === id) {
        const { text, textWithLinks } = this.reduceTextWithLinksToLimit(
          content,
          LANDING_PAGE_LIMITS.ADDITIONAL_DESCRIPTION
        );

        return { ...description, content: text, contentWithLinks: textWithLinks };
      }
      return description;
    });
  }

  public removeEmptyAdditionalDescriptions() {
    this.additionalDescriptions = this.additionalDescriptions.filter(description => !!description.content);
  }

  validateForm() {
    runInAction(() => {
      this.pageTitleError = !this.pageTitle ? "Tytuł nie może być pusty" : "";
      this.pageDescriptionError = !this.pageDescription ? "Opis nie może być pusty" : "";
    });
  }

  clearErrors() {
    this.pageTitleError = "";
    this.pageDescriptionError = "";
  }

  clearData() {
    this.pageTitle = "";
    this.pageDescription = "";
    this.pageDescriptionWithLinks = "";
    this.additionalDescriptions = [];
    this.clearErrors();
  }

  get isError(): boolean {
    return !!(this.pageTitleError || this.pageDescriptionError);
  }

  override async onInit() {}
}
