import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ProfileViewSectionContainerProps {
  children: ReactNode;
  isHalfWidth?: boolean;
}

export const ProfileViewSectionContainer: React.FC<ProfileViewSectionContainerProps> = ({ children, isHalfWidth }) => {
  return (
    <Box
      p="20px"
      borderColor="primary"
      borderWidth="2px"
      borderRadius="standard"
      w={{ base: "100%", md: isHalfWidth ? "50%" : "100%" }}
      alignSelf={isHalfWidth ? "flex-start" : undefined}
      bg="background"
    >
      {children}
    </Box>
  );
};
