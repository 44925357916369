import { action, makeObservable, observable } from "mobx";
import {
  DATE_SORTERS,
  LandingPagesListItem,
  getLandingPages,
  getFormattedDate,
  ToastType,
  Command,
  LP_STATUSES,
  editLandingPage,
  ListModel,
  deleteLandingPage
} from "@lubbezposrednio-frontend/core";
import { AxiosResponse } from "axios";

export class CreatedLandingPagesListViewModel extends ListModel<
  { sorter?: DATE_SORTERS; statusFilter?: LP_STATUSES[]; pageTitleFilter: string },
  LandingPagesListItem
> {
  sorter?: DATE_SORTERS;
  statusFilter?: LP_STATUSES[];
  pageTitleFilter = "";

  constructor() {
    super();

    makeObservable(this, {
      sorter: observable,
      setSorter: action.bound,
      statusFilter: observable,
      changeStatusFilter: action.bound,
      pageTitleFilter: observable,
      changePageTitleFilter: action.bound,
      cacheFilters: action.bound,
      restoreCachedFilters: action.bound
    });
  }

  setSorter(sorter?: DATE_SORTERS, apply?: boolean) {
    apply && this.cacheFilters();
    this.sorter = sorter;
    apply && this.applyFilter();
  }

  changeStatusFilter(statusFilter?: LP_STATUSES[], apply?: boolean) {
    apply && this.cacheFilters();
    this.statusFilter = statusFilter;
    apply && this.applyFilter();
  }

  changePageTitleFilter(pageTitleFilter?: string, apply?: boolean) {
    apply && this.cacheFilters();
    this.pageTitleFilter = pageTitleFilter ?? "";
    apply && this.applyFilter();
  }

  async getListItems(page: number): Promise<{ list?: LandingPagesListItem[]; pages?: number; status?: number }> {
    try {
      const response = await getLandingPages({
        per_page: 4,
        page_number: page,
        sort: this.sorter,
        status: this.statusFilter,
        page_title: this.pageTitleFilter || undefined
      });

      const list = response?.data?.landing_pages.map(landing_page => ({
        id: landing_page.id,
        pageTitle: landing_page.page_title ?? "",
        isActive: landing_page.status === LP_STATUSES.ONGOING,
        startDate: landing_page.start_date && getFormattedDate(new Date(landing_page.start_date)),
        endDate: landing_page.end_date && getFormattedDate(new Date(landing_page.end_date)),
        leadUrl: landing_page.spreadsheet_url ?? undefined,
        status: landing_page.status
      }));

      return { list, pages: response?.data?.meta.total_pages, status: response?.status };
    } catch (err) {
      throw err;
    }
  }

  clearFilters() {
    this.cacheFilters();
    this.setSorter();
    this.changeStatusFilter();
    this.changePageTitleFilter();
    this.applyFilter();
  }

  override cacheFilters(): void {
    if (!this.fetchTimeout) {
      this.cacheCommonFilters();
      this.cachedFilters.statusFilter = this.cachedFilters.statusFilter ?? this.statusFilter;
      this.cachedFilters.sorter = this.cachedFilters.sorter ?? this.sorter;
      this.cachedFilters.pageTitleFilter = this.cachedFilters.pageTitleFilter ?? this.pageTitleFilter;
    }
  }

  override restoreCachedFilters() {
    this.restoreCommonFilters();
    this.statusFilter = this.cachedFilters.statusFilter ?? this.statusFilter;
    this.sorter = this.cachedFilters.sorter ?? this.sorter;
    this.pageTitleFilter = this.cachedFilters.pageTitleFilter ?? this.pageTitleFilter;
  }

  private async handleApiAction(
    cb: () => Promise<AxiosResponse | undefined>,
    okStatus: number,
    successMessage: string,
    toast: ToastType,
    closeModal: () => void
  ) {
    try {
      const response = await cb();

      if (response?.status === okStatus) {
        toast({
          status: "success",
          isClosable: true,
          title: successMessage
        });
        this.fetchList(this.currPage);
        closeModal();
      } else {
        this.showGenericErrorToast(toast);
        closeModal();
      }
    } catch (err) {
      this.showGenericErrorToast(toast);
      console.log(err);
      closeModal();
    }
  }

  private handleDelete(id: string, toast: ToastType, closeModal: () => void, successMessage: string) {
    return new Command(async () =>
      this.handleApiAction(() => deleteLandingPage(id), 204, successMessage, toast, closeModal)
    );
  }

  public handleDraftDelete(id: string, toast: ToastType, closeModal: () => void) {
    return this.handleDelete(
      id,
      toast,
      closeModal,
      "Wersja robocza strony zapisu na głosowanie została pomyślnie usunięta"
    );
  }

  public handleEndPublication(id: string, toast: ToastType, closeModal: () => void) {
    return new Command(async () =>
      this.handleApiAction(
        () => editLandingPage(id, { end_date: new Date().toISOString() }),
        200,
        "Publikacja została zakończona",
        toast,
        closeModal
      )
    );
  }

  override async onInit() {}
}
