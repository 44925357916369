import React, { useEffect } from "react";
import { Text, VStack } from "@chakra-ui/react";
import { CustomButton, Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";
import { useNavigate } from "react-router-dom";

export const VotingConfirmEmailSentScreen: React.FC = () => {
  useEffect(() => {
    document.title = "Potwierdzenie wysłania wiadomości - Głosowanie";
  }, []);

  const navigate = useNavigate();

  return (
    <Layout>
      <VStack flex={1} py={{ base: 0, sm: "135px" }} mb={{ base: "140px", sm: 0 }}>
        <MessageWithIcon
          image="/ICON_voting.svg"
          title="Dziękujemy!"
          message={
            <>
              <Text>Na podany adres e-mail została wysłana wiadomość dotycząca głosowania.</Text>
              <br />
              <Text>Prosimy postępować według wskazówek z wiadomości.</Text>
            </>
          }
          p={{ base: "100px 40px 50px", md: "100px 27px 80px" }}
        />
        <CustomButton
          text="Powrót"
          onClick={() => navigate("/aktywne-glosowania")}
          mt={{ base: "-20px", sm: "20px" }}
        />
      </VStack>
    </Layout>
  );
};
