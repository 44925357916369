import React, { useEffect } from "react";
import { Text, VStack } from "@chakra-ui/react";
import { CustomButton, Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFormattedDate } from "@lubbezposrednio-frontend/core";

export const CreateLandingConfirmationScreen: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    document.title = "Potwierdzenie utworzenia strony zapisu na głosowanie";
  }, []);

  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <Layout>
      <VStack flex={1} py={{ base: 0, sm: "135px" }} mb={{ base: "140px", sm: 0 }}>
        <MessageWithIcon
          image="/ICON_voting.svg"
          title="Zmiany zostały zapisane"
          maxW="95vw"
          w="500px"
          message={
            <>
              <Text display="inline">Twoja strona zapisu na głosowanie będzie widoczna od </Text>
              <Text display="inline" whiteSpace="nowrap">
                {getFormattedDate(state?.startDate)}
              </Text>
              <Text display="inline"> do </Text>
              <Text display="inline" whiteSpace="nowrap">
                {getFormattedDate(state?.endDate)}
              </Text>
              <Text display="inline"> pod adresem </Text>
              <Link style={{ textDecoration: "underline" }} to={state?.url}>
                {state?.url}
              </Link>
            </>
          }
          p={{ base: "100px 40px 50px", md: "100px 27px 80px" }}
        />
        <CustomButton
          text="Lista zapisów na głosowania"
          onClick={() => navigate("/organizator/lista-zapisow-na-glosowanie")}
          mt={{ base: "-20px", sm: "20px" }}
          w="auto"
        />
      </VStack>
    </Layout>
  );
};
