import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { NEW_TAB_WARNING, getAnswerChar, convertHtmlToText } from "@lubbezposrednio-frontend/core";
import { CreateVotingViewModel } from "../../CreateVotingView/CreateVotingViewModel";
import { observer } from "mobx-react-lite";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";

export const VotingQuestionsSummary: React.FC<{
  model: CreateVotingViewModel;
}> = observer(({ model }) => {
  return (
    <VStack align={{ base: "center", sm: "start" }} spacing={0}>
      <Text variant="votingSummaryLabel">Pytania:</Text>
      {model.votingQuestionsModel.questionsArray.map((question, index, questionsArr) => {
        const isLink = question.transformedQuestion?.includes("<a");

        return (
          <React.Fragment key={question.id}>
            {question.transformedQuestion && (
              <CustomTooltip message={isLink ? NEW_TAB_WARNING : ""}>
                <Box
                  textAlign={{ base: "center", sm: "left" }}
                  textStyle="largeText"
                  fontWeight="bold"
                  color="accent"
                  wordBreak="break-word"
                >
                  {convertHtmlToText(question.transformedQuestion)}
                </Box>
              </CustomTooltip>
            )}
            {question.answers.map((answer, index) => (
              <Text key={`${question.id}.${answer.id}`} variant="votingSummaryValue">
                {getAnswerChar(index)}. {answer.content}
              </Text>
            ))}
            {index !== questionsArr.length - 1 && <br />}
          </React.Fragment>
        );
      })}
    </VStack>
  );
});
