import { Flex, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { Layout } from "@lubbezposrednio-frontend/commons";
import {
  LAYOUT_CONTENT_WIDTHS,
  LandingPageDataType,
  LandingPageDraftDataType,
  withModel
} from "@lubbezposrednio-frontend/core";

import { LandingPageViewModel } from "./LandingPageViewModel";
import { OrganizerData } from "./components/OrganizerData";
import { VotingDescription } from "./components/VotingDescription";
import { LandingPageQR } from "./components/LandingPageQR";
import { SignUpForVotingForm } from "./components/SignUpForVotingForm";
import { LandingPageDescriptions } from "./components/LandingPageDescriptions";

interface LandingPageViewProps {
  model: LandingPageViewModel;
}

const LandingPageView: React.FC<LandingPageViewProps> = observer(({ model }) => {
  const landingPageData = useLoaderData() as
    | (LandingPageDataType | LandingPageDraftDataType)["landing_page"]
    | undefined;

  useEffect(() => {
    document.title = "Strona zapisu na głosowanie";
    landingPageData && model.getInitialData(landingPageData);
  }, []);

  return (
    <Layout>
      <VStack
        pt={{ base: "76px", md: "80px" }}
        pb={{ base: "60px", sm: "64px" }}
        w={{ base: "100vw", ...LAYOUT_CONTENT_WIDTHS }}
        gap={0}
      >
        <Flex w="100%" pb={{ base: "28px", sm: "64px" }} flexDir={{ base: "column", md: "row" }} alignItems="start">
          <VStack
            flex={1}
            w={{ base: "100%", sm: "auto" }}
            align="start"
            gap={0}
            pb={{ base: "60px", md: 0 }}
            px={{ base: "37px", md: 0 }}
          >
            <OrganizerData model={model} />
            <VotingDescription model={model} />
            <LandingPageQR model={model} />
          </VStack>
          <SignUpForVotingForm model={model} />
        </Flex>
        <LandingPageDescriptions model={model} />
      </VStack>
    </Layout>
  );
});

const LandingPageViewWithModel = withModel<LandingPageViewModel>(LandingPageView, LandingPageViewModel);

export default LandingPageViewWithModel;
