import { Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { InternalLink } from "../../InternalLink/InternalLink";

interface InternalLinksSectionProps {
  redirectBaseUrl?: string;
}

const getFirstLineLinks = (redirectBaseUrl?: string) => [
  { text: "Regulamin", to: `${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/terms_of_service` },
  { text: "Polityka prywatności", to: `${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/privacy_policy` },
  { text: "Deklaracja dostępności", to: (redirectBaseUrl ?? "") + "/deklaracja-dostepnosci" }
];
const getSecondLineLinks = (redirectBaseUrl?: string) => [
  { text: "O platformie decyduj.lubbezposrednio.pl", to: (redirectBaseUrl ?? "") + "/o-nas" },
  { text: "Jak stworzyć głosowanie?", to: (redirectBaseUrl ?? "") + "/jak-stworzyc-glosowanie" },
  { text: "Jak zagłosować?", to: (redirectBaseUrl ?? "") + "/jak-zaglosowac" },
  { text: "FAQ", to: (redirectBaseUrl ?? "") + "/faq" }
];
const THIRD_LINE_LINKS = [
  {
    text: "O LubBezpośrednio.pl – Instytut Demokracji Bezpośredniej",
    to: "https://lubbezposrednio.pl/nasza-wiarygnodnosc/o-kampanii/"
  },
  { text: "Organizator kampanii", to: "https://lubbezposrednio.pl/nasza-wiarygnodnosc/organizator-kampanii/" },
  { text: "Rejestr umów", to: "https://lubbezposrednio.pl/nasza-wiarygnodnosc/rejestr-umow/" },
  { text: "Finansowanie", to: "https://lubbezposrednio.pl/nasza-wiarygnodnosc/finansowanie/" },
  { text: "Strona LubBezpośrednio.pl ", to: "https://lubbezposrednio.pl/" }
];

const getLinks = (redirectBaseUrl?: string) => [
  { id: 1, items: getFirstLineLinks(redirectBaseUrl) },
  { id: 2, items: getSecondLineLinks(redirectBaseUrl) },
  { id: 3, items: THIRD_LINE_LINKS }
];

export const InternalLinksSection: React.FC<InternalLinksSectionProps> = ({ redirectBaseUrl }) => {
  return (
    <VStack>
      {getLinks(redirectBaseUrl).map(linksLine => (
        <Flex key={linksLine.id} columnGap="8px" wrap="wrap" justify={{ base: "center", md: "end" }}>
          {linksLine.items.map((linkItem, index) => (
            <React.Fragment key={linkItem.to}>
              {!!index && (
                <Text textStyle="text" fontWeight="bold" color="primary">
                  |
                </Text>
              )}
              <InternalLink
                openInNewTab
                to={linkItem.to}
                text={linkItem.text}
                textAlign={{ base: "center", sm: "end" }}
              />
            </React.Fragment>
          ))}
        </Flex>
      ))}
    </VStack>
  );
};
