import React, { useEffect } from "react";
import { Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";
import { Box } from "@chakra-ui/react";

interface RegistrationConfirmEmailSentScreenProps {}

export const RegistrationConfirmEmailSentScreen: React.FC<RegistrationConfirmEmailSentScreenProps> = ({}) => {
  useEffect(() => {
    document.title = "Potwierdzenie wysłania wiadomości - Rejestracja";
  }, []);

  return (
    <Layout>
      <Box flex={1} py={{ base: 0, sm: "135px" }}>
        <MessageWithIcon
          image="/ICON_THX-register.svg"
          title="Dziękujemy!"
          message="Na podany email została przesłana wiadomość z linkiem weryfikacyjnym w celu zakończenia procesu rejestracji. Kliknij w przesłany link, by skutecznie zakończyć proces rejestracji."
        />
      </Box>
    </Layout>
  );
};
