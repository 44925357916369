import { Box, Flex, Text } from "@chakra-ui/layout";
import { Switch } from "@chakra-ui/switch";
import React from "react";
import { ConsentType } from "@lubbezposrednio-frontend/core";
import { ConsentsViewModel } from "../ConsentsViewModel";
import { CustomLoader } from "@lubbezposrednio-frontend/commons";
import { observer } from "mobx-react-lite";
import { useToast } from "@chakra-ui/toast";

interface ConsentProps extends ConsentType {
  model: ConsentsViewModel;
}

export const Consent: React.FC<ConsentProps> = observer(({ title, name, content, isChecked, model }) => {
  const toast = useToast();

  return (
    <Box
      bg="background"
      w="812px"
      borderWidth="2px"
      borderColor="primary"
      borderRadius="standard"
      pb="6px"
      borderTop={0}
      maxW="75vw"
    >
      <Flex
        pl="22px"
        pr={{ base: "21px", sm: "44px" }}
        py="18px"
        bg="primary"
        justify="space-between"
        borderRadius="standard"
      >
        <Text
          color="background"
          textStyle="largeText"
          fontSize={{ base: "16px", sm: "18px" }}
          fontWeight="bold"
          flexWrap="wrap"
        >
          {title}
        </Text>
        {model.isLoading ? (
          <CustomLoader size="sm" color="background" />
        ) : (
          <Switch
            isChecked={isChecked}
            onChange={e => model.changeConsents(name, e.target.checked, toast)}
            variant="consent"
          />
        )}
      </Flex>
      <Text
        pl="22px"
        pt={{ base: "19px", sm: "16px" }}
        pb={{ base: "9px", sm: "16px" }}
        pr="29px"
        textStyle="text"
        fontWeight="bold"
        color="primary"
      >
        {content}
      </Text>
    </Box>
  );
});
