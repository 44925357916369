import { TILE_TITLE_STYLES, TILE_X_PADDING } from "@lubbezposrednio-frontend/core";

const VOTING_SUMMARY_TEXT = {
  textStyle: "largeText",
  fontWeight: "bold",
  textAlign: { base: "center", sm: "left" },
  wordBreak: "break-word"
};

export const textStyles = {
  variants: {
    smallTitle: {
      textStyle: "text",
      color: "primary",
      fontWeight: "bold"
    },
    error: {
      color: "error",
      textStyle: "text",
      fontWeight: "bold",
      pb: "10px"
    },
    tileTitle: TILE_TITLE_STYLES,
    createVotingTileTitle: {
      ...TILE_TITLE_STYLES,
      fontSize: { base: "16px", sm: "18px" },
      px: "22px"
    },
    createdVotingsListTileTitle: {
      ...TILE_TITLE_STYLES,
      px: { base: 0, sm: TILE_X_PADDING },
      textAlign: { base: "center", sm: "left" }
    },
    votingViewTileTitle: {
      ...TILE_TITLE_STYLES,
      px: TILE_X_PADDING,
      textAlign: "left"
    },
    votingSummaryLabel: { ...VOTING_SUMMARY_TEXT, color: "primary" },
    votingSummaryValue: { ...VOTING_SUMMARY_TEXT, color: "accent" },
    votersSummary: {
      ...VOTING_SUMMARY_TEXT,
      color: "accent",
      display: "inline-block",
      whiteSpace: "normal"
    },
    videoTile: {
      ...TILE_TITLE_STYLES,
      textAlign: "center",
      fontSize: "28px",
      lineHeight: "34px",
      px: "8px"
    }
  }
};
