import { Flex, Button } from "@chakra-ui/react";
import { CustomFileUploader } from "../Form/index";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import { NEW_TAB_WARNING } from "../../../node_modules/@lubbezposrednio-frontend/core/index";

const MOBILE_WIDTH = "206px";

type CustomDocumentAddProps = {
  onChange: (file?: File) => void;
  value?: File;
  placeholder: string;
  buttonLabel: string;
  previewUrl?: string;
  isDisabled?: boolean;
  previewUrlName?: string;
};

export const CustomDocumentAdd = ({
  onChange,
  value,
  placeholder,
  buttonLabel,
  previewUrl,
  isDisabled,
  previewUrlName
}: CustomDocumentAddProps) => {
  const url = value ? URL.createObjectURL(value) : previewUrl;
  return (
    <Flex w="100%" flexWrap={{ base: "wrap", sm: "initial" }} justify="center" rowGap="5px" columnGap="22px">
      <CustomFileUploader
        placeholder={placeholder}
        onChange={onChange}
        fileName={value?.name ?? previewUrlName}
        onCancel={onChange}
        accept={["pdf"]}
        mobileWidth={MOBILE_WIDTH}
        isDisabled={isDisabled}
      />
      <Flex w={{ base: MOBILE_WIDTH, sm: "50%" }}>
        <CustomTooltip message={url ? NEW_TAB_WARNING : ""}>
          <Button
            variant="regular"
            h="48px"
            px="22px"
            fontSize="14px"
            lineHeight="20px"
            fontWeight="bold"
            w={{ base: MOBILE_WIDTH, sm: "auto" }}
            onClick={() => url && window.open(url)}
            whiteSpace={"normal"}
          >
            {buttonLabel}
            {" >>"}
          </Button>
        </CustomTooltip>
      </Flex>
    </Flex>
  );
};
