export const SorterIcon: React.FC<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.501"
      height="20.243"
      viewBox="0 0 27.501 20.243"
    >
      <g
        id="Group_21"
        data-name="Group 21"
        transform="translate(-366.75 -14.878)"
      >
        <path
          id="Icon_ionic-ios-arrow-round-up"
          data-name="Icon ionic-ios-arrow-round-up"
          d="M24.49,15.216a.919.919,0,0,1-1.294.007l-4.282-4.268V27.218a.914.914,0,0,1-1.828,0V10.955L12.8,15.23a.925.925,0,0,1-1.294-.007.91.91,0,0,1,.007-1.287l5.836-5.794h0a1.026,1.026,0,0,1,.288-.19.872.872,0,0,1,.352-.07.916.916,0,0,1,.64.26l5.836,5.794A.9.9,0,0,1,24.49,15.216Z"
          transform="translate(355.502 6.997)"
          fill={fill}
        />
        <path
          id="Icon_ionic-ios-arrow-round-up-2"
          data-name="Icon ionic-ios-arrow-round-up"
          d="M24.49,15.216a.919.919,0,0,1-1.294.007l-4.282-4.268V27.218a.914.914,0,0,1-1.828,0V10.955L12.8,15.23a.925.925,0,0,1-1.294-.007.91.91,0,0,1,.007-1.287l5.836-5.794h0a1.026,1.026,0,0,1,.288-.19.872.872,0,0,1,.352-.07.916.916,0,0,1,.64.26l5.836,5.794A.9.9,0,0,1,24.49,15.216Z"
          transform="translate(405.498 43.003) rotate(180)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
