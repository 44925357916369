import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { Model } from "./Model";
import { ToastType } from "../types";
import { DEFAULT_DEBOUNCE_TIME } from "../utils";

type CachedFiltersType<FilterType> = FilterType & {
  page?: number;
};

export abstract class ListModel<FilterType = {}, ListItemType = {}> extends Model {
  list: Map<number, ListItemType[]> = new Map();
  listError?: string;
  isListLoading = false;

  currPage = 1;
  totalPages = 0;

  fetchTimeout?: NodeJS.Timeout;
  cachedFilters: CachedFiltersType<FilterType> = {} as CachedFiltersType<FilterType>;

  constructor() {
    super();

    makeObservable(this, {
      list: observable,
      listError: observable,
      setListError: action.bound,
      currPage: observable,
      isListLoading: observable,
      totalPages: observable,
      applyFilter: action.bound,
      currentPageItems: computed,
      cachedFilters: observable,
      cacheCommonFilters: action.bound,
      restoreCommonFilters: action.bound
    });
  }

  setListError(error?: string) {
    this.listError = error;
  }

  async applyFilter() {
    this.currPage = 1;
    this.fetchList(this.currPage);
  }

  cacheFilters(): void {
    if (!this.fetchTimeout) {
      this.cacheCommonFilters();
    }
  }

  cacheCommonFilters() {
    this.cachedFilters.page = this.cachedFilters.page ?? this.currPage;
  }

  restoreCachedFilters() {
    this.restoreCommonFilters();
  }

  restoreCommonFilters() {
    this.currPage = this.cachedFilters.page ?? this.currPage;
  }

  changePage(page: number, toast: ToastType): void {
    this.cacheFilters();

    runInAction(() => {
      this.currPage = page;
    });

    this.fetchList(page, (isSuccess: boolean) => {
      if (!isSuccess) {
        toast({
          description: "Wystąpił błąd podczas próby pobrania listy głosowań",
          status: "error"
        });
      }
    });
  }

  abstract getListItems(page: number): Promise<{ list?: ListItemType[]; pages?: number; status?: number }>;

  private async debounceFetch(page: number, afterAction?: (isSuccess: boolean) => void) {
    try {
      const { list, pages, status } = await this.getListItems(page);

      runInAction(() => {
        this.list.set(page, list ?? []);
        this.totalPages = pages ?? 1;
      });

      this.setListError();
      afterAction?.(status === 200);
    } catch (err) {
      this.setListError((err as Error).message ?? "");
      afterAction?.(false);
      this.restoreCachedFilters();
    } finally {
      runInAction(() => {
        this.isListLoading = false;
        this.cachedFilters = {} as CachedFiltersType<FilterType>;
        this.fetchTimeout = undefined;
      });
    }
  }

  fetchList(page: number, afterAction?: (isSuccess: boolean) => void): void {
    runInAction(() => {
      this.isListLoading = true;
    });

    clearTimeout(this.fetchTimeout);

    const timeoutId = setTimeout(() => {
      this.debounceFetch(page, afterAction);
    }, DEFAULT_DEBOUNCE_TIME);

    this.fetchTimeout = timeoutId;
  }

  get currentPageItems(): ListItemType[] {
    return this.list.get(this.currPage) ?? [];
  }
}
