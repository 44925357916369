import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { CreateVotingViewModel } from "../../CreateVotingView/CreateVotingViewModel";
import { observer } from "mobx-react-lite";
import { VotingDataModel } from "../../CreateVotingView/components/VotingData/VotingDataModel";
import { getDateObject } from "@lubbezposrednio-frontend/core";

export const VotingDates: React.FC<{ model: CreateVotingViewModel }> = ({ model }) => {
  return (
    <VStack align={{ base: "center", sm: "start" }} gap={0} maxW="100%">
      <Text variant="votingSummaryLabel" textAlign={{ base: "center", sm: "left" }}>
        Czas trwania głosowania:
      </Text>

      <Flex flexWrap="wrap" justify={{ base: "center", sm: "start" }}>
        <Box my="auto" textAlign="center">
          <Text variant="votingSummaryLabel" display="inline-block">
            od&nbsp;
          </Text>
          <VotingDate model={model.votingDataModel} isStart={true} />
        </Box>
        <Box my="auto" textAlign="center">
          <Text variant="votingSummaryLabel" display="inline-block">
            &nbsp;do&nbsp;
          </Text>
          <VotingDate model={model.votingDataModel} isStart={false} />
        </Box>
      </Flex>
    </VStack>
  );
};

type VotingDateProps = {
  model: VotingDataModel;
  isStart: boolean;
};

const VotingDate: React.FC<VotingDateProps> = observer(({ model, isStart }) => {
  const dateObject = useMemo(
    () => getDateObject(isStart ? model.startVotingDate : model.endVotingDate),
    [JSON.stringify(model.startVotingDate), JSON.stringify(model.startVotingDate), isStart]
  );
  return (
    <>
      <Text variant="votingSummaryValue" display="inline-block">
        {dateObject.toLocaleDateString("pl-PL", {
          day: "numeric",
          month: "long",
          year: "numeric"
        })}{" "}
        r.,
      </Text>
      <Text variant="votingSummaryValue" display="inline-block">
        godz.&nbsp;
        {dateObject.toLocaleTimeString("pl-PL", {
          hour: "2-digit",
          minute: "2-digit"
        })}
      </Text>
    </>
  );
});
