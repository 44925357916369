import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { CheckboxesSection } from "./components/CheckboxesSection";
import { RadioButtonsSection } from "./components/RadioButtonsSection";
import { RegisterForm } from "./components/RegisterForm";
import { AuthViewWrapper } from "../../hocs/AuthViewWrapper";
import { authorization } from "@lubbezposrednio-frontend/core";

interface RegisterViewProps {}

export const RegisterView: React.FC<RegisterViewProps> = observer(({}) => {
  useEffect(() => {
    document.title = "Rejestracja";
    authorization.clearInputData();
  }, []);

  return (
    <AuthViewWrapper authorization={authorization} buttonText="Zarejestruj się" submitAction={authorization.register}>
      <RegisterForm authorization={authorization} />
      <RadioButtonsSection authorization={authorization} />
      <CheckboxesSection authorization={authorization} />
    </AuthViewWrapper>
  );
});
