import { Text, VStack } from "@chakra-ui/react";
import { CreateVotingTile } from "../CreateVotingTile";
import { CREATE_VOTING_ERR_KEYS, CreateVotingViewModel } from "../../CreateVotingViewModel";
import { observer } from "mobx-react-lite";
import { CustomDocumentAdd } from "@lubbezposrednio-frontend/commons";

export const VotingDrafts: React.FC<{ model: CreateVotingViewModel }> = observer(({ model }) => {
  const {
    [CREATE_VOTING_ERR_KEYS.REGULATIONS]: regulationsErr,
    [CREATE_VOTING_ERR_KEYS.PRIVACY_POLICY]: privacyPolicyErr
  } = model.errors;

  return (
    <CreateVotingTile title="Drafty" pt="29px" pb={{ base: "39px", sm: "38px" }}>
      <VStack align="start" gap="34px">
        <CustomDocumentAdd
          placeholder="Wczytaj własny regulamin..."
          onChange={model.setCustomRegulations.bind(model)}
          value={model.customRegulations}
          buttonLabel={"Zobacz regulamin"}
          previewUrl={model.customRegulationsUrl}
          previewUrlName={model.customRegulationsUrlName}
        />
        {regulationsErr && <Text variant="error">{regulationsErr}</Text>}
        <CustomDocumentAdd
          placeholder="Wczytaj własną politykę prywatności..."
          onChange={model.setCustomPrivacyPolicy.bind(model)}
          value={model.customPrivacyPolicy}
          buttonLabel={"Zobacz politykę prywatności"}
          previewUrl={model.customPrivacyPolicyUrl}
          previewUrlName={model.customPrivacyPolicyUrlName}
        />
        {privacyPolicyErr && <Text variant="error">{privacyPolicyErr}</Text>}
      </VStack>
    </CreateVotingTile>
  );
});
