import { computed, makeObservable, observable, action, runInAction } from "mobx";
import {
  Command,
  GENERAL_MAIL_REGEX,
  LandingPageConsentType,
  LandingPageTextContents,
  LandingPageUser,
  MAX_MAIL_SIGNS,
  Model,
  SIZE_UNIT,
  ToastType,
  downloadAffirmation,
  downloadFile,
  addEmail,
  AddEmailParams,
  LandingPageDataType,
  LandingPageDraftDataType
} from "@lubbezposrednio-frontend/core";
import { QR_ID } from "./components/LandingPageQR";
import { AxiosError } from "axios";

type AddEmailError = AxiosError<{ errors: string[] }>;

const LANDING_PAGE_FINISHED_ERR = ["Landing page Landing page is already finished"];
const EMAIL_ADDED_ERR = ["Email has already been taken", "Confirmed Record is already confirmed"];

const ADD_EMAIL_ERRORS = [
  {
    status: 422,
    messages: [
      {
        errMessages: LANDING_PAGE_FINISHED_ERR,
        message: "Zapisy na głosowanie zostały już zakończone. Nie można dodać adresu do listy."
      },
      { errMessages: EMAIL_ADDED_ERR, message: "Podany adres email został już zapisany na głosowanie." }
    ]
  }
];

const getInitialConsents = (
  customTermsAndConditionsUrl?: string,
  customPivacyPolicyUrl?: string
): LandingPageConsentType[] =>
  [
    {
      content: `akceptacja ${
        customTermsAndConditionsUrl ? `<a target='_blank' href=${customTermsAndConditionsUrl}} >regulaminu</a>` : ""
      }${customTermsAndConditionsUrl && customPivacyPolicyUrl ? " i " : ""}${
        customPivacyPolicyUrl ? `<a target='_blank' href=${customPivacyPolicyUrl}} >polityki prywatności</a>` : ""
      } organizatora`,
      id: 1,
      isChecked: false,
      isRequired: true,
      isDisplayed: customTermsAndConditionsUrl || customPivacyPolicyUrl
    },
    {
      content: "akceptacja zgody na przetwarzanie maila w celach marketingowych przez organizatora",
      id: 2,
      isChecked: false,
      isDisplayed: customTermsAndConditionsUrl || customPivacyPolicyUrl
    },
    {
      content: `akceptacja <a target='_blank' href=${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/terms_of_service >regulaminu</a> i <a target='_blank' href=${process.env.REACT_APP_BACKEND_URL}/api/v1/documents/privacy_policy >polityki prywatności</a> aplikacji`,
      id: 3,
      isChecked: false,
      isRequired: true,
      isDisplayed: true
    },
    {
      content: "akceptacja zgody na przetwarzanie w celach marketingowych przez aplikację",
      id: 4,
      isChecked: false,
      isDisplayed: true
    }
  ]
    .filter(consent => consent.isDisplayed)
    .map(({ isDisplayed, ...consent }) => consent as LandingPageConsentType);

export class LandingPageViewModel extends Model {
  organizerData: LandingPageUser & { affirmation_size?: number | null; affirmation_url?: string } =
    {} as LandingPageUser;
  textContents: LandingPageTextContents = {} as LandingPageTextContents;
  qrCodeBlob?: Blob;
  email = "";
  consents: LandingPageConsentType[] = [];
  emailError = "";
  consentsError = "";
  id = "";
  customPivacyPolicyUrl?: string;
  customTermsAndConditionsUrl?: string;

  constructor() {
    super();

    makeObservable(this, {
      organizerData: observable.ref,
      textContents: observable.ref,
      qrCodeBlob: observable.ref,
      consents: observable.ref,
      email: observable,
      emailError: observable,
      consentsError: observable,
      id: observable,
      checkAllConsents: action.bound,
      toggleConsent: action.bound,
      setEmailError: action.bound,
      setConsentsError: action.bound,
      updateEmail: action.bound,
      clearData: action.bound,
      isAllConsents: computed,
      affirmationSize: computed
    });
  }

  public async downloadOrganizerAffirmation() {
    if (this.organizerData.affirmation_url) {
      const response = await downloadAffirmation(this.organizerData.affirmation_url);
      if (response?.status === 200 && response?.data) {
        downloadFile(response.data, "oswiadczenie.pdf");
      }
    }
  }

  async getQrCodeBLob() {
    const canvas = document.getElementById(QR_ID) as HTMLCanvasElement;
    const pngUrl = canvas?.toDataURL("image/png")?.replace("image/png", "image/octet-stream");
    const blob = await fetch(pngUrl).then(r => r.blob());

    runInAction(() => {
      this.qrCodeBlob = blob;
    });
  }

  public async downloadQrCode() {
    this.qrCodeBlob && downloadFile(this.qrCodeBlob, `${this.textContents.pageTitle}.png`);
  }

  updateEmail(email: string) {
    this.email = email;
    this.emailError && this.validateEmail();
  }

  public checkAllConsents() {
    this.consents = this.consents.map(consent => ({ ...consent, isChecked: true }));
    this.setConsentsError();
  }

  public toggleConsent(id: number) {
    this.consents = this.consents.map(consent =>
      consent.id === id ? { ...consent, isChecked: !consent.isChecked } : consent
    );
    this.consentsError && this.validateConsents();
  }

  setEmailError(error?: string) {
    this.emailError = error ?? "";
  }

  setConsentsError(error?: string) {
    this.consentsError = error ?? "";
  }

  private validateEmail(): boolean {
    this.setEmailError();
    if (!!this.email) {
      if (!this.email.match(GENERAL_MAIL_REGEX)) {
        this.setEmailError("Nieprawidłowy format email");
      } else if (this.email.length > MAX_MAIL_SIGNS) {
        this.setEmailError(`Maksymalna ilość znaków to ${MAX_MAIL_SIGNS}`);
      }
    } else {
      this.setEmailError("Adres email jest wymagany");
    }

    return !this.emailError;
  }

  public async getInitialData(data: (LandingPageDataType | LandingPageDraftDataType)["landing_page"]) {
    runInAction(() => {
      this.id = data.id;
      this.organizerData = data.user ?? {};
      this.textContents = {
        pageTitle: data.page_title,
        pageDescription: data.page_description,
        additionalDescriptions:
          data.additional_descriptions?.map((item, i) => ({ id: String(i + 1), content: item })) ?? []
      };
      this.customPivacyPolicyUrl =
        data.privacy_policy_url && process.env.REACT_APP_BACKEND_URL + data.privacy_policy_url;
      this.customTermsAndConditionsUrl =
        data.terms_and_conditions_url && process.env.REACT_APP_BACKEND_URL + data.terms_and_conditions_url;
      this.consents = getInitialConsents(this.customTermsAndConditionsUrl, this.customPivacyPolicyUrl);
    });
  }

  private validateConsents(): boolean {
    const notCheckedConsents = this.consents
      .filter(consent => consent.isRequired && !consent.isChecked)
      .map(({ content }) => content);
    const isError = !!notCheckedConsents.length;
    this.setConsentsError(isError ? `Zgody: ${notCheckedConsents.join(", ")} są wymagane` : "");
    return !isError;
  }

  public addEmail(toast: ToastType) {
    return new Command(async () => {
      const isValid = this.validateEmail() && this.validateConsents();
      if (isValid) {
        const params: AddEmailParams = {
          email: this.email,
          accepted_organizations_service_terms: this.consents.find(consent => consent.id === 1)?.isChecked,
          accepted_organizations_marketing_terms: this.consents.find(consent => consent.id === 2)?.isChecked,
          accepted_applications_service_terms: !!this.consents.find(consent => consent.id === 3)?.isChecked,
          accepted_applications_marketing_terms: !!this.consents.find(consent => consent.id === 4)?.isChecked
        };

        try {
          const response = await addEmail(this.id, params);
          if (response.status === 202) {
            toast({
              title: "Na podany adres email została przesłana wiadomość w celu potwierdzenia dodania do listy.",
              status: "success",
              isClosable: true
            });
            this.clearData();
          } else {
            this.showGenericErrorToast(toast);
          }
        } catch (err) {
          const errResponse = (err as AddEmailError)?.response;
          const errMessageData = ADD_EMAIL_ERRORS.find(addErr => addErr.status === errResponse?.status);

          const message = errResponse?.data?.errors
            .map(
              error =>
                errMessageData?.messages.find(addEmailErrMessage => addEmailErrMessage.errMessages.includes(error))
                  ?.message
            )
            .filter(errMessage => errMessage)
            ?.join(", ");

          if (message) {
            return this.showGenericErrorToast(toast, message);
          }
          this.showGenericErrorToast(toast);
          console.log(err);
        }
      }
    });
  }

  clearData() {
    this.email = "";
    this.consents = getInitialConsents(this.customTermsAndConditionsUrl, this.customPivacyPolicyUrl);
    this.emailError = "";
    this.consentsError = "";
  }

  override async onInit() {}

  get qrCodeSize(): string {
    return ((this.qrCodeBlob?.size ?? 0) / SIZE_UNIT).toFixed(2) + "KB";
  }

  get affirmationSize(): string {
    let finalSize = this.organizerData.affirmation_size ?? 0;
    let i = 0;

    const units = ["B", "KB", "MB", "GB"];

    while (finalSize / SIZE_UNIT > 1 && i < units.length - 1) {
      finalSize = finalSize / SIZE_UNIT;
      i++;
    }

    return finalSize.toFixed(2) + units[i];
  }

  get isAllConsents(): boolean {
    return Object.values(this.consents).every(({ isChecked }) => isChecked);
  }
}
