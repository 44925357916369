import { useCallback, useState } from "react";
import { Button, Flex, HStack, Image, InputRightElement, Text, VStack, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { EligibleVotersModel } from "../EligibleVotersModel";
import { CustomInput } from "@lubbezposrednio-frontend/commons";

const BUTTON_WIDTH = { base: "42px", sm: "120px" };
const INPUT_HEIGHT = "48px";

type ManualVotersAddProps = {
  model: EligibleVotersModel;
};

export const ManualVotersAdd: React.FC<ManualVotersAddProps> = observer(({ model }) => {
  const [currValue, setCurrValue] = useState("");

  const toast = useToast();

  const handleAdd = useCallback(() => {
    const isSuccess = model.handleAddEmail(currValue, toast);
    isSuccess && setCurrValue("");
  }, [currValue]);

  return (
    <VStack
      pt={{ base: "32px", sm: "29px" }}
      align={{ base: "center", sm: "flex-start" }}
      maxW="100%"
      spacing={{ base: "35px", sm: "18px" }}
    >
      <CustomInput
        value={currValue}
        onKeyDown={e => (e.key === "Enter" || e.key === "Return") && handleAdd()}
        onChange={setCurrValue}
        width="469px"
        h={INPUT_HEIGHT}
        placeholder="Wpisz adres e-mail..."
        color="primary"
        _placeholder={{ color: "primary", textStyle: "text" }}
      >
        <InputRightElement w={BUTTON_WIDTH} h={INPUT_HEIGHT}>
          <Button
            variant="regular"
            w={BUTTON_WIDTH}
            h={INPUT_HEIGHT}
            onClick={handleAdd}
            _after={{
              content: { base: "'+'", sm: "'Dodaj >>'" },
              textStyle: "largeText",
              fontWeight: "bold"
            }}
          />
        </InputRightElement>
      </CustomInput>

      <Flex
        columnGap="20.34px"
        rowGap="7px"
        flexWrap="wrap"
        justify={{ base: "center", sm: "flex-start" }}
        maxW={"100%"}
      >
        {model.votersEmails.map(email => (
          <HStack key={email.id} gap="7.34px">
            <Text textStyle="text" color="primary" wordBreak="break-word" textAlign="center">
              {email.value}
            </Text>
            <Image src="/Icon metro-cross.svg" onClick={() => model.removeVotersEmail(email.id)} cursor="pointer" />
          </HStack>
        ))}
      </Flex>
    </VStack>
  );
});
