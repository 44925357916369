import { action, computed, makeObservable, observable, runInAction } from "mobx";
import {
  Model,
  Command,
  getVotingDetails,
  sendVote,
  ApiVotingDetails,
  ConsentsType,
  VoteType,
  VotingViewQuestion,
  ToastType,
  VOTING_STATUSES,
  GENERAL_MAIL_REGEX,
  MAX_MAIL_SIGNS,
  getFormattedDate,
  FORM_ERROR_MESSAGE
} from "@lubbezposrednio-frontend/core";
import { NavigateFunction } from "react-router-dom";

const HAS_ALREADY_VOTED_MESSAGE = "has already been taken";

export class VotingViewModel extends Model {
  id?: string;
  isPublic?: boolean;
  organizer?: { name: string; imageUrl?: string };
  name?: string;
  startDate?: string;
  endDate?: string;
  questions?: VotingViewQuestion[] = [];
  email = "";
  consents: ConsentsType = {} as ConsentsType;
  consentsError = "";
  questionErrors: { questionId: number; message: string }[] = [];
  emailError = "";
  isLoading = false;
  isEmailNecessary = false;
  status?: VOTING_STATUSES;
  privacyPolicyUrl = "";
  termsAndConditionsUrl = "";

  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      isPublic: observable,
      email: observable,
      handleEmailChange: action.bound,
      organizer: observable.ref,
      name: observable,
      startDate: observable,
      endDate: observable,
      questions: observable,
      consents: observable.ref,
      privacyPolicyUrl: observable,
      termsAndConditionsUrl: observable,
      isLoading: observable,
      isEmailNecessary: observable,
      status: observable,
      changeConsent: action.bound,
      saveVotingDetails: action.bound,
      selectAnswer: action.bound,
      consentsError: observable,
      validateConsents: action.bound,
      questionErrors: observable.ref,
      validateQuestions: action.bound,
      emailError: observable,
      validateEmail: action.bound,
      isError: computed,
      clearData: action.bound
    });
  }

  public changeConsent(consent: Partial<ConsentsType>) {
    this.consents = { ...this.consents, ...consent };
    this.consentsError && this.validateConsents();
  }

  public getQuestionError(questionId: number) {
    return this.questionErrors.find(error => error.questionId === questionId)?.message;
  }

  public handleEmailChange(email: string) {
    this.email = email;
    this.emailError && this.validateEmail();
  }

  public selectAnswer(questionId: number, answerId?: number) {
    const question = this.questions?.find(question => question.id === questionId);
    if (question) {
      question.selectedAnswerId = answerId;
    }

    this.getQuestionError(questionId) && this.validateQuestions();
  }

  private getFormattedDate(date: string) {
    return getFormattedDate(new Date(date));
  }

  saveVotingDetails({ voting }: ApiVotingDetails) {
    const { user } = voting;

    const userName =
      (user.first_name || user.last_name) && `${voting.user.first_name ?? ""} ${voting.user.last_name ?? ""}`;
    const organizerName = user.is_organization ? user.organization_name : userName;

    this.isPublic = voting.public;
    this.organizer = {
      name: organizerName ?? user.email,
      imageUrl: user.avatar_url ? `${process.env.REACT_APP_BACKEND_URL}${user.avatar_url}` : undefined
    };
    this.name = voting.title;
    this.startDate = this.getFormattedDate(voting.start_date);
    this.endDate = this.getFormattedDate(voting.end_date);
    this.questions = voting.questions;
    this.status = voting.status;
    this.privacyPolicyUrl = voting.privacy_policy_url
      ? `${process.env.REACT_APP_BACKEND_URL}${voting.privacy_policy_url}`
      : "";
    this.termsAndConditionsUrl = voting.terms_and_conditions_url
      ? `${process.env.REACT_APP_BACKEND_URL}${voting.terms_and_conditions_url}`
      : "";
  }

  public async getVoting(id: string, toast: ToastType, navigate: NavigateFunction, useEmail?: true) {
    runInAction(() => {
      this.id = id;
      this.isLoading = true;
    });
    const response = await getVotingDetails(id, useEmail && this.email);

    runInAction(() => {
      this.isLoading = false;
    });

    const isUserNotAllowed = response.status === 403;

    if (this.isEmailNecessary && isUserNotAllowed && useEmail) {
      this.clearData();
      return navigate(`/glosowanie/${id}/nieuprawiony-email`);
    }

    runInAction(() => {
      this.isLoading = false;
      this.isEmailNecessary = isUserNotAllowed;
    });
    if (response?.status === 200) {
      return this.saveVotingDetails(response.data);
    }

    !this.isEmailNecessary && this.showGenericErrorToast(toast);
  }

  validateQuestions() {
    this.questionErrors =
      this.questions
        ?.filter(question => !question.selectedAnswerId)
        .map(question => ({ questionId: question.id, message: "Zaznacz odpowiedź" })) ?? [];
  }

  validateEmail() {
    const rules = [
      { condition: !!this.email, message: "Podaj adres email" },
      {
        condition: !!this.email.match(GENERAL_MAIL_REGEX),
        message: "Nieprawidłowy format email"
      },
      {
        condition: this.email.length <= MAX_MAIL_SIGNS,
        message: `Maksymalna ilość znaków to ${MAX_MAIL_SIGNS}`
      }
    ];
    this.emailError = rules.find(rule => !rule.condition)?.message ?? "";
  }

  validateConsents() {
    const missingConsents = [
      this.consents.termsAndConditions,
      this.consents.privacyPolicy,
      this.consents.customTermsAndConditions || !this.termsAndConditionsUrl,
      this.consents.customPrivacyPolicy || !this.privacyPolicyUrl
    ].filter(consent => !consent);

    this.consentsError = missingConsents.length ? "Wymagana jest akceptacja wszystkich zgód" : "";
  }

  validate() {
    !this.isEmailNecessary && this.validateQuestions();
    this.validateEmail();
    !this.isEmailNecessary && this.validateConsents();
  }

  clearData() {
    this.id = undefined;
    this.isPublic = undefined;
    this.organizer = undefined;
    this.name = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.questions = [];
    this.email = "";
    this.consents = {} as ConsentsType;
    this.consentsError = "";
    this.questionErrors = [];
    this.emailError = "";
    this.isLoading = false;
    this.isEmailNecessary = false;
    this.privacyPolicyUrl = "";
    this.termsAndConditionsUrl = "";
  }

  public handleSend(toast: ToastType, navigate: NavigateFunction) {
    return new Command(async () => {
      this.validate();
      if (this.isError) {
        return this.showGenericErrorToast(toast, FORM_ERROR_MESSAGE);
      }
      if (this.id) {
        if (this.isEmailNecessary) {
          return this.getVoting(this.id, toast, navigate, true);
        }

        const vote = (this.questions?.map(question => ({ answer_id: question.selectedAnswerId })) ?? []) as VoteType;

        const response = await sendVote(this.id, { vote_answers_attributes: vote, voter_email: this.email });

        switch (response.status) {
          case 204: {
            navigate("/wyslanie-maila-z-potwierdzeniem-glosowania");
            return this.clearData();
          }

          case 403: {
            navigate(`/glosowanie/${this.id}/nieuprawiony-email`);
            return this.clearData();
          }

          case 422: {
            if (response.data?.errors?.voter_id?.[0] === HAS_ALREADY_VOTED_MESSAGE) {
              return this.showGenericErrorToast(
                toast,
                "Dla podanego adresu email został już oddany i potwierdzony głos"
              );
            }
          }
        }
      }
      return this.showGenericErrorToast(toast);
    });
  }

  get isError(): boolean {
    return !!this.emailError || !!this.consentsError || !!this.questionErrors.length;
  }

  override async onInit() {}
}
