import { Text, VStack } from "@chakra-ui/react";
import { CustomTile, Layout } from "@lubbezposrednio-frontend/commons";
import React from "react";
import ReactPlayer from "react-player/youtube";

const MAX_WIDTH = "80vw";

export const HowToUseExplorerView: React.FC = () => {
  return (
    <Layout>
      <VStack flex={1} gap={0} pb="113px">
        <Text textStyle="header" color="primary" py="34px" textAlign="center">
          Jak korzystać z eksploratora?
        </Text>
        <Text w="958px" fontSize="22px" lineHeight="22px" textAlign="center" color="primary" pb="80px" maxW={MAX_WIDTH}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
          dolore magna aliquam erat volutpat.
          <br /> <br /> Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
          aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie
          consequat.
        </Text>

        <CustomTile
          tileWidth={715}
          title="Instrukcja korzystania"
          titleStyle={{ variant: "videoTile" }}
          pb="17px"
          maxWidth={MAX_WIDTH}
        >
          <VStack>
            <ReactPlayer url="https://youtu.be/QMA567FVlXk" controls style={{ maxWidth: "100%" }} />
          </VStack>
        </CustomTile>
      </VStack>
    </Layout>
  );
};
