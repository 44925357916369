import { Flex, Text } from "@chakra-ui/layout";
import { getAnswerChar } from "@lubbezposrednio-frontend/core";

export const VotingQuestionOption: React.FC<{
  option: string;
  index: number;
}> = ({ option, index }) => {
  return (
    <Flex pl="61px" gap="22px">
      <Text fontWeight="bold">{getAnswerChar(index)}</Text>
      <Text>{option}</Text>
    </Flex>
  );
};
