import { Text, VStack } from "@chakra-ui/react";
import { CreateVotingTile } from "./CreateVotingTile";
import { CustomCheckbox, CustomIconWIthTooltip } from "@lubbezposrednio-frontend/commons";
import { CREATE_VOTING_ERR_KEYS, CreateVotingViewModel } from "../CreateVotingViewModel";
import { observer } from "mobx-react-lite";

const TEXT_LEFT_PADDING = { sm: "24px" };

export const VotingType: React.FC<{ model: CreateVotingViewModel }> = observer(({ model }) => {
  const error = model.errors[CREATE_VOTING_ERR_KEYS.IS_PUBLIC];

  return (
    <CreateVotingTile title="Typ głosowania" pt="15px">
      <VStack alignItems="left" pb={{ base: "23px", sm: "36px" }} spacing={0}>
        <CustomIconWIthTooltip tooltipMsg="To głosowanie będzie widoczne wyłącznie dla osób, które otrzymają od Ciebie zaproszenie do udziału w nim, czyli dla osób uprawnionych do głosowania. Pozostali użytkownicy platformy nie będą mogły dowiedzieć się, o czym decydujecie. Szczegóły znajdziesz w polityce prywatności i regulaminie">
          <CustomCheckbox
            label={"NIEPUBLICZNE"}
            value={model.isPublic === false}
            onChange={isNotPublic => model.setIsPublic(!isNotPublic && undefined)}
            wrapperProps={{ lineHeight: 1, pb: "10px" }}
          />
        </CustomIconWIthTooltip>

        <CustomIconWIthTooltip tooltipMsg="To głosowanie – czyli jego tytuł, zadane pytania i proponowane odpowiedzi – będzie widoczne dla wszystkich użytkowników platformy, ale tylko uprawnione przez Ciebie osoby będą mogły wziąć w nim udział. Pozostali użytkownicy będą mogli zobaczyć, o czym decydujecie. Szczegóły znajdziesz w polityce prywatności i regulaminie">
          <CustomCheckbox
            label={"JAWNE"}
            value={model.isPublic === true}
            onChange={isPublic => model.setIsPublic(isPublic || undefined)}
            wrapperProps={{ lineHeight: 1, pb: { base: "8px", sm: "5px" } }}
          />
        </CustomIconWIthTooltip>

        {error && (
          <Text variant="error" pl={TEXT_LEFT_PADDING} pt="5px" pb={0}>
            {error}
          </Text>
        )}
      </VStack>
    </CreateVotingTile>
  );
});
