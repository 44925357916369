import { action, computed, makeObservable, observable } from "mobx";
import {
  Model,
  ToastType,
  GENERAL_MAIL_REGEX,
  MAILER_CLIENT_ADDRESS_REGEX,
  MAX_MAIL_SIGNS,
  downloadFile,
  getCsvTemplate,
  VotingDraftDetails
} from "@lubbezposrednio-frontend/core";

const MAX_CSV_SIZE = 2 * 1024 * 1024; // B

export class EligibleVotersModel extends Model {
  eligibleVotersListType?: "csv" | "manual";
  votersEmails: { id: number; value: string }[] = [];
  votersCsv?: File;
  votersCsvUrl?: string;
  error = "";

  constructor() {
    super();

    makeObservable(this, {
      eligibleVotersListType: observable,
      setEligibleVotersListType: action.bound,
      votersCsv: observable.ref,
      votersCsvUrl: observable.ref,
      setVotersCsv: action.bound,
      loadDataFromDraft: action.bound,

      votersEmails: observable.ref,
      addVotersEmail: action.bound,
      removeVotersEmail: action.bound,
      votersCsvUrlName: computed,

      error: observable,
      setError: action.bound,

      clearData: action.bound,
      clearErrors: action.bound
    });
  }

  public loadDataFromDraft(draft: VotingDraftDetails) {
    if (draft.emails) {
      this.setEligibleVotersListType("manual");
      draft.emails.forEach(email => {
        this.addVotersEmail(email.value);
      });
    } else if (draft.votersListUrl) {
      this.setEligibleVotersListType("csv");
      this.votersCsvUrl = draft.votersListUrl;
    }
  }

  setEligibleVotersListType(eligibleVotersListType?: "csv" | "manual") {
    this.eligibleVotersListType = eligibleVotersListType;
    this.error && this.validateCheckbox();
  }

  setVotersCsv(votersCsv?: File) {
    this.votersCsv = votersCsv;
    this.votersCsvUrl = undefined;
    this.error && this.validateCsv();
  }

  setError(error: string) {
    this.error = error;
  }

  addVotersEmail(email: string) {
    const id = (this.votersEmails[this.votersEmails.length - 1]?.id ?? 0) + 1;
    this.votersEmails = [...this.votersEmails, { id, value: email }];

    this.error && this.validateEmails();
  }

  public removeVotersEmail(id: number) {
    this.votersEmails = this.votersEmails.filter(email => email.id !== id);
  }

  private validateEmail(email: string, toast: ToastType): boolean {
    const validatioError: string = [
      {
        condition: !!email.match(GENERAL_MAIL_REGEX),
        message: `Nieprawidłowy format email (${email})`
      },
      {
        condition: email.length <= MAX_MAIL_SIGNS,
        message: `Maksymalna ilość znaków to ${MAX_MAIL_SIGNS}`
      },
      {
        condition: this.votersEmails.every(savedEmail => savedEmail.value !== email),
        message: `Adres e-mail (${email}) został już dodany`
      }
    ]
      .reduce<string[]>((error, validation): string[] => {
        !validation.condition && error.push(validation.message);
        return error;
      }, [])
      .join(". ");

    validatioError &&
      toast({
        title: validatioError,
        status: "error",
        isClosable: true
      });

    return !validatioError;
  }

  private parseEmail(email: string): string {
    const lowerCaseEmail = email.toLowerCase();

    const emailMatches = lowerCaseEmail.match(MAILER_CLIENT_ADDRESS_REGEX);

    const mailerClientEmail = emailMatches && emailMatches[0].slice(1, -1);

    return mailerClientEmail || lowerCaseEmail;
  }

  private divideEmails(value: string): string[] {
    const emails = value.split(/\s+|,\s*|;\s*/);
    return emails.filter(
      (singleEmail, index) =>
        emails.findIndex((searchedEmail: string) => searchedEmail.toLowerCase() === singleEmail.toLowerCase()) === index
    );
  }

  public handleAddEmail(email: string, toast: ToastType) {
    const emails = this.divideEmails(email);

    if (emails.length + this.votersEmails.length > 1000) {
      return toast({
        title:
          "Przekroczono maksymalny dopuszczalny limit 1000 rekordów wprowadzanych ręcznie. Jeśli chcesz utworzyć głosowanie dla większej liczby głosujących skorzystaj z możliwości załączenia listy w formacie .csv",
        status: "error",
        isClosable: true
      });
    }

    const isSuccess = emails
      .map(singleEmail => {
        const emailValue = this.parseEmail(singleEmail);
        const isValid = this.validateEmail(emailValue, toast);
        isValid && this.addVotersEmail(emailValue);
        return isValid;
      })
      .every(isValid => !!isValid);

    return isSuccess;
  }

  public async downloadCsvTemplate() {
    const response = await getCsvTemplate();
    if (response?.status === 200 && response?.data) {
      downloadFile(response.data, "voters_template.csv");
    }
  }

  private validateCheckbox() {
    this.setError(this.eligibleVotersListType ? "" : "Wybierz jedną z opcji");
  }

  public validateCsvSize() {
    const isCorrectSize = (this.votersCsv?.size ?? 0) <= MAX_CSV_SIZE;

    this.setError(
      isCorrectSize ? "" : `Przekroczono maksymalny dopuszczalny rozmiar pliku ${MAX_CSV_SIZE / 1024 / 1024} MB`
    );
  }

  private validateCsv() {
    if (this.eligibleVotersListType === "csv") {
      this.setError(this.votersCsv || this.votersCsvUrl ? "" : "Wczytaj plik .csv");

      !this.error && this.validateCsvSize();
    }
  }

  private validateEmails() {
    if (this.eligibleVotersListType === "manual") {
      this.setError(this.votersEmails.length ? "" : "Podaj przynajmniej 1 adres email");
    }
  }

  validateFields() {
    this.validateCheckbox();
    !this.error && this.validateCsv();
    !this.error && this.validateEmails();
  }

  get votersCsvUrlName(): string {
    const lastSlashIndex = this.votersCsvUrl?.lastIndexOf("/");
    return decodeURI(this.votersCsvUrl?.slice((lastSlashIndex ?? 0) + 1) ?? "");
  }

  override async onInit() {}

  clearErrors() {
    this.error = "";
  }

  clearData() {
    this.eligibleVotersListType = undefined;
    this.votersEmails = [];
    this.votersCsv = undefined;
    this.votersCsvUrl = undefined;
    this.clearErrors();
  }
}
