import { Box, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomButton, Layout } from "@lubbezposrednio-frontend/commons";
import { withModel } from "@lubbezposrednio-frontend/core";
import { OrganizerSection } from "./components/OrganizerSection";
import { VotingOrganizerViewModel } from "./VotingOrganizerViewModel";
import ReportPopup from "../VotingView/components/ReportPopup/ReportPopup";

interface VotingOrganizerViewProps {
  model: VotingOrganizerViewModel;
}

const VotingOrganizerView: React.FC<VotingOrganizerViewProps> = observer(({ model }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();

  useEffect(() => {
    document.title = "Dane organizatora głosowania";
    id && model.getOrganizerData(id, toast);
  }, []);

  const [isReportPopupOpen, setReportPopupOpen] = useState(false);
  console.log(model.organizerData);

  return (
    <Layout>
      {model.isOrganization && (
        <OrganizerSection
          isUserVerified={model.isOrganisationVerified}
          data={model.organizationData}
          title="Dane organizacji"
          imageUrl={model.organizationImageUrl}
          affirmationUrl={model.organizationAffirmationUrl}
          verifiedText="Organizacja zweryfikowana"
        />
      )}
      <OrganizerSection
        data={model.isOrganization ? model.representativeData : model.organizerData}
        title={model.isOrganization ? "Dane reprezentanta organizacji" : "Dane organizatora"}
        imageUrl={model.profileImageUrl}
        affirmationUrl={model.profileAffirmationUrl}
        isUserVerified={model.isUserVerified}
        verifiedText="Użytkownik zweryfikowany"
      />
      <CustomButton text="Powrót" backgroundColor="accent" onClick={() => navigate(-1)} my="40px" />
      <Box mb={4}>
        <button onClick={() => setReportPopupOpen(true)}>
          <b>Zgłoś użytkownika</b>
        </button>
      </Box>
      {isReportPopupOpen && model.organizerData && (
        <ReportPopup
          Id={model.organizerData.find(data => data.fieldName === "Id")?.value as string}
          onClose={() => setReportPopupOpen(false)}
          reportableType="user"
          model={model}
        />
      )}
    </Layout>
  );
});

const VotingOrganizerViewWithModel = withModel<VotingOrganizerViewModel>(VotingOrganizerView, VotingOrganizerViewModel);

export default VotingOrganizerViewWithModel;
