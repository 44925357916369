import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/input";
import { useIsMobile, VOTING_FILTER_HEIGHT } from "@lubbezposrednio-frontend/core";
import React from "react";
import { SearchIcon } from "../../../../assets/SearchIcon";
import { CustomFilterButton } from "@lubbezposrednio-frontend/commons";

type SearchFilterProps = {
  value?: string;
  onChange: (value?: string) => void;
};

export const SearchFilter: React.FC<SearchFilterProps> = ({ value, onChange }) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <CustomFilterButton<string>
      label="page title filter"
      getIcon={(fill: string) => <SearchIcon fill={fill} />}
      value={value}
    >
      <FilterInput value={value} onChange={onChange} withoutIcon />
    </CustomFilterButton>
  ) : (
    <FilterInput value={value} onChange={onChange} />
  );
};

type FilterInputProps = SearchFilterProps & {
  withoutIcon?: boolean;
};

const FilterInput: React.FC<FilterInputProps> = ({ value, onChange, withoutIcon }) => {
  const placeholder = "Szukaj...";

  return (
    <InputGroup
      w="260px"
      px="20px"
      h={VOTING_FILTER_HEIGHT}
      borderWidth="2px"
      borderColor="primary"
      borderRadius={"standard"}
      variant="custom"
      bg="background"
      alignItems="center"
      maxW="100%"
    >
      {!withoutIcon && (
        <InputLeftAddon padding={0}>
          <SearchIcon fill="#430098" />
        </InputLeftAddon>
      )}
      <Input
        bg="background"
        color="primary"
        textStyle="text"
        placeholder={placeholder}
        _placeholder={{ color: "primary", textStyle: "text" }}
        pl={"16.5px"}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </InputGroup>
  );
};
