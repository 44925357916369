import { useMemo } from "react";
import { CustomConfirmModal } from "@lubbezposrednio-frontend/commons";
import { CreatedLandingPagesListViewModel } from "../CreatedLandingPagesListViewModel";
import { useToast } from "@chakra-ui/react";

export const ConfirmEndPublicationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  model: CreatedLandingPagesListViewModel;
  id: string;
}> = ({ isOpen, onClose, model, id }) => {
  const toast = useToast();
  const confirmCommand = useMemo(() => model.handleEndPublication(id, toast, onClose), [toast, id, onClose]);

  return (
    <CustomConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      title="Zakończ publikację"
      description="Czy na pewno chcesz zakończyć publikację strony zapisu na głosowanie?"
      confirmCommand={confirmCommand}
    />
  );
};
