import { VStack, HStack, Button, Text, Box, Image } from "@chakra-ui/react";

import React from "react";
import { Link } from "react-router-dom";
import { VerifiedCheck } from "@lubbezposrednio-frontend/commons";
import { OrganizerDataLine } from "./OrganizerDataLine";

interface OrganizerSectionProps {
  title: string;
  data: { id: number; fieldName: string; value: string }[];
  imageUrl: string | null;
  affirmationUrl: string | null;
  isUserVerified: boolean;
  verifiedText: string;
}

export const OrganizerSection: React.FC<OrganizerSectionProps> = ({
  title,
  data,
  imageUrl,
  affirmationUrl,
  isUserVerified,
  verifiedText
}) => {
  return (
    <>
      {isUserVerified && <VerifiedCheck text={verifiedText} mb="10px" mt="40px" />}
      <VStack
        p="30px"
        borderColor="primary"
        borderWidth="2px"
        borderRadius="standard"
        w={{ base: "100%", md: "600px" }}
        bg="background"
        align="flex-start"
        mt={isUserVerified ? 0 : "40px"}
      >
        <Text textStyle="header" color="primary">
          {title}
        </Text>
        <HStack w="100%" align="flex-end" justify="space-between" mb="20px">
          {imageUrl ? (
            <Image src={imageUrl} maxW="120px" maxH="120px" borderRadius="standard" />
          ) : (
            <Box
              w="120px"
              h="120px"
              borderColor="primary"
              borderWidth="2px"
              borderRadius="standard"
              position="relative"
            >
              <Image src="/PERSON_ICON.svg" position="absolute" bottom={0} left="50%" transform="translate(-50%)" />
            </Box>
          )}
          {affirmationUrl && (
            <Link to={affirmationUrl} target="blank">
              <Button bg="primary" color="white" variant="custom" borderRadius="standard" py="24px">
                <Text textStyle="text">Pobierz oświadczenie</Text>
              </Button>
            </Link>
          )}
        </HStack>
        {data.map(line => (
          <OrganizerDataLine key={line.id} fieldName={line.fieldName} value={line.value} />
        ))}
      </VStack>
    </>
  );
};
