import React from "react";
import { Box, Flex, Text, VStack, useToast } from "@chakra-ui/react";
import { VotingQuestionsModel } from "../VotingQuestionsModel";
import { observer } from "mobx-react-lite";
import { CreateVotingAnswer } from "./CreateVotingAnswer";
import { VotingQuestionArrayType, handleKeyboardNavigation } from "@lubbezposrednio-frontend/core";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";

type CreateVotingAnswersProps = {
  question: VotingQuestionArrayType;
  model: VotingQuestionsModel;
};

export const CreateVotingAnswers: React.FC<CreateVotingAnswersProps> = observer(({ question, model }) => {
  const errors = model.answerErrors.filter(error => error.questionId === question.id);

  const toast = useToast();

  return (
    <VStack pt="10px" pb={{ base: "9px", sm: "30px" }} w="100%" spacing={"11px"}>
      <VStack spacing="12px" w="100%" align="flex-start">
        {question.answers.map((answer, index) => {
          const error = errors.find(error => error.answerId === answer.id);
          return (
            <CreateVotingAnswer
              key={answer.id}
              error={error}
              index={index}
              model={model}
              questionId={question.id}
              answer={answer}
            />
          );
        })}
      </VStack>
      <Flex w="100%" justifyContent="flex-end">
        <CustomTooltip message="Dodaj więcej opcji odpowiedzi">
          <Box background="primary" h="35px" w="35px" borderRadius="50%" position="relative">
            <Text
              position="absolute"
              color="background"
              fontSize="34px"
              top="-3.75px"
              left="5.5px"
              fontWeight="bold"
              textAlign="center"
              _hover={{ cursor: "pointer" }}
              tabIndex={0}
              lineHeight={1}
              {...handleKeyboardNavigation(() => {
                model.addAnswer(question.id, toast);
              })}
            >
              +
            </Text>
          </Box>
        </CustomTooltip>
      </Flex>
    </VStack>
  );
});
