import { Text, TextProps } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import { NEW_TAB_WARNING } from "@lubbezposrednio-frontend/core";

interface InternalLinkProps extends TextProps {
  to: string;
  text: string;
  openInNewTab?: true;
  isInline?: true;
}

export const InternalLink: React.FC<InternalLinkProps> = ({ to, text, openInNewTab, isInline, ...textProps }) => {
  return (
    <CustomTooltip message={openInNewTab ? NEW_TAB_WARNING : ""}>
      <Link to={to} target={openInNewTab && "blank"} style={{ display: isInline && "inline-block" }}>
        <Text textStyle="text" fontWeight="bold" color="primary" {...textProps}>
          {text}
        </Text>
      </Link>
    </CustomTooltip>
  );
};
