import { VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { CustomButton } from "@lubbezposrednio-frontend/commons";

interface BottomButtonsSectionProps {}

export const BottomButtonsSection: React.FC<BottomButtonsSectionProps> = ({}) => {
  return (
    <VStack gap={0}>
      <Link to={"/zapomnialem-hasla"} tabIndex={-1}>
        <CustomButton
          text="Nie pamiętam hasła"
          backgroundColor="transparent"
          textColor="primary"
          fontSize="14px"
          fontWeight="normal"
          py="8px"
        />
      </Link>
      <Link to="/rejestracja" tabIndex={-1}>
        <CustomButton text="Zarejestruj się" backgroundColor="transparent" textColor="accent" py="8px" />
      </Link>
    </VStack>
  );
};
