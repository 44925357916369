import { action, makeObservable, observable, runInAction } from "mobx";
import {
  Model,
  CONSENT_NAMES,
  ConsentType,
  ToastType,
  editProfile,
  getProfile,
  ProfileApiData
} from "@lubbezposrednio-frontend/core";

const INITIAL_CONSENTS: ConsentType[] = [
  {
    id: 1,
    name: CONSENT_NAMES.APPLICATIONS_MARKETING,
    title: "Zgoda marketingowa",
    content:
      "Wyrażam zgodę na otrzymywanie materiałów informacyjnych i marketingowych w związku z działalnością platformy decyduj.lubbezposrednio.pl i realizacją zadania Instytut Demokracji Bezpośredniej",
    isChecked: false
  }
];

export class ConsentsViewModel extends Model {
  consents: ConsentType[] = INITIAL_CONSENTS;
  isLoading = false;
  isGetConsentsError = false;
  constructor() {
    super();

    makeObservable(this, {
      consents: observable.ref,
      saveUserConsents: action.bound,
      changeConsents: action.bound,
      isLoading: observable,
      setIsLoading: action.bound,
      isGetConsentsError: observable
    });
  }

  saveUserConsents({ user }: ProfileApiData): void {
    this.consents = this.consents.map(consent => {
      const isChecked = user[consent.name] ?? consent.isChecked;
      return { ...consent, isChecked };
    });
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  public async getConsents() {
    this.setIsLoading(true);

    const response = await getProfile();
    if (response.status === 200 && response.data) {
      this.saveUserConsents(response.data);
    } else if (response.status !== 200) {
      runInAction(() => {
        this.isGetConsentsError = true;
      });
    }

    this.setIsLoading(false);
  }

  public async changeConsents(name: CONSENT_NAMES, isChecked: boolean, toast: ToastType) {
    this.setIsLoading(true);
    const isSuccess = await editProfile({ [name]: isChecked });
    if (isSuccess) {
      runInAction(() => {
        this.consents = this.consents.map(consent => (consent.name === name ? { ...consent, isChecked } : consent));
      });
    } else {
      this.showGenericErrorToast(toast);
    }

    this.setIsLoading(false);
  }

  override async onInit() {}
}
