import { Fragment } from "react";
import { Divider, Radio, RadioGroup, VStack } from "@chakra-ui/react";
import { CustomFilterButton } from "@lubbezposrednio-frontend/commons";

type FilterButtonProps<T extends string> = {
  getIcon: (fill: string) => React.ReactElement;
  label: string;
  options?: { label: string; value: T }[];
  onChange: (val: T) => void;
  value?: T;
};

export const RadioFilterButton = <T extends string>({
  getIcon,
  label,
  options,
  onChange,
  value
}: FilterButtonProps<T>) => {
  return (
    <CustomFilterButton<T> getIcon={getIcon} label={label} value={value}>
      <RadioGroup onChange={onChange} value={value}>
        <VStack align="left" px="14px" gap="14.5px">
          {options?.map((option, index) => (
            <Fragment key={option.value}>
              {!!index && <Divider />}
              <Radio size="22px" value={option.value}>
                {option.label}
              </Radio>
            </Fragment>
          ))}
        </VStack>
      </RadioGroup>
    </CustomFilterButton>
  );
};
