import { Flex, Text, NumberInputProps } from "@chakra-ui/react";
import React from "react";
import { observer } from "mobx-react-lite";
import { DateInput } from "./DateInput";
import { DATE_KEYS, DateItemType } from "@lubbezposrednio-frontend/core";

const DATE_ITEMS: DateItemType[] = [
  { placeholder: "DD", key: DATE_KEYS.DAY },
  { placeholder: "MM", key: DATE_KEYS.MONTH },
  { placeholder: "RRRR", key: DATE_KEYS.YEAR, width: "68px" }
];
const HOUR_ITEMS: DateItemType[] = [
  { placeholder: "H", key: DATE_KEYS.HOUR },
  { placeholder: "MIN", key: DATE_KEYS.MINUTES }
];

export type CustomDatePickerProps = Omit<NumberInputProps, "onChange" | "value"> & {
  label?: string;
  onChange: (key: DATE_KEYS, value: string) => void;
  value: Record<DATE_KEYS, string>;
  onBlur: () => void;
};

export const CustomDatePicker: React.FC<CustomDatePickerProps> = observer(({ label, ...props }) => {
  return (
    <Flex pb="8px">
      {label && (
        <Text
          variant="smallTitle"
          pt="10px"
          pl={{ sm: "10px" }}
          align="center"
          w={{ base: "60px", sm: "auto" }}
          maxW="16vw"
        >
          {label.toUpperCase()}
        </Text>
      )}
      <Flex wrap="wrap" justify={label ? "end" : "start"} maxW="100%" rowGap="12px" columnGap={"51px"}>
        <Flex gap={{ base: "8px", sm: "4px" }} pl={{ base: "4px", sm: "16px" }}>
          {DATE_ITEMS.map(dateItem => (
            <DateInput key={dateItem.key} dateItem={dateItem} {...props} />
          ))}
        </Flex>
        <Flex gap="4px" align="center">
          {HOUR_ITEMS.map((dateItem, index) => (
            <React.Fragment key={dateItem.key}>
              <DateInput dateItem={dateItem} {...props} />
              {!index && <Text variant="smallTitle">:</Text>}
            </React.Fragment>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
});
