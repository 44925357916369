import { Box, Collapse, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { CollapseRedirectButton } from "./CollapseRedirectButton";

interface CollapsableSectionProps {
  isOpen: boolean;
  redirectPath: string;
  redirectButtonText: string;
  content: React.ReactNode;
}

export const CollapsableSection: React.FC<CollapsableSectionProps> = ({
  isOpen,
  redirectButtonText,
  redirectPath,
  content
}) => {
  return (
    <Collapse in={isOpen}>
      <VStack p="24px" gap={0}>
        <Box textStyle={{ base: "text", md: "largeText" }} color="primary" textAlign="center" pb="34px">
          {content}
        </Box>
        <CollapseRedirectButton redirectButtonText={redirectButtonText} redirectPath={redirectPath} />
      </VStack>
    </Collapse>
  );
};
