import { Text, VStack } from "@chakra-ui/react";
import { CustomButton, Layout, MessageWithIcon } from "@lubbezposrednio-frontend/commons";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

export const VotingFinishedScreen: React.FC = () => {
  useEffect(() => {
    document.title = "Głosowanie zakończone";
  }, []);

  const { state } = useLocation();

  return (
    <Layout>
      <VStack py="100px">
        <MessageWithIcon
          image="/ICON_voting.svg"
          title="Głosowanie zakończone"
          message={
            <>
              <Text>Głosowanie {state?.name ?? ""} zakończyło się</Text>
              <Text>{state?.endDate ?? ""}</Text>
            </>
          }
          bottomElement={
            <Link to="/aktywne-glosowania" tabIndex={-1}>
              <CustomButton
                text="Lista głosowań"
                backgroundColor="primary"
                mb="-20px"
                display={{ base: "flex", md: "none" }}
                w="200px"
              />
            </Link>
          }
        />
        <Link to="/aktywne-glosowania" tabIndex={-1}>
          <CustomButton
            text="Lista głosowań"
            backgroundColor="primary"
            my="50px"
            display={{ base: "none", md: "flex" }}
          />
        </Link>
      </VStack>
    </Layout>
  );
};
