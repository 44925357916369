import { PaginationItem } from "./PaginationItem";

type SinglePageProps = {
  page: number;
  isSelected: boolean;
  changePage: (page: number) => void;
};

export const SinglePage: React.FC<SinglePageProps> = ({ page, isSelected, changePage }) => {
  return (
    <PaginationItem
      changePage={changePage}
      content={String(page)}
      color={isSelected ? "background" : "primary"}
      bg={isSelected ? "primary" : "transparent"}
      updatedPage={page}
    />
  );
};
