import { Text, useToast, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { CustomButton, CustomFormControl, CustomInput, Layout } from "@lubbezposrednio-frontend/commons";
import { bindProperty, bindToCommand, withModel } from "@lubbezposrednio-frontend/core";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";

interface ForgotPasswordViewProps {
  model: ForgotPasswordViewModel;
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = observer(({ model }) => {
  const toast = useToast();

  useEffect(() => {
    document.title = "Email do resetu hasła";
  }, []);

  return (
    <Layout>
      <VStack gap="40px" my="40px">
        <Text textStyle="header" color="primary">
          Reset hasła
        </Text>
        <Text textStyle="largeText" color="primary">
          Podaj nam swój adres email. Wyślemy na niego link do resetu hasła.
        </Text>
        <CustomFormControl
          isInvalid={model.isTouched && !model.isEmailCorrect}
          errorMessage="Podany adres e-mail jest niepoprawny."
          w="300px"
          labelTitle="Email"
        >
          <CustomInput
            autoComplete="email"
            {...bindProperty(model, "email")}
            onFocus={() => {
              model.setIsTouched();
            }}
          />
        </CustomFormControl>
        <CustomButton
          text="Wyślij email"
          {...bindToCommand(model.sendPasswordResetEmail(toast))}
          backgroundColor="primary"
        />
      </VStack>
    </Layout>
  );
});

const ForgotPasswordViewWithModel = withModel<ForgotPasswordViewModel>(ForgotPasswordView, ForgotPasswordViewModel);

export default ForgotPasswordViewWithModel;
