import { action, makeObservable } from "mobx";
import { VOTING_STATUSES, getPublicVotings, VOTINGS_LIST_PAGE_SIZE } from "@lubbezposrednio-frontend/core";
import { VotingsListModel } from "../../models/VotingsListModel";

const ACTIVE_STATUSES = [VOTING_STATUSES.ONGOING, VOTING_STATUSES.PLANNED];

export class ActiveVotingsViewModel extends VotingsListModel {
  constructor() {
    super();

    makeObservable(this, {
      markVotingAsOngoing: action.bound
    });
  }
  changeStatusFilter(status?: VOTING_STATUSES) {
    this.cacheFilters();
    this._changeStatusFilter(status);
    this.applyFilter();
  }

  async getVotings(page: number) {
    return getPublicVotings({
      per_page: VOTINGS_LIST_PAGE_SIZE,
      page_number: page,
      status: this.statusFilter ? [this.statusFilter] : ACTIVE_STATUSES
    });
  }

  public markVotingAsOngoing(id: string) {
    const updatedVotings = this.list
      .get(this.currPage)
      ?.map(voting => (voting.id === id ? { ...voting, status: VOTING_STATUSES.ONGOING } : voting));

    updatedVotings && this.list.set(this.currPage, updatedVotings);
  }

  override async onInit() {}
}
