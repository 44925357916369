export const checkboxStyles = {
  variants: {
    primary: {
      color: "primary",
      control: {
        alignSelf: "flex-start",
        marginTop: "2px",
        borderColor: "primary",
        _checked: {
          color: "primary"
        }
      }
    },
    votingAnswer: {
      control: {
        alignSelf: "flex-start",
        borderColor: "primary",
        width: "27px",
        height: "27px",
        _checked: { color: "primary" }
      },
      container: { lineHeight: 1 },
      icon: { fontSize: "100%" },
      label: { marginLeft: { base: "8px", sm: "22px" } }
    },
    landingPageForm: {
      container: { lineHeight: 1 },
      control: {
        alignSelf: "flex-start",
        marginTop: "2px",
        background: "white",
        _checked: { color: "primary" }
      }
    }
  },
  defaultProps: {
    variant: "primary",
    colorScheme: "primary"
  }
};
