import { ToastId, UseToastOptions } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { FilePreview, compress } from "@lubbezposrednio-frontend/core";

export const useImageDropzone = (
  setImage: (image: FilePreview) => void,
  toast: (options?: UseToastOptions) => ToastId
) => {
  return useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"]
    },
    maxFiles: 1,
    onDrop: async acceptedFiles => {
      try {
        const compressedFiles = await compress(acceptedFiles);
        const loadedImages = compressedFiles.map(file =>
          Object.assign(file, {
            src: URL.createObjectURL(file)
          })
        );
        const imagesToSet = loadedImages;
        setImage(imagesToSet[0]);
      } catch (err) {
        toast({ status: "error", isClosable: true, title: "Nie udało się wgrać zdjęcia." });
      }
    },
    onDropRejected: rejectedFiles => {
      if (rejectedFiles.some(file => file.errors.find(error => error.code === "too-many-files"))) {
        toast({
          title: "Wybrano zbyt wiele plików. Maksymalna ilość plików to 1",
          status: "error",
          isClosable: true
        });
      }
      if (rejectedFiles.some(file => file.errors.find(error => error.code === "file-invalid-type"))) {
        toast({
          title: "Nieprawidłowe rozszerzenie pliku. Dopuszczalne rozszerzenie to *.jpeg, *.png, *.jpg",
          status: "error",
          isClosable: true
        });
      }
    }
  });
};
