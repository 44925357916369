import { useRef } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { EligibleVotersModel } from "../EligibleVotersModel";
import { CustomTooltip, CustomFileUploader } from "@lubbezposrednio-frontend/commons";

const BUTTON_WIDTH = "177px";

export const CsvVotersAdd: React.FC<{ model: EligibleVotersModel }> = observer(({ model }) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Flex
      w="100%"
      columnGap="13px"
      rowGap="17px"
      wrap="wrap"
      justify={{ base: "center", sm: "flex-start" }}
      align="flex-start"
      pb={{ base: "20px", sm: "11px" }}
      pt={{ base: "27px", sm: "33px" }}
    >
      <CustomTooltip message="42B, CSV">
        <Button variant="regular" textStyle="text" h="48px" w={BUTTON_WIDTH} onClick={model.downloadCsvTemplate}>
          Pobierz szablon <Image pl="11.54px" src="/Icon feather-download.svg" />
        </Button>
      </CustomTooltip>
      <CustomFileUploader
        placeholder="Wczytaj uzupełniony plik..."
        fileName={model.votersCsv?.name || model.votersCsvUrlName}
        onChange={model.setVotersCsv.bind(model)}
        onCancel={model.setVotersCsv.bind(model)}
        accept={["csv"]}
        wrapperDisplay={{ base: "none", sm: "block" }}
        customRef={ref}
      />

      <Box display={{ base: "flex", sm: "none" }} flexDir="column" alignItems="center">
        <Button variant="regular" textStyle="text" h="48px" w={BUTTON_WIDTH} onClick={() => ref.current?.click()}>
          Wgraj szablon{" >>"}
        </Button>

        {(model.votersCsv?.name || model.votersCsvUrl) && (
          <Flex pt="24px" w={BUTTON_WIDTH}>
            <Text
              textStyle="text"
              color="primary"
              whiteSpace="normal"
              wordBreak="break-word"
              textAlign="center"
              flex={1}
            >
              {model.votersCsv?.name || model.votersCsvUrlName}
            </Text>
            <Image
              src="/Icon metro-cross.svg"
              onClick={() => {
                if (ref.current) {
                  ref.current.files = null;
                  ref.current.value = "";
                }
                model.setVotersCsv();
              }}
              cursor="pointer"
            />
          </Flex>
        )}
      </Box>
    </Flex>
  );
});
