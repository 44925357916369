export const switchStyles = {
  baseStyle: {
    track: {
      bg: "primary",
      borderColor: "background",
      borderWidth: "1px",

      _checked: {
        bg: "background"
      }
    },
    thumb: {
      bg: "primary",
      borderColor: "background",
      borderWidth: "1px",

      _checked: {
        borderWidth: 0
      }
    }
  },
  variants: {
    consent: {
      track: {
        w: "51px",
        h: "21px",
        alignItems: "center",
        p: "0px",

        _checked: {
          justifyContent: "right"
        }
      },
      thumb: {
        h: "14px",
        w: "14px",
        ml: "5px",

        _checked: {
          ml: 0,
          mr: "21px"
        }
      }
    }
  }
};
