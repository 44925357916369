import { makeObservable, observable, action, computed } from "mobx";
import { Model, Command, sendPasswordResetEmail, ToastType, EMAIL_REGEX } from "@lubbezposrednio-frontend/core";

export class ForgotPasswordViewModel extends Model {
  email = "";
  isTouched = false;
  sendPasswordResetEmail: (toast: ToastType) => Command<void, any>;

  constructor() {
    super();

    makeObservable(this, {
      email: observable,
      setIsTouched: action.bound,
      isEmailCorrect: computed
    });

    this.sendPasswordResetEmail = toast =>
      new Command(
        async () => {
          try {
            const response = await sendPasswordResetEmail(this.email);
            if (response.status === 204) {
              toast({
                title: "Na podany adres email wysłano wiadomość z linkiem do resetu hasła",
                status: "success",
                isClosable: true
              });
            } else {
              this.showGenericErrorToast(toast);
            }
          } catch (err) {
            this.showGenericErrorToast(toast);
            console.log(err);
          }
        },
        () => this.isEmailCorrect
      );
  }

  override async onInit() {}

  setIsTouched() {
    this.isTouched = true;
  }

  get isEmailCorrect() {
    return EMAIL_REGEX.test(this.email);
  }
}
