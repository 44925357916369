import { observer } from "mobx-react-lite";
import { CreateVotingTile } from "../CreateVotingTile";
import { CreateVotingQuestion } from "./components/CreateVotingQuestion";
import { VotingQuestionsModel } from "./VotingQuestionsModel";
import { AddQuestionButton } from "./components/AddQuestionButton";

export const VotingQuestions: React.FC<{
  model: VotingQuestionsModel;
}> = observer(({ model }) => {
  return (
    <CreateVotingTile
      title="Pytania"
      pt="21px"
      pb="52px"
      pr={{ base: "22px", sm: "31px" }}
      absoluteChildren={
        <AddQuestionButton model={model} display={{ sm: "none" }} />
      }
      containerProps={{ mb: { base: "37px", sm: 0 } }}
    >
      {model.questionsArray.map((question, index) => (
        <CreateVotingQuestion
          key={question.id}
          question={question}
          model={model}
          order={index + 1}
          isRemovable={!!index}
        />
      ))}
      <AddQuestionButton model={model} display={{ base: "none", sm: "flex" }} />
    </CreateVotingTile>
  );
});
