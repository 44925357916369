import { Text } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { NEW_TAB_WARNING, VOTING_STATUSES, withModel } from "@lubbezposrednio-frontend/core";
import { CreatedVotingsListViewModel } from "./CreatedVotingsListViewModel";
import { CustomTooltip, Layout } from "@lubbezposrednio-frontend/commons";
import { VotingsList } from "./components/VotingList/VotingsList";
import { VotingsListFilters } from "./components/VotingsListFilters/VotingsListFilters";
import { VotingTileButton } from "./components/VotingList/VotingTileButton";
import { observer } from "mobx-react-lite";
import { DraftTileTitle } from "./components/VotingList/DraftTileTitle";
import { Link } from "react-router-dom";

interface CreatedVotingsListViewProps {
  model: CreatedVotingsListViewModel;
}

export const CreatedVotingsListView: React.FC<CreatedVotingsListViewProps> = observer(({ model }) => {
  useEffect(() => {
    document.title = "Twoje głosowania";
    model.fetchList(model.currPage);
  }, []);

  const getCustomTitle = useCallback(
    (id: string, title?: string, status?: VOTING_STATUSES) =>
      status === VOTING_STATUSES.DRAFT ? (
        <DraftTileTitle id={id} title={title} model={model} />
      ) : (
        <CustomTooltip message={NEW_TAB_WARNING}>
          <Link to={`${window.origin}/glosowanie/${id}`} target="_blank">
            <Text variant="createdVotingsListTileTitle">{title}</Text>
          </Link>
        </CustomTooltip>
      ),
    []
  );

  return (
    <Layout isMenu>
      <Text textStyle="header" color="primary" pt={{ sm: "34px", base: "41px" }}>
        Twoje głosowania
      </Text>
      <VotingsListFilters model={model} isOrganizerView />
      <VotingsList model={model} Button={VotingTileButton} getCustomTitle={getCustomTitle} />
    </Layout>
  );
});

const CreatedVotingsListViewWithModel = withModel<CreatedVotingsListViewModel>(
  CreatedVotingsListView,
  CreatedVotingsListViewModel
);

export default CreatedVotingsListViewWithModel;
