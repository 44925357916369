import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { Command, bindToCommand } from "@lubbezposrednio-frontend/core";

type CustomConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  confirmCommand: Command;
};

export const CustomConfirmModal: React.FC<CustomConfirmModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  confirmCommand
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="background">
        <ModalHeader>
          <Text color="primary">{title}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="primary">{description}</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" {...bindToCommand(confirmCommand)} bg="primary" _hover={{}}>
            <Text color="background">Potwierdź</Text>
          </Button>
          <Button variant="ghost" onClick={onClose} _hover={{}}>
            <Text color="primary">Anuluj</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
