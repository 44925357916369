import { AxiosError } from "axios";
import { action, computed, makeObservable, observable } from "mobx";
import { RefObject } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { NavigateFunction } from "react-router-dom";
import { logIn, validateRecaptcha, logOut, register, resetPassword, setAuthHeaders } from "../api/index";
import { Model } from "./Model";
import { ToastType, RegistrationErrorsType } from "../types/index";
import {
  ACCOUNT_TYPES,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  NUMBER_OF_PASSWORD_CONDITIONS_REQUIRED,
  PASSWORD_VALIDATORS,
  clearLocalStorageToken,
  EMAIL_REGEX,
  setTokenInLocalStorage
} from "../utils/index";
import { Command } from "../mvvm/index";

const LOGIN_ERRORS = [
  { responseMessage: "Incorrect password", toastMessage: "Niepoprawne hasło" },
  { responseMessage: "Incorrect email", toastMessage: "Niepoprawny e-mail. Takie konto nie istnieje" },
  {
    responseMessage: "Account not activated",
    toastMessage:
      "Wystąpił błąd logowania. Możliwe, że Twoje konto nie zostało zweryfikowane. Sprawdź skrzynkę i poszukaj maila aktywacyjnego pt. Aktywacja nowego konta w LubBezpośrednio.pl"
  }
];

const EMAIL_TAKEN_ERROR = "has already been taken";
const EMAIL_ACTIVATED_ERROR = "account has already been activated";

export class AuthorizationModel extends Model {
  email: string = "";
  password: string = "";
  confirmPassword: string = "";
  resetPassword: (toast: ToastType, token: string, navigate: NavigateFunction) => Command<void, any>;
  logIn: (reCaptchaRef: RefObject<ReCAPTCHA>, toast: ToastType, navigate: NavigateFunction) => Command<void, any>;
  register: (reCaptchaRef: RefObject<ReCAPTCHA>, toast: ToastType, navigate: NavigateFunction) => Command<void, any>;
  touchedInputs: string[] = [];
  passwordConditionsChecked = 0;
  accountType: string | undefined = undefined;
  applicationServiceTerms = false;
  applicationMarketingTerms = false;

  constructor() {
    super();
    makeObservable(this, {
      email: observable,
      password: observable,
      confirmPassword: observable,
      touchedInputs: observable,
      accountType: observable,
      applicationServiceTerms: observable,
      applicationMarketingTerms: observable,

      setIsTouched: action.bound,
      clearTouched: action.bound,
      clearInputData: action.bound,

      isEmailCorrect: computed,
      isPasswordCorrect: computed,
      arePasswordsTheSame: computed,
      isAccountTypeSelected: computed,
      isOrganization: computed
    });

    this.logIn = (reCaptchaRef, toast, navigate) =>
      new Command(
        async () => {
          try {
            const isReCaptchaTokenValid = await this.validateReCaptchaToken(reCaptchaRef, toast);

            if (!isReCaptchaTokenValid) {
              return;
            }

            const response = await logIn(this.email, this.password);
            if (response.status === 200) {
              const {
                access_token: accessToken,
                expires_in: expiresIn,
                refresh_expires_in: refreshExpiresIn
              } = response.data;

              setTokenInLocalStorage(accessToken, expiresIn, refreshExpiresIn);
              setAuthHeaders();

              navigate("/organizator");
            } else {
              this.showGenericErrorToast(toast);
            }
          } catch (err) {
            setTimeout(() => reCaptchaRef?.current?.reset(), 500);
            const statusCode = (err as AxiosError).response?.status;
            if (statusCode === 401) {
              const message =
                LOGIN_ERRORS.find(loginError =>
                  (err as AxiosError<{ errors: string[] }>)?.response?.data?.errors?.includes?.(
                    loginError.responseMessage
                  )
                )?.toastMessage || "Niepoprawne dane logowania";

              toast({
                title: message,
                status: "error",
                isClosable: true
              });
            } else {
              this.showGenericErrorToast(toast);
            }
            console.log(err);
          }
        },
        () => this.isEmailCorrect && this.password.length > 0 && this.applicationServiceTerms
      );

    this.register = (reCaptchaRef, toast, navigate) =>
      new Command(
        async () => {
          try {
            const isReCaptchaTokenValid = await this.validateReCaptchaToken(reCaptchaRef, toast);

            if (!isReCaptchaTokenValid) {
              return;
            }

            await register(
              this.email,
              this.password,
              this.confirmPassword,
              this.isOrganization,
              this.applicationServiceTerms,
              this.applicationMarketingTerms
            );

            navigate("/wyslanie-maila-z-potwierdzeniem-rejestracji");
          } catch (err) {
            setTimeout(() => reCaptchaRef?.current?.reset(), 500);

            const errResponse = (err as RegistrationErrorsType).response;

            if (errResponse?.status === 422) {
              toast({
                title: errResponse?.data?.errors?.activated?.includes?.(EMAIL_ACTIVATED_ERROR)
                  ? "Wystąpił błąd rejestracji. Możliwe, że konto powiązane z tym adresem już istnieje. Zaloguj się lub zresetuj hasło, jeśli go nie pamiętasz."
                  : errResponse?.data?.errors?.email?.includes?.(EMAIL_TAKEN_ERROR)
                  ? "Wystąpił błąd rejestracji. Możliwe, że już rozpoczęto proces rejestracji w oparciu o ten adres, ale nie został dokończony. Sprawdź skrzynkę i poszukaj maila aktywacyjnego pt. Aktywacja nowego konta w LubBezpośrednio.pl"
                  : "Coś poszło nie tak, spróbuj ponownie później",
                status: "error",
                isClosable: true
              });
            } else {
              this.showGenericErrorToast(toast);
            }

            console.log(err);
          }
        },
        () =>
          this.isEmailCorrect &&
          this.isPasswordCorrect &&
          this.arePasswordsTheSame &&
          this.isAccountTypeSelected &&
          this.applicationServiceTerms
      );

    this.resetPassword = (toast, token, navigate) =>
      new Command(
        async () => {
          try {
            const response = await resetPassword(this.password, this.confirmPassword, token);
            if (response.status === 200) {
              navigate("/sukces-resetu-hasla");
            } else {
              this.showGenericErrorToast(toast);
            }
          } catch (err) {
            console.log(err);
            if ((err as AxiosError).response?.status === 404) {
              toast({
                title: "Link do resetu hasła wygasł, lub jest niepoprawny.",
                status: "error",
                isClosable: true
              });
            } else {
              this.showGenericErrorToast(toast);
            }
          }
        },
        () => this.isPasswordCorrect && this.arePasswordsTheSame
      );
  }

  public logOut(toast: ToastType, navigate: NavigateFunction) {
    return new Command(async () => {
      try {
        const response = await logOut();

        if (response.status === 204) {
          clearLocalStorageToken();

          navigate("/");
        } else {
          this.showGenericErrorToast(toast);
        }
      } catch (err) {
        this.showGenericErrorToast(toast);
        console.log(err);
      }
    });
  }

  override async onInit() {}

  async validateReCaptchaToken(reCaptchaRef: RefObject<ReCAPTCHA>, toast: ToastType) {
    const token = await reCaptchaRef?.current?.executeAsync();
    const tokenResponse = await validateRecaptcha(token);

    if (tokenResponse.status !== 204) {
      this.showGenericErrorToast(toast);
      setTimeout(() => reCaptchaRef?.current?.reset(), 500);

      return false;
    }
    setTimeout(() => reCaptchaRef?.current?.reset(), 500);
    return true;
  }

  resetPasswordConditions() {
    this.passwordConditionsChecked = 0;
  }

  get isEmailCorrect() {
    return EMAIL_REGEX.test(this.email);
  }

  get isPasswordCorrect() {
    if (this.password.length < MIN_PASSWORD_LENGTH || this.password.length > MAX_PASSWORD_LENGTH) {
      return false;
    }

    this.resetPasswordConditions();

    PASSWORD_VALIDATORS.forEach(regexp => {
      if (this.password.match(regexp)) {
        this.passwordConditionsChecked += 1;
      }
    });

    return this.passwordConditionsChecked >= NUMBER_OF_PASSWORD_CONDITIONS_REQUIRED;
  }

  get arePasswordsTheSame() {
    return this.password === this.confirmPassword;
  }

  get isAccountTypeSelected() {
    return !!this.accountType;
  }

  get isOrganization() {
    return this.accountType === ACCOUNT_TYPES.ORGANIZATION;
  }

  setIsTouched = (inputId: string) => {
    this.touchedInputs = [...this.touchedInputs, inputId];
  };

  getIsTouched = (inputId: string) => {
    return this.touchedInputs.includes(inputId);
  };

  clearTouched = () => {
    this.touchedInputs = [];
  };

  clearInputData() {
    this.clearTouched();
    this.email = "";
    this.password = "";
    this.confirmPassword = "";
    this.passwordConditionsChecked = 0;
    this.accountType = undefined;
    this.applicationServiceTerms = false;
    this.applicationMarketingTerms = false;
  }
}

export const authorization = new AuthorizationModel();
