import axios, { AxiosResponse } from "axios";
import { CreateVotingParams } from "../types/createVoting";
import { VotingQuestionArrayType } from "../types/votingQuestions";
import {
  ApiVotingListType,
  GetPublicVotingsParams,
  GetOrganizerVotingsParams,
  ApiVotingDraftDetails
} from "../types/votingsList";
import { axiosClient } from "./axiosClient";
import { ApiVotingDetails, PostVoteParams } from "../types/votingView";

const appendEmails = (emails: { id: number; value: string }[] = [], formData: FormData) => {
  emails.forEach(email => {
    formData.append("voting[voters_attributes][][email]", email.value);
  });
};

const appendQuestions = (questions: VotingQuestionArrayType[], formData: FormData) => {
  questions.forEach(question => {
    formData.append("voting[questions_attributes][][content]", question.transformedQuestion ?? question.question);
    question.answers.forEach(answer => {
      formData.append("voting[questions_attributes][][answers_attributes][][content]", answer.content);
    });
  });
};

const getFormData = (data: Partial<CreateVotingParams>): FormData => {
  const formData = new FormData();

  const { emails, questions, ...restParams } = data;
  const nonArrayData = { ...restParams, status: "planned" };

  Object.entries(nonArrayData).forEach(([key, value]) => {
    value && formData.append(`voting[${key}]`, value);
  });

  emails && appendEmails(emails, formData);
  questions && appendQuestions(questions, formData);

  return formData;
};

export const createVoting = async (params: Partial<CreateVotingParams>) => {
  try {
    return await axiosClient.post("votings", getFormData(params), {
      headers: { "Content-Type": "multipart/form-data" }
    });
  } catch (err) {
    console.error(err);
  }
};

export const getPublicVotings = async (params: GetPublicVotingsParams) =>
  axiosClient.get<ApiVotingListType>("votings", { params });

export const getCsvTemplate = async (): Promise<AxiosResponse<Blob> | undefined> => {
  try {
    return axios.get<Blob>(`${process.env.REACT_APP_BACKEND_URL}/voters_template.csv`, {
      responseType: "blob"
    });
  } catch (err) {
    console.error(err);
  }
};

export const getVotingDetails = async (id: string, email?: string) => {
  try {
    return await axiosClient.get<ApiVotingDetails | ApiVotingDraftDetails>(`votings/${id}`, {
      params: { voter_email: email }
    });
  } catch (err: any) {
    console.error(err);

    return err?.response;
  }
};

export const sendVote = async (id: string, vote: PostVoteParams) => {
  try {
    return await axiosClient.post(`votings/${id}/vote`, { vote });
  } catch (err: any) {
    console.error(err);

    return err?.response;
  }
};
export const confirmVote = async (token: string) =>
  axiosClient.post("vote/confirm", {
    confirmation_token: token
  });

export const getOrganizerVotings = async (params: GetOrganizerVotingsParams) =>
  axiosClient.get<ApiVotingListType>("user/votings", { params });

export const getVotingOrganizer = async (id: string) => {
  try {
    return await axiosClient.get<ApiVotingDetails>(`votings/${id}/user`);
  } catch (err: any) {
    console.error(err);

    return err?.response;
  }
};

export const sendVotingProtocole = async (id: string) => axiosClient.post(`user/votings/${id}/send_protocol`);

export const deleteVotingDraft = async (id: string) => axiosClient.delete(`votings/${id}`);

export const sendReport = async (id: string, email: string, reason: string, reportableType: string, recaptchaValue: string) => {
  try {
    return await axiosClient.post(`report`, { id, email, reason, type: reportableType, recaptcha: { token: recaptchaValue } });
  } catch (err: any) {
    console.error(err);

    return err?.response;
  }
}