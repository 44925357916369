import { useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomButton, MessageWithIcon } from "@lubbezposrednio-frontend/commons";
import { VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { createVotingModel as model } from "../../CreateVotingView/CreateVotingViewModel";

export const CreateVotingConfirmation = observer(() => {
  useEffect(() => {
    document.title = "Potwierdzenie utworzenia głosowania";
  }, []);

  return (
    <VStack flex={1} pb="133px" spacing={0}>
      <MessageWithIcon
        p={{ base: "115px 20px 24px", sm: "119px 36px 72px" }}
        mt={{ base: "114px", sm: "135px" }}
        w="536px"
        maxW="80vw"
        image="/ICON_THX-register-voting.svg"
        title="Twoje głosowanie zostało utworzone"
        titleStyle="title"
        message={`Będzie widoczne od ${model.confirmData.startDate}`}
        bg="background"
        gap={{ base: "7.5px", sm: "24px" }}
        bottomElement={
          <Link to="/organizator/twoje-glosowania" tabIndex={-1}>
            <CustomButton
              text="Powrót"
              backgroundColor="primary"
              display={{ base: "flex", md: "none" }}
              w="258px"
              h="48px"
              mt="19px"
            />
          </Link>
        }
      />
      <Link to="/organizator/twoje-glosowania" tabIndex={-1}>
        <CustomButton
          text="Powrót"
          backgroundColor="primary"
          mt="34px"
          display={{ base: "none", md: "flex" }}
          w="258px"
          h="48px"
        />
      </Link>
    </VStack>
  );
});
