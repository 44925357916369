import { observer } from "mobx-react-lite";
import React from "react";
import { CustomFormControl, CustomInput } from "@lubbezposrednio-frontend/commons";
import { bindProperty } from "@lubbezposrednio-frontend/core";
import { ProfileViewModel } from "../../ProfileViewModel";

import { ProfileViewSectionContainer } from "../ProfileViewSectionContainer/ProfileViewSectionContainer";
import { Flex } from "@chakra-ui/react";

interface PostalCodeSectionProps {
  model: ProfileViewModel;
}

export const PostalCodeSection = observer(({ model }: PostalCodeSectionProps) => {
  return (
    <ProfileViewSectionContainer>
      <Flex direction={{ base: "column", md: "row" }} gap="12px" w="50%" mb="12px">
        <CustomFormControl labelTitle="Kod pocztowy">
          <CustomInput autoComplete="postal-code" {...bindProperty(model, "postCode")} variant="profile" />
        </CustomFormControl>
      </Flex>
    </ProfileViewSectionContainer>
  );
});
