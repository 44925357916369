import { Box, Divider, Image, Text } from "@chakra-ui/react";
import { VOTE_TILE_WIDTH } from "../../VotingView";
import { VotingInfoData } from "./components/VotingInfoData";
import { VotingViewModel } from "../../VotingViewModel";
import { VotingInfoDates } from "./components/VotingInfoDates";
import { observer } from "mobx-react-lite";

export const VotingInfoTile: React.FC<{ model: VotingViewModel }> = observer(({ model }) => {
  return (
    <Box
      w={`${VOTE_TILE_WIDTH}px`}
      bg="background"
      gap={0}
      borderColor="primary"
      borderWidth="2px"
      borderRadius="standard"
      p={{ base: "121px 26px 57px", sm: "85px 40px 31px" }}
      position="relative"
      maxW="78vw"
    >
      <Image src="/ICON_voting.svg" position="absolute" top="0" left="50%" transform="translate(-50%, -50%)" />

      <VotingInfoData model={model} />
      <Divider />

      <Text
        textStyle="title"
        color="primary"
        pt={{ base: "47px", sm: "18.5px" }}
        pb={{ base: "33px", sm: "79px" }}
        textAlign={{ base: "center", sm: "start" }}
      >
        {model.name}
      </Text>

      <VotingInfoDates model={model} />
    </Box>
  );
});
