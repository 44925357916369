import { forwardRef, Stack, Text, InputRightElement, Image } from "@chakra-ui/react";
import { CustomInput, CustomInputProps } from "../CustomInput/CustomInput";

export type CustomInputWithCounterProps = CustomInputProps & {
  valueForCounter?: string;
  onRemove?: () => void;
};

export const CustomInputWithCounter = forwardRef<CustomInputWithCounterProps, "input">(
  ({ valueForCounter, maxLength, onRemove, ...props }: CustomInputWithCounterProps, ref) => {
    const currLength = (valueForCounter || props.value).trim().length;
    const isMax = maxLength && currLength >= maxLength;
    const lengthDiscrepancy = currLength - props.value.trim().length;

    return (
      <Stack w="100%">
        <CustomInput {...props} ref={ref} maxLength={maxLength && maxLength - lengthDiscrepancy}>
          {!!onRemove && (
            <InputRightElement right="8px">
              <Image src="/Icon metro-cross.svg" onClick={onRemove} cursor="pointer" />
            </InputRightElement>
          )}
        </CustomInput>
        <Text
          textStyle="text"
          textAlign="right"
          color={isMax ? "error" : "primary"}
          fontWeight={isMax ? "bold" : "normal"}
        >
          {currLength}/{maxLength}
        </Text>
      </Stack>
    );
  }
);
