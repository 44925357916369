import { ToastId, UseToastOptions } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

export const usePdfDropzone = (
  setPdf: (pdf: File) => void,
  toast: (options?: UseToastOptions) => ToastId
) => {
  return useDropzone({
    accept: {
      "application/pdf": [".pdf"]
    },
    maxFiles: 1,
    onDrop: async acceptedFiles => {
      console.log(acceptedFiles);
      setPdf(acceptedFiles[0]);
    },
    onDropRejected: rejectedFiles => {
      if (
        rejectedFiles.some(file =>
          file.errors.find(error => error.code === "too-many-files")
        )
      ) {
        toast({
          title: "Wybrano zbyt wiele plików. Maksymalna ilość plików to 1",
          status: "error",
          isClosable: true
        });
      }
      if (
        rejectedFiles.some(file =>
          file.errors.find(error => error.code === "file-invalid-type")
        )
      ) {
        toast({
          title:
            "Nieprawidłowe rozszerzenie pliku. Dopuszczalne rozszerzenie to *.pdf",
          status: "error",
          isClosable: true
        });
      }
    }
  });
};
