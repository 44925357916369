import React, { useCallback, useEffect } from "react";
import { withModel, VOTING_STATUSES, NEW_TAB_WARNING } from "@lubbezposrednio-frontend/core";
import { ActiveVotingsViewModel } from "./ActiveVotingsViewModel";
import { CustomTooltip, Layout } from "@lubbezposrednio-frontend/commons";
import { VotingsListFilters } from "../CreatedVotingsListView/components/VotingsListFilters/VotingsListFilters";
import { VotingsList } from "../CreatedVotingsListView/components/VotingList/VotingsList";
import { ActiveVotingButton } from "./components/ActiveVotingButton";
import { VotingCountdown } from "./components/VotingCountdown";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface ActiveVotingsViewProps {
  model: ActiveVotingsViewModel;
}

export const ActiveVotingsView: React.FC<ActiveVotingsViewProps> = ({ model }) => {
  useEffect(() => {
    document.title = "Lista głosowań";
    model.fetchList(model.currPage);
  }, []);

  const getIsCustomTile = useCallback((status?: VOTING_STATUSES) => status === VOTING_STATUSES.PLANNED, []);

  const getCustomTitle = useCallback(
    (id: string, title?: string) => (
      <CustomTooltip message={NEW_TAB_WARNING}>
        <Link to={`${window.origin}/glosowanie/${id}`} target="_blank">
          <Text variant="createdVotingsListTileTitle">{title}</Text>
        </Link>
      </CustomTooltip>
    ),
    []
  );

  return (
    <Layout>
      <Box pt={{ base: "7px", sm: "14px" }} flex={1}>
        <VotingsListFilters model={model} />
        <VotingsList
          model={model}
          Button={ActiveVotingButton}
          getIsCustomTile={getIsCustomTile}
          CustomDates={props => <VotingCountdown {...props} model={model} />}
          mt={{ base: "35px", sm: "39px" }}
          getCustomTitle={getCustomTitle}
        />
      </Box>
    </Layout>
  );
};

const ActiveVotingsViewWithModel = withModel<ActiveVotingsViewModel>(ActiveVotingsView, ActiveVotingsViewModel);

export default ActiveVotingsViewWithModel;
