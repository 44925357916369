import { Box, Flex, useToast } from "@chakra-ui/react";
import React from "react";
import { ProfileButton } from "./components/ProfileButton";
import { ProfileViewModel } from "../../ProfileViewModel";
import { useNavigate } from "react-router-dom";
import { CustomTooltip } from "@lubbezposrednio-frontend/commons";

interface ProfileButtonsSectionProps {
  model: ProfileViewModel;
}

export const ProfileButtonsSection: React.FC<ProfileButtonsSectionProps> = ({ model }) => {
  const toast = useToast();
  const navigate = useNavigate();
  return (
    <Flex direction={{ base: "column", md: "row" }} gap="30px" mt="20px">
      <ProfileButton label="Zapisz" onClick={() => model.saveProfileData(toast)} />
      <CustomTooltip message='UWAGA! Po kliknięciu "Usuń konto" Twoje konto zostanie zablokowane. Nie będziesz mógł się zalogować, wrócić do swoich głosowań ani założyć nowego konta na ten sam adres e-mail'>
        <Box>
          <ProfileButton label="Usuń konto" onClick={() => model.deactivateAccount(toast, navigate)} />
        </Box>
      </CustomTooltip>
    </Flex>
  );
};
