import Compressor from "compressorjs";

export const compress = async (files: File[]): Promise<File[]> => {
  const compressioanPromisses = files.map(
    async file =>
      await new Promise((resolve: (file: File) => void, reject) => {
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 1080,
          maxHeight: 1080,
          success: resolve,
          error: reject
        });
      })
  );
  return await Promise.all(compressioanPromisses);
};
