import React, { useEffect } from "react";
import { Text, VStack } from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CustomButton, MessageWithIcon, Layout } from "@lubbezposrednio-frontend/commons";

export const VoterNotAllowedScreen: React.FC = () => {
  useEffect(() => {
    document.title = "Nieuprawniony adres email - Głosowanie";
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Layout>
      <VStack flex={1} py={{ base: 0, sm: "135px" }} mb={{ base: "140px", sm: 0 }}>
        <MessageWithIcon
          isError
          image="/ICON_voting_red.svg"
          title=""
          message={
            <>
              <Text>Wskazany adres e-mail nie uprawnia do udziału w głosowaniu.</Text>
              <br />
              <Text fontWeight="normal">
                Nie ma Cię na liście uprawnionych dodanej przez organizatora bądź oddałeś już prawidłowo głos. Jeżeli
                uważasz, że powinieneś móc zagłosować{" "}
                <Link to={`/glosowanie/${id}/organizator`} style={{ textDecoration: "underline" }}>
                  skontaktuj się bezpośrednio z organizatorem głosowania.
                </Link>
              </Text>
            </>
          }
          p={{ base: "100px 29px 50px", md: "100px 65px 60px" }}
        />
        <CustomButton
          text="Lista głosowań"
          onClick={() => navigate("/aktywne-glosowania")}
          mt={{ base: "-20px", sm: "20px" }}
        />
      </VStack>
    </Layout>
  );
};
