import { DATE_KEYS } from "../types/general";
import { MAX_VALUES } from "./constants";

export const getMaxDay = (year: number, month: number) => new Date(year, month, 0).getDate();

export const getFormattedDate = (date: Date) =>
  date
    ?.toLocaleString("pl-PL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit"
    })
    .replaceAll(".", "-")
    .replace(",", "");

export const getDateObject = (date: Record<DATE_KEYS, string>): Date => {
  return new Date(
    +date[DATE_KEYS.YEAR],
    +date[DATE_KEYS.MONTH] - 1,
    +date[DATE_KEYS.DAY],
    +date[DATE_KEYS.HOUR],
    +date[DATE_KEYS.MINUTES]
  );
};

export const checkDateCorrectness = (date: Record<DATE_KEYS, string>): { isCorrect: boolean; message: string } => {
  const {
    [DATE_KEYS.YEAR]: year,
    [DATE_KEYS.MONTH]: month,
    [DATE_KEYS.DAY]: day,
    [DATE_KEYS.HOUR]: hour,
    [DATE_KEYS.MINUTES]: minutes
  } = date;

  const maxDay = getMaxDay(+year, +month);

  const validationFails = [
    {
      condition: (+month <= (MAX_VALUES[DATE_KEYS.MONTH] as number) && +month >= 1) || month === "",
      message: `Miesiąc powinien być z zakresu 1-${MAX_VALUES[DATE_KEYS.MONTH]}`
    },
    {
      condition: !maxDay || (+day <= maxDay && +day >= 1) || day === "",
      message: `Dzień powinien być z zakresu 1-${maxDay}`
    },
    {
      condition: (+hour <= (MAX_VALUES[DATE_KEYS.HOUR] as number) && +hour >= 0) || !hour,
      message: `Godzina powinna być z zakresu 0-${MAX_VALUES[DATE_KEYS.HOUR]}`
    },
    {
      condition: (+minutes <= (MAX_VALUES[DATE_KEYS.MINUTES] as number) && +minutes >= 0) || !minutes,
      message: `Minuty powinny być z zakresu 0-${MAX_VALUES[DATE_KEYS.MINUTES]}`
    }
  ].filter(item => !item.condition);

  const isDateCorrect = !validationFails.length;
  const message = validationFails.map(fail => fail.message).join(", ");

  return { isCorrect: isDateCorrect, message };
};

export const getDateModelType = (date: Date): Record<DATE_KEYS, string> => {
  return {
    [DATE_KEYS.MINUTES]: String(date.getMinutes()),
    [DATE_KEYS.HOUR]: String(date.getHours()),
    [DATE_KEYS.DAY]: String(date.getDate()),
    [DATE_KEYS.MONTH]: String(date.getMonth() + 1),
    [DATE_KEYS.YEAR]: String(date.getFullYear())
  };
};

export const getApiParamsDate = (date: Record<DATE_KEYS, string>, isDraft?: boolean) =>
  isDraft && Object.values(date).every(value => value === "") ? undefined : getDateObject(date).toISOString();

const VOTING_DATE_KEYS = Object.values(DATE_KEYS);

export const correctDate = (date: Record<DATE_KEYS, number>): Record<DATE_KEYS, string> => {
  VOTING_DATE_KEYS.forEach((key, index) => {
    if (key !== DATE_KEYS.YEAR) {
      const maxDay = getMaxDay(date[DATE_KEYS.YEAR], date[DATE_KEYS.MONTH]);
      const max = key === DATE_KEYS.DAY ? maxDay : MAX_VALUES[key] ?? 0;
      const offset = date[key] - max;

      if (offset > 0) {
        date[key] = offset - (key === DATE_KEYS.MINUTES || key === DATE_KEYS.HOUR ? 1 : 0);
        date[VOTING_DATE_KEYS[index + 1]]++;
      }
    }
  });

  return VOTING_DATE_KEYS.reduce((finalDate, key) => {
    finalDate[key] = String(date[key]);
    return finalDate;
  }, {} as Record<DATE_KEYS, string>);
};
