import React, { useEffect } from "react";
import { Layout } from "@lubbezposrednio-frontend/commons";
import { Text, VStack } from "@chakra-ui/layout";

export const AboutView: React.FC = () => {
  useEffect(() => {
    document.title = "O platformie decyduj.lubbezposrednio.pl ";
  }, []);

  return (
    <Layout>
      <VStack flex={1}>
        <Text textStyle="header" color="primary">
          O platformie decyduj.lubbezposrednio.pl
        </Text>
      </VStack>
    </Layout>
  );
};
