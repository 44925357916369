import { useMemo, useState } from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import { CustomConfirmModal } from "@lubbezposrednio-frontend/commons";
import { TILE_TITLE_STYLES } from "@lubbezposrednio-frontend/core";
import { CreatedVotingsListViewModel } from "../../CreatedVotingsListViewModel";
import { useNavigate } from "react-router-dom";

type DraftTileTitleProps = {
  title?: string;
  id: string;
  model: CreatedVotingsListViewModel;
};

export const DraftTileTitle: React.FC<DraftTileTitleProps> = ({ id, title, model }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const onClose = () => setIsOpen(false);

  const confirmCommand = useMemo(() => model.handleDraftDelete(id, toast, onClose), [toast, id, onClose]);

  return (
    <Flex {...TILE_TITLE_STYLES} justify="space-between" px="20px">
      <Text>{title}</Text>
      <Flex>
        <IconButton
          aria-label="create-from-draft"
          variant="iconButton"
          h="100%"
          icon={<EditIcon />}
          onClick={() => model.handleCreateVotingFromDraft(navigate, id, toast)}
        />
        <IconButton
          h="100%"
          aria-label="remove-draft"
          variant="iconButton"
          icon={<DeleteIcon />}
          onClick={() => setIsOpen(true)}
        />
      </Flex>
      <CustomConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        title="Usuń wersję roboczą"
        description={`Czy na pewno chcesz usunąć wersję roboczą głosowania o nazwie: ${title}?`}
        confirmCommand={confirmCommand}
      />
    </Flex>
  );
};
