import { CONSENT_NAMES } from "../types/consents";
import { axiosClient } from "./axiosClient";

export const register = (
  email: string,
  password: string,
  passwordConfirmation: string,
  isOrganization: boolean,
  isApplicationServiceTermsAccepted: boolean,
  isApplicationMarketingTermsAccepted: boolean
) =>
  axiosClient.post("user", {
    user: {
      email,
      password,
      password_confirmation: passwordConfirmation,
      is_organization: isOrganization,
      [CONSENT_NAMES.APPLICATIONS_MARKETING]: isApplicationMarketingTermsAccepted,
      [CONSENT_NAMES.APPLICATIONS_SERVICE]: isApplicationServiceTermsAccepted
    }
  });

export const logIn = (email: string, password: string) =>
  axiosClient.post("login", {
    user: {
      email,
      password
    }
  });

export const validateRecaptcha = (token: string | null | undefined) =>
  axiosClient.post("recaptcha", {
    recaptcha: {
      token
    }
  });

export const refreshToken = () => axiosClient.post("refresh_token");

export const logOut = () => axiosClient.delete("login");

export const activateAccount = (token: string) =>
  axiosClient.post("user/activate", {
    user: {
      activation_token: token
    }
  });

export const sendPasswordResetEmail = (email: string) =>
  axiosClient.post("user/password/recovery_email", {
    email
  });

export const resetPassword = (password: string, confirmPassword: string, token: string) =>
  axiosClient.put("user/password", {
    token,
    user: {
      password,
      password_confirmation: confirmPassword
    }
  });

export const deactivateAccountSendEMail = () => axiosClient.post("user/removal_email");
export const confirmDeactivateAccount = async (token: string) =>
  axiosClient.delete("user", { data: { removal_token: token } });
