import { Flex, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { bindProperty } from "@lubbezposrednio-frontend/core";
import { CustomFormControl, CustomInput } from "@lubbezposrednio-frontend/commons";
import { ProfileViewSectionContainer } from "../ProfileViewSectionContainer/ProfileViewSectionContainer";
import { ProfileViewModel } from "../../ProfileViewModel";

interface PersonalDataSectionProps {
  model: ProfileViewModel;
  isOrganization?: boolean;
}

export const PersonalDataSection = observer(({ model, isOrganization }: PersonalDataSectionProps) => {
  return (
    <ProfileViewSectionContainer>
      <VStack mb="20px" gap="20px">
        <Flex direction={{ base: "column", md: "row" }} gap="12px" w="100%">
          <CustomFormControl labelTitle={isOrganization ? "Imię przedstawiciela" : "Imię"}>
            <CustomInput autoComplete="given-name" {...bindProperty(model, "firstName")} variant="profile" />
          </CustomFormControl>
          <CustomFormControl labelTitle={isOrganization ? "Nazwisko przedstawiciela" : "Nazwisko"}>
            <CustomInput autoComplete="family-name" {...bindProperty(model, "lastName")} variant="profile" />
          </CustomFormControl>
        </Flex>
        <Flex direction={{ base: "column", md: "row" }} gap="12px" w="100%">
          <CustomFormControl labelTitle="E-mail">
            <CustomInput autoComplete="email" disabled {...bindProperty(model, "email")} variant="profile" />
          </CustomFormControl>
          <CustomFormControl labelTitle="Nr telefonu">
            <CustomInput autoComplete="tel" {...bindProperty(model, "phoneNumber")} variant="profile" />
          </CustomFormControl>
        </Flex>
      </VStack>
    </ProfileViewSectionContainer>
  );
});
